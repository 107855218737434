import stringUtils from "./stringUtils";

const max = <T, K extends keyof T>(list: T[], field: K, defaultValue: T[K]) => {
  return list.reduce((maxValue, nextItem) => {
    const fieldValue = nextItem[field];

    if (typeof fieldValue === "number" && (fieldValue as T[K]) > maxValue) {
      return fieldValue;
    }

    return maxValue;
  }, defaultValue);
};

const sortByStringProperty = <T, K extends keyof T>(list: T[], field: K, order: "asc" | "desc") => {
  return list.sort((a, b) => {
    let aValue: any = a[field];
    let bValue: any = b[field];

    const orderDirection = order === "desc" ? -1 : 1;

    if (stringUtils.isNullOrWhitespace(aValue)) {
      return -1 * orderDirection;
    }

    if (stringUtils.isNullOrWhitespace(bValue)) {
      return 1 * orderDirection;
    }

    const aString = String(aValue).toLowerCase();
    const bString = String(bValue).toLowerCase();

    return sortByStringComparison(aString, bString, order);
  });
};

const sortByStringComparison = (a: string, b: string, order: "asc" | "desc") => {
  const orderDirection = order === "desc" ? -1 : 1;
  const returnValue = b < a ? 1 * orderDirection : b > a ? -1 * orderDirection : 0;
  return returnValue;
};

const listUtils = {
  max,
  sortByStringProperty,
  sortByStringComparison,
};

export default listUtils;
