import { Box, Link, SxProps, Typography } from "@mui/material";
import theme from "config/theme/theme";
import { Domain, useConfig } from "./Config";

interface SpanProps {
  text: string;
}

interface BasicLogoProps {
  type?: "span" | "div" | "h1" | "h2" | undefined;
  colors?: "inverse" | undefined;
  sx?: SxProps | undefined;
}

type DomainCompanyNameMap = {
  [x in Domain]: JSX.Element[];
};

export const BasicLogo = (props: BasicLogoProps) => {
  const { colors, sx } = props;
  const { config } = useConfig();

  const PrimarySpan = (props: SpanProps): JSX.Element => {
    const { text } = props;
    return <span className={colors === "inverse" ? "primaryInverseSpan" : "primarySpan"}>{text}</span>;
  };

  const SecondarySpan = (props: SpanProps): JSX.Element => {
    const { text } = props;
    return <span className="secondarySpan">{text}</span>;
  };

  const domainCompanyNameMap: DomainCompanyNameMap = {
    "fabledfounders.com": [
      <PrimarySpan key="fabled" text="fabled" />,
      <SecondarySpan key="founders" text="[founders]" />,
    ],
    "titanpaddle.com": [<PrimarySpan key="titan" text="titan" />, <SecondarySpan key="paddle" text="[paddle]" />],
  };

  const currentDomainCompanyNameParts = domainCompanyNameMap[config.domain];

  return (
    <Typography
      variant="h5"
      // component={type ?? "div"}
      sx={{
        ...sx,

        fontWeight: 700,
        "& .primarySpan": { color: theme.base.primary.main },
        "& .primaryInverseSpan": { color: theme.base.primary.contrastText },
        "& .secondarySpan": { color: theme.base.secondary.main },
      }}
    >
      {currentDomainCompanyNameParts.map((p) => p)}
    </Typography>
  );
};

interface LogoProps {
  onClick?: () => void;
  variant?: "primary" | "secondary";
}

type DomainImageMapDetails = {
  src: string;
  alt: string;
};

type DomainImageMap = {
  [x in Domain]: DomainImageMapDetails;
};

const Logo = (props: LogoProps) => {
  const { onClick } = props;
  const { config } = useConfig();

  const imageMap: DomainImageMap = {
    "fabledfounders.com": {
      src: "/images/fox32.png",
      alt: "Fox icons created by Freepik - Flaticon @ https://www.flaticon.com/free-icons/fox",
    },
    "titanpaddle.com": {
      src: "/images/titanpaddle/paddle32.png",
      alt: "Paddle icons created by Freepik - Flaticon @ https://www.flaticon.com/free-icons/paddle",
    },
  };

  const imageDetails: DomainImageMapDetails = imageMap[config.domain];

  return (
    <Link href={"/"} sx={{ textDecoration: "none" }}>
      <Box
        onClick={onClick}
        style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
        sx={{ cursor: "pointer" }}
      >
        <img
          src={imageDetails.src}
          alt={imageDetails.alt}
          width={32}
          height={32}
          style={{ marginRight: "1em", marginLeft: "1em" }}
        />
        <BasicLogo />
      </Box>
    </Link>
  );
};

export default Logo;
