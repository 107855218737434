import { Serializable, TypedJSON } from "typedjson";

const deepCopyObject = function <T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
};

const isString = (value: any) => {
  return typeof value === "string";
};

const deepEquals = (obj1: any, obj2: any, inputKey: string = "root") => {
  if (obj1 === obj2) {
    return true; // They are the same object
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    console.error("One of the inputs is not an object or is null");
    return obj1 === obj2; // Primitives or one is null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    console.error("Mismatched key count");
    console.error("keys1:", keys1.join(", "));
    console.error("keys2:", keys2.join(", "));
    return false; // Different number of keys
  }

  for (const key of keys1) {
    const nestedKey = `${inputKey}:${key}`;
    if (!keys2.includes(key)) {
      console.error(`Key=${nestedKey}, Missing key`);
      return false; // Keys are different or values are not deeply equal
    }

    const nextObj1 = obj1[key];
    const nextObj2 = obj2[key];
    if (!deepEquals(nextObj1, nextObj2, nestedKey)) {
      console.error(`Key=${nestedKey}, deepEquals failed`);
      return false; // Keys are different or values are not deeply equal
    }
  }

  for (const key of keys2) {
    const nestedKey = `${inputKey}:${key}`;
    if (!keys1.includes(key) || !deepEquals(obj1[key], obj2[key], nestedKey)) {
      console.error(`Key=${nestedKey}, Missing key or deepEquals failed`);
      return false; // Keys are different or values are not deeply equal
    }
  }

  return true; // All checks passed, objects are deeply equal
};

const deepCopyTypedObject = <T extends U, U>(rootConstructor: Serializable<T>, objectToClone: U): T => {
  const serializer = new TypedJSON(rootConstructor);

  const clonedAsJson =
    objectToClone instanceof rootConstructor ? serializer.stringify(objectToClone as T) : JSON.stringify(objectToClone);

  const clonedTransaction = serializer.parse(clonedAsJson);

  if (!clonedTransaction) {
    throw new Error(`Failed to clone object: ${typeof objectToClone}`);
  }

  return clonedTransaction;
};

const omitProperties = <T>(objType: Serializable<T>, match: T, propertiesToOmit: (keyof T)[]): object => {
  const serializer = new TypedJSON(objType);
  const jsonString = serializer.stringify(match);
  const jsonObject = JSON.parse(jsonString);

  propertiesToOmit.forEach((property) => {
    delete jsonObject[property];
  });

  return jsonObject;
};

const objectUtils = {
  deepCopyObject,
  deepCopyTypedObject,
  isString,
  deepEquals,
  omitProperties,
};

export default objectUtils;
