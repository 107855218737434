import { AdvancedTable, AdvancedTableRecords, HeadCell } from "components/AdvancedTable";
import { Grid, Typography } from "@mui/material";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { usePaddleThisWeekStore } from "./store/usePaddleThisWeekStore";
import { ThisWeekMatchup } from "api/paddle/thisWeekModels"; // Assuming the correct import path

const PaddleThisWeekPage = () => {
  const [state, actions] = usePaddleThisWeekStore();

  const { thisWeek } = state;

  const matchupFields: HeadCell<ThisWeekMatchup>[] = [
    { id: "teamOnePlayersName", label: "Team One", readOnly: true, width: "30%" },
    { id: "teamTwoPlayersName", label: "Team Two", readOnly: true, width: "30%" },
    { id: "matchTime", label: "Match Time", dateFormat: "ddd @ h", readOnly: true, width: "15%" },
    { id: "court", label: "Court", readOnly: true, width: "15%" },
  ];

  const firstMatchupsTable: AdvancedTableRecords<ThisWeekMatchup> = {
    fields: matchupFields,
    data: thisWeek.firstMatchups,
  };

  const secondMatchupsTable: AdvancedTableRecords<ThisWeekMatchup> = {
    fields: matchupFields,
    data: thisWeek.secondMatchups,
  };

  useEffectOnLoad(() => actions.load());

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" sx={{ mt: "1em" }}>{`Matches for week of ${thisWeek.matchWeek.format(
          "M/D/YYYY"
        )}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AdvancedTable
          title={thisWeek.firstMatchupName}
          table={firstMatchupsTable}
          orderByKey={"matchTime"}
          onChange={() => {}}
          onDelete={() => {}}
          onChangeAll={() => {}}
          hidePagination
          minWidthPx={0}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AdvancedTable
          title={thisWeek.secondMatchupName}
          table={secondMatchupsTable}
          orderByKey={"matchTime"}
          onChange={() => {}}
          onDelete={() => {}}
          onChangeAll={() => {}}
          hidePagination
          minWidthPx={0}
        />
      </Grid>
    </Grid>
  );
};

export default PaddleThisWeekPage;
