import { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { EmailTemplateForm, EmailTemplateFormField, EmailTemplateFormVersion } from "api/models";
import { v4 } from "uuid";
import moment from "moment";
import CustomIcon from "components/CustomIcon";
import listUtils from "utils/listUtils";
import objectUtils from "utils/objectUtil";
import liquidUtils, { LiquidField } from "utils/liquidUtils";
import stringUtils from "utils/stringUtils";
import CustomAlert from "components/CustomAlert";

interface EditEmailTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  emailTemplate: EmailTemplateForm;
  onSave: (updatedTemplate: EmailTemplateForm) => void;
}

function areEmailTemplateFormsEqual(form1: EmailTemplateForm, form2: EmailTemplateForm): boolean {
  if (
    form1.name === form2.name &&
    form1.isDisabled === form2.isDisabled &&
    areEmailTemplateFormVersionsEqual(form1.templates, form2.templates)
  ) {
    return true;
  }
  return false;
}

export function areEmailTemplateFormVersionsEqual(
  version1: EmailTemplateFormVersion[],
  version2: EmailTemplateFormVersion[]
): boolean {
  if (version1.length !== version2.length) {
    return false;
  }

  const version2Map = new Map<string, EmailTemplateFormVersion>();

  for (var version of version2) {
    version2Map.set(version.id, version);
  }

  let foundMismatch = false; // Flag to track if a mismatch is found

  for (let i = 0; i < version1.length; i++) {
    const version1Id = version1[i].id;
    const version2Match = version2Map.get(version1Id);

    if (!version2Match) {
      return false; // Version missing in version2
    }

    if (
      version1[i].template !== version2Match.template ||
      version1[i].subject !== version2Match.subject ||
      version1[i].isDisabled !== version2Match.isDisabled
    ) {
      foundMismatch = true; // Set the flag if a mismatch is found
    }
  }

  return !foundMismatch; // Return true if no mismatch was found
}

const getLatestVersion = (form: EmailTemplateForm, showDisabled: boolean) => {
  return form.templates.filter((t) => !t.isDisabled || showDisabled).sort((a, b) => b.version - a.version)[0];
};

const createNewVersion = () => {
  const version: EmailTemplateFormVersion = {
    id: v4(),
    version: 1,
    fields: [],
    subject: "",
    template: "",
    defaultValue: "",
    isDisabled: false,
    created: moment(),
    updated: moment(),
  };
  return version;
};

const EditEmailTemplateModal = (props: EditEmailTemplateModalProps) => {
  const { isOpen, onClose, emailTemplate, onSave } = props;

  const [state, setState] = useState(emailTemplate);
  const [hasChanges, setHasChanges] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const latestVersion: EmailTemplateFormVersion = getLatestVersion(state, showDisabled);
  const [version, setVersion] = useState(latestVersion);

  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpen: false,
  });

  const versionTextFieldRef = useRef<HTMLInputElement>(null);

  const focusTemplateField = () => {
    if (versionTextFieldRef.current) {
      versionTextFieldRef.current.focus();
    }
  };

  if (emailTemplate.templates.length <= 0) {
    emailTemplate.templates.push(createNewVersion());
  }

  useEffect(() => {
    setState(emailTemplate);
    setHasChanges(false);
    setShowDisabled(false);

    if (version) {
      const updatedVersion = emailTemplate.templates.find((t) => t.id === version.id);
      setVersion(updatedVersion || getLatestVersion(emailTemplate, showDisabled));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplate]);

  useEffect(() => {
    const hasChanges = areEmailTemplateFormsEqual(emailTemplate, state);
    setHasChanges(!hasChanges);
  }, [state, emailTemplate]);

  const onVersionBlur = async (newVersion?: EmailTemplateFormVersion) => {
    let versionToUpdate = version;
    if (newVersion) {
      versionToUpdate = newVersion;
      setVersion(newVersion);
      setHasChanges(true);
    }

    const newState = objectUtils.deepCopyObject(state);

    const existingVersionIndex = newState.templates.findIndex((t) => t.id === versionToUpdate.id);

    if (existingVersionIndex >= 0) {
      const areEqual = areEmailTemplateFormsEqual(emailTemplate, newState);
      setHasChanges(!areEqual);

      newState.templates[existingVersionIndex] = versionToUpdate;

      setState({
        ...newState,
        templates: [...newState.templates],
        updated: moment(),
      });
    }
  };

  const addOrKeepFields = (
    existingFields: EmailTemplateFormField[],
    fieldsToAdd: LiquidField[]
  ): EmailTemplateFormField[] => {
    const modifiedDate = moment(); // You should set this to the appropriate value

    const updatedFields: EmailTemplateFormField[] = existingFields.map((existingField) => {
      const matchingField = fieldsToAdd.find((newField) => newField.key === existingField.key);
      const copiedExistingField = objectUtils.deepCopyObject(existingField);

      if (matchingField) {
        copiedExistingField.isDisabled = false;

        if (!objectUtils.deepEquals(matchingField.options, existingField.options)) {
          copiedExistingField.options = matchingField.options;
          copiedExistingField.order = matchingField.order;
          copiedExistingField.updated = modifiedDate;
          return copiedExistingField;
        }

        copiedExistingField.order = matchingField.order;
        return copiedExistingField;
      }

      copiedExistingField.order = 100;
      copiedExistingField.isDisabled = true;
      copiedExistingField.updated = modifiedDate;
      return copiedExistingField;
    });

    const newFields: EmailTemplateFormField[] = fieldsToAdd
      .filter((newField) => existingFields.every((existingField) => existingField.key !== newField.key))
      .map((field) => {
        const mappedField: EmailTemplateFormField = {
          id: stringUtils.uuid(),
          key: field.key,
          options: field.options,
          order: field.order,
          rawTemplate: field.rawFieldTemplate,
          isDisabled: false,
          created: modifiedDate,
          updated: modifiedDate,
        };

        return mappedField;
      });

    return [...updatedFields, ...newFields];
  };

  const onVersionChange = (newTemplate: string) => {
    if (newTemplate === version.template) {
      return;
    }

    const matchingVersion = emailTemplate.templates.find((t) => t.id === version.id);
    const existingFields = matchingVersion?.fields || [];
    const fields = liquidUtils.parseFields(newTemplate);

    const newFields = addOrKeepFields(existingFields, fields);

    const newVersion = objectUtils.deepCopyObject(version);
    newVersion.fields = newFields;
    newVersion.template = newTemplate;
    newVersion.updated = moment();

    setVersion(newVersion);
  };

  const insertTextAtCursor = (options: { fieldName: string; typeOptions?: string } | string) => {
    let textToInsert = undefined;

    if (typeof options === "string") {
      textToInsert = options;
    } else {
      let { fieldName, typeOptions } = options;
      const fieldsWithSimilarName = version.fields.filter((f) => f.key.startsWith(fieldName));

      const highestNumberSuffix = fieldsWithSimilarName.reduce((maxSuffix, field) => {
        const suffixMatch = field.key.match(new RegExp(`${fieldName}(\\d+)$`));

        if (suffixMatch) {
          const currentSuffix = parseInt(suffixMatch[1]);
          return Math.max(maxSuffix, currentSuffix);
        }

        return maxSuffix;
      }, 0);

      fieldName = `${fieldName}${highestNumberSuffix + 1}`;
      textToInsert = typeOptions ? `{{ ${fieldName} | ${typeOptions} }}` : `{{ ${fieldName} }}`;
    }

    if (!textToInsert) {
      return;
    }

    const textField = versionTextFieldRef.current;
    if (textField) {
      const startPos = textField.selectionStart || 0;
      const endPos = textField.selectionEnd || 0;

      const currentValue = version.template;

      const newValue = currentValue.substring(0, startPos) + textToInsert + currentValue.substring(endPos);

      onVersionChange(newValue);

      setTimeout(() => {
        const newCursorPosition = startPos + textToInsert.length;
        textField.focus();
        textField.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    } else {
      onVersionChange(`${version.template}\n${textToInsert}`);
      focusTemplateField();
    }
  };

  const onTemplateChange = (callback: (form: EmailTemplateForm) => void) => {
    const newState = objectUtils.deepCopyObject(state);

    callback(newState);
    setState(newState);
  };

  const onShowDisabledChange = () => {
    const newShowDisabledValue = !showDisabled;

    if (newShowDisabledValue === false && version.isDisabled === true) {
      const latestVersion = getLatestVersion(state, false);
      setVersion(latestVersion);
    }

    setShowDisabled(newShowDisabledValue);
  };

  const hasDeletedVersions = state.templates.some((t) => t.isDisabled);

  const groupedFields = version.fields.reduce(
    (
      result: {
        disabled: EmailTemplateFormField[];
        conditionals: EmailTemplateFormField[];
        others: EmailTemplateFormField[];
      },
      obj
    ) => {
      if (obj.isDisabled) {
        result.disabled.push(obj);
      } else if (obj.options.type === "conditional") {
        result.conditionals.push(obj);
      } else {
        result.others.push(obj);
      }
      return result;
    },
    { disabled: [], conditionals: [], others: [] }
  );

  groupedFields.disabled.sort((a, b) => a.key.localeCompare(b.key));
  groupedFields.conditionals.sort((a, b) => a.key.localeCompare(b.key));
  groupedFields.others.sort((a, b) => a.order - b.order);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(_, reason) => {
          const isBackdropClickOrEscapePress = reason === "backdropClick" || reason === "escapeKeyDown";
          if (!isBackdropClickOrEscapePress) {
            onClose();
          }
        }}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Edit Email Template</DialogTitle>
        <DialogContent style={{ marginBottom: "16px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "8px",
              marginBottom: "32px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <TextField
                label="Name"
                style={{ marginRight: "16px", width: "100%", maxWidth: "400px" }}
                variant="outlined"
                value={state.name}
                onChange={(e) => onTemplateChange((newState) => (newState.name = e.target.value))}
              />
              <Autocomplete
                id="version-autocomplete"
                style={{ width: "12em", minWidth: "150px" }}
                options={state.templates.filter((t) => !t.isDisabled || showDisabled)}
                value={version}
                disableClearable
                onChange={(_, newValue) => {
                  if (newValue) {
                    setVersion(newValue);
                  }
                }}
                getOptionLabel={(option: EmailTemplateFormVersion) => {
                  const disabledText = option.isDisabled ? " (disabled)" : "";
                  return `Version ${option.version}${disabledText}`;
                }}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                renderInput={(params) => <TextField {...params} label="Version" variant="outlined" />}
              />
              <CustomIcon
                icon="add"
                style={{ display: "flex", alignItems: "center", marginLeft: "0.5em" }}
                onClick={() => {
                  const newVersion = createNewVersion();
                  newVersion.version = listUtils.max(state.templates, "version", 1) + 1;
                  state.templates.unshift(newVersion);
                  setVersion(newVersion);
                  setState({
                    ...state,
                  });
                }}
              />
              <CustomIcon
                icon="copy"
                style={{ display: "flex", alignItems: "center", marginLeft: "0.5em" }}
                onClick={() => {
                  const newVersion = objectUtils.deepCopyObject(version);
                  newVersion.id = v4();
                  newVersion.version = listUtils.max(state.templates, "version", 1) + 1;
                  newVersion.created = moment();
                  newVersion.updated = moment();

                  state.templates.unshift(newVersion);
                  setVersion(newVersion);
                  setState({
                    ...state,
                  });
                }}
              />
              <CustomIcon
                icon="delete"
                style={{ display: "flex", alignItems: "center", marginLeft: "0.5em" }}
                onClick={() => {
                  const newState = objectUtils.deepCopyObject(state);
                  const matchingVersionIndex = newState.templates.findIndex((t) => t.id === version.id);

                  if (matchingVersionIndex >= 0) {
                    const matchingVersion = newState.templates[matchingVersionIndex];

                    const otherActiveVersions = newState.templates.filter(
                      (t) => t.id !== matchingVersion.id && !t.isDisabled
                    );

                    const hasMoreThanOneActiveVersion = otherActiveVersions.length > 1;

                    if (!hasMoreThanOneActiveVersion) {
                      setAlert({
                        title: "Delete version",
                        message:
                          "Unable to delete the last version for a template. Please create another version before deleting.",
                        isOpen: true,
                      });
                      return;
                    }
                  } else {
                    return;
                  }

                  console.log("disabling version");

                  const disabledVersion = objectUtils.deepCopyObject(version);
                  disabledVersion.isDisabled = true;
                  disabledVersion.updated = moment();

                  newState.templates[matchingVersionIndex] = disabledVersion;

                  const latestVersion = getLatestVersion(newState, showDisabled);
                  setVersion(showDisabled ? disabledVersion : latestVersion);

                  setState(newState);
                }}
              />
            </div>
            {hasDeletedVersions ? (
              <Button size="small" style={{ marginRight: "1em" }} onClick={() => onShowDisabledChange()}>
                {showDisabled ? "Hide deleted versions" : "Show deleted versions"}
              </Button>
            ) : (
              <></>
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} style={{ paddingRight: "8px" }}>
              <Typography variant="subtitle1">Fields</Typography>
              <Divider style={{ marginBottom: "8px" }} />
              {groupedFields.others.map((f) => (
                <Box
                  key={f.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "0.5em",
                    borderRadius: "4px", // You can adjust the value to change the level of rounding
                    border: "1px solid #e0e0e0", // Light gray border color
                    p: "0.5em", // Padding to create some space inside the box
                  }}
                >
                  <CustomIcon
                    icon={
                      f.options.type === "string" ? "textField" : f.options.type === "number" ? "number" : "calendar"
                    }
                    style={{ marginRight: "1em", display: "flex" }}
                  />
                  <span>{f.key}</span>
                </Box>
              ))}
              {groupedFields.conditionals.length > 0 ? (
                <Box sx={{ mt: "2em" }}>
                  <span>Conditionals</span>
                  <Divider style={{ marginBottom: "8px" }} />
                  {groupedFields.conditionals.map((f) => (
                    <Box
                      key={f.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: "0.5em",
                        borderRadius: "4px", // You can adjust the value to change the level of rounding
                        border: "1px solid #e0e0e0", // Light gray border color
                        p: "0.5em", // Padding to create some space inside the box
                      }}
                    >
                      <CustomIcon
                        icon={
                          f.options.type === "conditional"
                            ? f.options.inputType === "string"
                              ? "textField"
                              : f.options.inputType === "number"
                              ? "number"
                              : "boolean"
                            : "question"
                        }
                      />
                      <CustomIcon icon="conditional" rotationDegrees={90} style={{ marginRight: "1em" }} />
                      <span>{f.key}</span>
                    </Box>
                  ))}
                </Box>
              ) : (
                <></>
              )}
              {groupedFields.disabled.length > 0 ? (
                <Box sx={{ mt: "2em" }}>
                  <span style={{ marginTop: "2em" }}>Disabled</span>
                  <Divider style={{ marginBottom: "8px" }} />
                  {groupedFields.disabled.map((f) => (
                    <Box
                      key={f.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: "0.5em",
                        borderRadius: "4px", // You can adjust the value to change the level of rounding
                        border: "1px solid #e0e0e0", // Light gray border color
                        p: "0.5em", // Padding to create some space inside the box
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomIcon
                          icon={
                            f.options.type === "string"
                              ? "textField"
                              : f.options.type === "number"
                              ? "number"
                              : "calendar"
                          }
                          style={{ marginRight: "0.5em" }}
                        />
                        <span style={{ fontStyle: "italic" }}>{f.key}</span>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomIcon
                          icon="delete"
                          onClick={() => {
                            const newVersion = objectUtils.deepCopyObject(version);
                            newVersion.fields = newVersion.fields.filter((nf) => nf.id !== f.id);
                            newVersion.updated = moment();
                            onVersionBlur(newVersion);
                          }}
                        />
                        <CustomIcon icon="restore" onClick={() => insertTextAtCursor(f.rawTemplate)} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1">
                Template
                <span style={{ fontSize: "10px", fontStyle: "italic", marginBottom: "8px" }}>
                  (Updated: {moment(version.updated).format("M/D/YYYY h:mmA")})
                </span>
                <Divider style={{ marginBottom: "8px" }} />
              </Typography>
              <TextField
                id="subject"
                label="Subject"
                fullWidth
                variant="outlined"
                margin="dense"
                value={version.subject}
                onChange={(e) => {
                  const newVersion = objectUtils.deepCopyObject(version);
                  newVersion.subject = e.target.value;
                  newVersion.updated = moment();
                  setVersion(newVersion);
                }}
                onBlur={() => onVersionBlur()}
              />
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CustomIcon
                  icon="textField"
                  tooltip="Text Field"
                  onClick={() => {
                    insertTextAtCursor({ fieldName: "field" });
                  }}
                />
                <CustomIcon
                  icon="calendar"
                  tooltip="Date Field"
                  onClick={() => {
                    insertTextAtCursor({ fieldName: "field", typeOptions: 'date: "%Y-%m-%d"' });
                  }}
                />
                <CustomIcon
                  icon="number"
                  tooltip="Number Field"
                  onClick={() => {
                    insertTextAtCursor({ fieldName: "field", typeOptions: "number: 0" });
                  }}
                />
                <CustomIcon
                  icon="conditional"
                  tooltip="Conditional Statement"
                  onClick={() => {
                    insertTextAtCursor('\n{% if field == "fieldValue" %}\n{% endif %}');
                  }}
                  rotationDegrees={90}
                />
              </Box>
              <TextField
                inputRef={versionTextFieldRef}
                margin="dense"
                id="version-template"
                fullWidth
                multiline
                minRows={20}
                maxRows={20}
                value={version.template}
                onChange={(e) => onVersionChange(e.target.value)}
                onBlur={() => onVersionBlur()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                Created: {moment(state.created).format("M/D/YYYY h:mmA")}
              </span>
              <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                Updated: {moment(state.updated).format("M/D/YYYY h:mmA")}
              </span>
            </Box>
            <div>
              {hasChanges ? <span style={{ color: "red", marginRight: "1em" }}>Unsaved changes</span> : <></>}
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "1em" }}
                onClick={() => {
                  onSave(state);
                  onClose();
                }}
              >
                Save
              </Button>
              <Button style={{ marginRight: "1em" }} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Box>
        </DialogActions>
      </Dialog>
      <CustomAlert
        title={alert.title}
        message={alert.message}
        isOpen={alert.isOpen}
        onClose={() => {
          setAlert({
            title: "",
            message: "",
            isOpen: false,
          });
        }}
      />
    </>
  );
};

export default EditEmailTemplateModal;
