import {
  CompaniesForm,
  CompanyForm,
  EmailTemplateForm,
  EmailTemplateFormField,
  EmailTemplateFormFieldOptions,
  EmailTemplateFormVersion,
  EmailTemplatesForm,
  GlobalForm,
  PeopleForm,
  PersonForm,
  PersonFormEmail,
  PersonFormEmailTemplate,
  PersonFormEmailTemplateField,
  PersonFormEmailTemplateVersion,
  PersonFormNotes,
  PersonFormUsedEmailTemplate,
} from "api/models";
import moment from "moment";
import objectUtils from "./objectUtil";
import { v4 } from "uuid";

const globalKey = "globalData";

const get = (): GlobalForm => {
  const globalData = localStorage.getItem(globalKey);

  const defaultGlobal: GlobalForm = {
    companies: {
      list: [],
    },
    people: {
      list: [],
    },
    emails: {
      list: [],
    },
    ideas: {
      list: [],
    },
  };

  let globalForm: GlobalForm = defaultGlobal;

  if (globalData != null && globalData?.trim() !== "") {
    try {
      const unParsedGlobalData = JSON.parse(globalData) as unknown;

      if (!unParsedGlobalData || typeof unParsedGlobalData !== "object") {
        return globalForm;
      }

      if ("companies" in unParsedGlobalData) {
        globalForm.companies = (unParsedGlobalData.companies as CompaniesForm) ?? { list: [] };
      }

      if ("people" in unParsedGlobalData) {
        globalForm.people = parsePeople(unParsedGlobalData.people);
      }

      if ("emails" in unParsedGlobalData) {
        globalForm.emails = parseEmailTemplates(unParsedGlobalData.emails);
      }
    } catch {}
  }

  return globalForm;
};

const set = (data: GlobalForm): void => {
  localStorage.setItem(globalKey, JSON.stringify(data));
};

const setCompany = (data: GlobalForm, companies: CompanyForm[]): GlobalForm => {
  const newGlobalForm: GlobalForm = objectUtils.deepCopyObject(data);
  newGlobalForm.companies.list = [...companies];
  set(newGlobalForm);

  return newGlobalForm;
};

const setPeople = (data: GlobalForm, people: PersonForm[]): GlobalForm => {
  const newGlobalForm: GlobalForm = objectUtils.deepCopyObject(data);
  newGlobalForm.people.list = objectUtils.deepCopyObject(people);
  set(newGlobalForm);
  return newGlobalForm;
};

const setEmails = (data: GlobalForm, emails: EmailTemplateForm[]): GlobalForm => {
  const newGlobalForm: GlobalForm = objectUtils.deepCopyObject(data);
  newGlobalForm.emails.list = objectUtils.deepCopyObject(emails);
  set(newGlobalForm);
  return newGlobalForm;
};

const localStorageUtil = {
  get,
  set: set,
  setCompany: setCompany,
  setPeople: setPeople,
  setEmails: setEmails,
};

export default localStorageUtil;

function parseProperty<T, K extends keyof T>(
  propertyName: K,
  data: unknown,
  defaultValue: T[K] | ((data: any) => T[K])
): T[K] {
  if (typeof data === "object" && data !== null && propertyName in data) {
    const value = (data as Record<K, T[K]>)[propertyName];
    if (value !== undefined && value !== null) {
      return value;
    }
  }
  return typeof defaultValue === "function" ? (defaultValue as (data: any) => T[K])(data) : defaultValue;
}

/**
 * Generated by ChatGPT
 * @param data
 * @returns
 */
const parsePeople = (data: unknown): PeopleForm => {
  if (!data || typeof data !== "object" || !("list" in data) || !data.list || !Array.isArray(data.list)) {
    return { list: [] };
  }

  const list = data.list;

  return {
    list: list.map((item: unknown) => {
      if (typeof item === "object" && item !== null) {
        const parsedPersonForm: PersonForm = {
          id: parseProperty<PersonForm, "id">("id", item, ""),
          name: parseProperty<PersonForm, "name">("name", item, ""),
          age: parseProperty<PersonForm, "age">("age", item, undefined),
          companyId: parseProperty<PersonForm, "companyId">("companyId", item, ""),
          jobTitle: parseProperty<PersonForm, "jobTitle">("jobTitle", item, ""),
          linkedinUrl: parseProperty<PersonForm, "linkedinUrl">("linkedinUrl", item, ""),
          linkedinUrlShorthand: parseProperty<PersonForm, "linkedinUrlShorthand">(
            "linkedinUrlShorthand",
            item,
            undefined
          ),
          emailAddress: parseProperty<PersonForm, "emailAddress">("emailAddress", item, ""),
          phone: parseProperty<PersonForm, "phone">("phone", item, ""),
          twitterHandle: parseProperty<PersonForm, "twitterHandle">("twitterHandle", item, ""),
          notes: [],
          emails: [],
        };

        if ("notes" in item && item.notes && Array.isArray(item.notes)) {
          const parsedNotes = item.notes.map((noteItem: unknown) => {
            const note: PersonFormNotes = {
              id: parseProperty<PersonFormNotes, "id">("id", noteItem, ""),
              note: parseProperty<PersonFormNotes, "note">("note", noteItem, ""),
              created: parseProperty<PersonFormNotes, "created">("created", noteItem, (createdData) =>
                moment(createdData)
              ),
              updated: parseProperty<PersonFormNotes, "updated">("updated", noteItem, (updatedData) =>
                moment(updatedData)
              ),
            };
            return note;
          });

          parsedPersonForm.notes = parsedNotes;
        }

        if ("emails" in item && item.emails && Array.isArray(item.emails)) {
          const emails: PersonFormEmail[] = item.emails.map((emailItem: unknown) => {
            const email: PersonFormEmail = {
              id: parseProperty<PersonFormEmail, "id">("id", emailItem, ""),
              subject: parseProperty<PersonFormEmail, "subject">("subject", emailItem, ""),
              customEmail: parseProperty<PersonFormEmail, "customEmail">("customEmail", emailItem, ""),
              created: parseProperty<PersonFormEmail, "created">("created", emailItem, (createdData) =>
                moment(createdData)
              ),
              updated: parseProperty<PersonFormEmail, "updated">("updated", emailItem, (updatedData) =>
                moment(updatedData)
              ),
            };

            // Check if 'usedTemplate' exists
            if (
              emailItem &&
              typeof emailItem === "object" &&
              "usedTemplate" in emailItem &&
              emailItem.usedTemplate &&
              typeof emailItem.usedTemplate === "object" &&
              "template" in emailItem.usedTemplate
            ) {
              const usedTemplate: PersonFormUsedEmailTemplate = {
                parsedEmail: parseProperty<PersonFormUsedEmailTemplate, "parsedEmail">(
                  "parsedEmail",
                  emailItem.usedTemplate,
                  ""
                ),
                template: parsePersonFormEmailTemplate(emailItem.usedTemplate.template),
              };

              email.usedTemplate = usedTemplate;
            }

            return email;
          });

          parsedPersonForm.emails = emails;
        }

        return parsedPersonForm;
      }

      const defaultPerson: PersonForm = {
        id: "",
        name: "",
        age: undefined,
        companyId: "",
        jobTitle: "",
        linkedinUrl: "",
        linkedinUrlShorthand: undefined,
        emailAddress: "",
        phone: "",
        twitterHandle: "",
        notes: [],
        emails: [],
      };

      return defaultPerson;
    }),
  };
};

const parsePersonFormEmailTemplate = (templateItem: unknown): PersonFormEmailTemplate => {
  if (typeof templateItem === "object" && templateItem !== null && "template" in templateItem) {
    return {
      id: parseProperty<PersonFormEmailTemplate, "id">("id", templateItem, ""),
      name: parseProperty<PersonFormEmailTemplate, "name">("name", templateItem, ""),
      template: parseEmailTemplateVersion(templateItem.template),
      created: parseProperty<PersonFormEmailTemplate, "created">("created", templateItem, (createdData) =>
        moment(createdData)
      ),
      updated: parseProperty<PersonFormEmailTemplate, "updated">("updated", templateItem, (updatedData) =>
        moment(updatedData)
      ),
    };
  }

  return {
    id: v4(),
    name: "",
    template: parseEmailTemplateVersion({}),
    created: moment(),
    updated: moment(),
  };
};

const parseEmailTemplateVersion = (versionItem: unknown): PersonFormEmailTemplateVersion => {
  if (typeof versionItem === "object" && versionItem !== null) {
    return {
      id: parseProperty<PersonFormEmailTemplateVersion, "id">("id", versionItem, ""),
      version: parseProperty<PersonFormEmailTemplateVersion, "version">("version", versionItem, -1),
      fields: "fields" in versionItem && versionItem.fields ? parseEmailTemplateFields(versionItem.fields) : [],
      template: parseProperty<PersonFormEmailTemplateVersion, "template">("template", versionItem, ""),
      created: parseProperty<PersonFormEmailTemplateVersion, "created">("created", versionItem, (createdData) =>
        moment(createdData)
      ),
      updated: parseProperty<PersonFormEmailTemplateVersion, "updated">("updated", versionItem, (updatedData) =>
        moment(updatedData)
      ),
    };
  }

  return {
    id: v4(),
    version: -1,
    fields: [],
    template: "",
    created: moment(),
    updated: moment(),
  };
};

const parseEmailTemplateFields = (fieldsItem: unknown): PersonFormEmailTemplateField[] => {
  if (Array.isArray(fieldsItem)) {
    return fieldsItem.map((fieldItem: unknown) => {
      if (typeof fieldItem === "object" && fieldItem !== null) {
        return {
          id: parseProperty<PersonFormEmailTemplateField, "id">("id", fieldItem, ""),
          rawTemplate: parseProperty<PersonFormEmailTemplateField, "rawTemplate">("rawTemplate", fieldItem, ""),
          key: parseProperty<PersonFormEmailTemplateField, "key">("key", fieldItem, ""),
          options: parseProperty<PersonFormEmailTemplateField, "options">("options", fieldItem, {
            type: "string",
          }),
          value: parseProperty<PersonFormEmailTemplateField, "value">("value", fieldItem, ""),
          order: parseProperty<PersonFormEmailTemplateField, "order">("order", fieldItem, 0),
          created: parseProperty<PersonFormEmailTemplateField, "created">("created", fieldItem, (createdData) =>
            moment(createdData)
          ),
          updated: parseProperty<PersonFormEmailTemplateField, "updated">("updated", fieldItem, (updatedData) =>
            moment(updatedData)
          ),
        };
      }

      return {
        id: v4(),
        rawTemplate: "",
        key: "",
        options: { type: "string" },
        value: "",
        order: -1,
        created: moment(),
        updated: moment(),
      };
    });
  }

  return [];
};

/**
 * Generated by ChatGPT
 * @param data
 * @returns
 */
const parseEmailTemplates = (data: unknown): EmailTemplatesForm => {
  if (!data || typeof data !== "object" || !("list" in data) || !data.list || !Array.isArray(data.list)) {
    return { list: [] };
  }

  const list = data.list;

  return {
    list: list.map((item: unknown) => {
      if (typeof item === "object" && item !== null) {
        const parsedEmailTemplateForm: EmailTemplateForm = {
          id: parseProperty<EmailTemplateForm, "id">("id", item, ""),
          name: parseProperty<EmailTemplateForm, "name">("name", item, ""),
          order: parseProperty<EmailTemplateForm, "order">("order", item, -1),
          templates: [],
          isDisabled: parseProperty<EmailTemplateFormVersion, "isDisabled">("isDisabled", item, false),
          created: parseProperty<EmailTemplateForm, "created">("created", item, (createdData) => moment(createdData)),
          updated: parseProperty<EmailTemplateForm, "updated">("updated", item, (updatedData) => moment(updatedData)),
        };

        if ("templates" in item && item.templates && Array.isArray(item.templates)) {
          const parsedTemplates = item.templates.map((templateItem: unknown) => {
            if (typeof templateItem === "object" && templateItem !== null) {
              const parsedTemplate: EmailTemplateFormVersion = {
                id: parseProperty<EmailTemplateFormVersion, "id">("id", templateItem, ""),
                version: parseProperty<EmailTemplateFormVersion, "version">("version", templateItem, -1),
                subject: parseProperty<EmailTemplateFormVersion, "subject">("subject", templateItem, ""),
                template: parseProperty<EmailTemplateFormVersion, "template">("template", templateItem, ""),
                defaultValue: parseProperty<EmailTemplateFormVersion, "defaultValue">("defaultValue", templateItem, ""),
                fields: [],
                isDisabled: parseProperty<EmailTemplateFormVersion, "isDisabled">("isDisabled", templateItem, false),
                created: parseProperty<EmailTemplateFormVersion, "created">("created", templateItem, (createdData) =>
                  moment(createdData)
                ),
                updated: parseProperty<EmailTemplateFormVersion, "updated">("updated", templateItem, (updatedData) =>
                  moment(updatedData)
                ),
              };

              if ("fields" in templateItem && templateItem.fields && Array.isArray(templateItem.fields)) {
                const parsedFields = templateItem.fields.map((fieldItem: unknown) => {
                  if (typeof fieldItem === "object" && fieldItem !== null) {
                    const options: EmailTemplateFormFieldOptions = parseProperty<EmailTemplateFormField, "options">(
                      "options",
                      fieldItem,
                      { type: "string" }
                    );

                    const parsedField: EmailTemplateFormField = {
                      id: parseProperty<EmailTemplateFormField, "id">("id", fieldItem, ""),
                      rawTemplate: parseProperty<EmailTemplateFormField, "rawTemplate">("rawTemplate", fieldItem, ""),
                      key: parseProperty<EmailTemplateFormField, "key">("key", fieldItem, ""),
                      options,
                      order: parseProperty<EmailTemplateFormField, "order">("order", fieldItem, 0),
                      isDisabled: parseProperty<EmailTemplateFormField, "isDisabled">("isDisabled", fieldItem, false),
                      created: parseProperty<EmailTemplateFormField, "created">("created", fieldItem, (createdData) =>
                        moment(createdData)
                      ),
                      updated: parseProperty<EmailTemplateFormField, "updated">("updated", fieldItem, (updatedData) =>
                        moment(updatedData)
                      ),
                    };
                    return parsedField;
                  }

                  const defaultField: EmailTemplateFormField = {
                    id: v4(),
                    rawTemplate: "",
                    key: "",
                    options: { type: "string" },
                    order: -1,
                    isDisabled: false,
                    created: moment(),
                    updated: moment(),
                  };
                  return defaultField;
                });

                parsedTemplate.fields = parsedFields;
              }

              return parsedTemplate;
            }

            const defaultTemplate: EmailTemplateFormVersion = {
              id: v4(),
              version: -1,
              fields: [],
              subject: "",
              template: "",
              defaultValue: "",
              isDisabled: false,
              created: moment(),
              updated: moment(),
            };
            return defaultTemplate;
          });

          parsedEmailTemplateForm.templates = parsedTemplates;
        }

        return parsedEmailTemplateForm;
      }

      const defaultEmailTemplate: EmailTemplateForm = {
        id: v4(),
        name: "",
        order: -1,
        templates: [],
        isDisabled: false,
        created: moment(),
        updated: moment(),
      };

      return defaultEmailTemplate;
    }),
  };
};
