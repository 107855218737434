// Home.tsx
import React from "react";
import { useHomeStore } from "./store/useHomeStore";
import Phase1Card from "./Phase1Card";
import Phase2Card from "./Phase2Card";
import Phase3Card from "./Phase3Card";

const cardContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  gap: "2px",
};

const Home: React.FC = () => {
  const { state } = useHomeStore();

  return (
    <div>
      <h2>Home Page</h2>
      <div style={cardContainerStyle}>
        <Phase1Card isActive={state.isPhase1CardActive} />
        <Phase2Card isActive={state.isPhase2CardActive} />
        <Phase3Card isActive={state.isPhase3CardActive} />
      </div>
    </div>
  );
};

export default Home;
