// People.tsx
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import { usePeopleStore } from "./store/usePeopleStore";
import ConfirmationDialog from "components/ConfirmationDialog";
import Spinner from "components/Spinner";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 } from "uuid";
import { PersonForm, PersonFormEmail } from "api/models";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import moment from "moment";
import MarkdownIt from "markdown-it";
import style from "./index.module.scss";
import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import listUtils from "utils/listUtils";
import SendEmailDialog from "./SendEmailDialog";
import stringUtils from "utils/stringUtils";
import objectUtils from "utils/objectUtil";

const People: React.FC = () => {
  const [state, actions] = usePeopleStore();
  const [count, setCount] = useState(0);
  const [newNote, setNewNote] = useState("");

  const md = new MarkdownIt();

  const people = state.people || [];
  const companies = state.availableCompanies;

  useEffectOnLoad(() => actions.load());

  const focusNameInput = (index: number) => {
    const nameInput = document.getElementById(`person-name-input-${index}`);
    if (nameInput) {
      nameInput.focus();
    }
  };

  const focusLastPersonNameMemo = useMemo(
    () => () => {
      if (state.people.length > 0) {
        const lastIndex = state.people.length - 1;
        focusNameInput(lastIndex);
      }
    },
    [state]
  );

  useEffect(() => {
    if (count !== 0 && count < state.people.length) {
      focusLastPersonNameMemo();
    }

    setCount(state.people.length);
  }, [state, count, focusLastPersonNameMemo]);

  if (state.isLoading) {
    return <Spinner isLoading={state.isLoading} />;
  }

  const onDeleteConfirmation = () => {
    // Perform the actual deletion here
    const personToDelete = people.find((item) => item.id === state.deletingPerson?.id);
    if (personToDelete) {
      const updatedPeople = [...people].filter((p) => p.id !== personToDelete.id);
      actions.savePeople(updatedPeople);
    }
    actions.toggleDeleteModal(false); // Close the delete confirmation dialog
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewNote(e.target.value);
  };

  const editingPerson = state.editingPerson;
  const companyDictionary: Record<string, string> = Object.fromEntries(
    companies.map((business) => [business.id, business.name])
  );

  const addNote = () => {
    if (newNote.trim() === "") {
      return;
    }

    if (editingPerson) {
      actions.updatePerson(editingPerson.id, {
        ...editingPerson,
        notes: [
          {
            id: v4(),
            note: newNote,
            created: moment(),
            updated: moment(),
          },
          ...(editingPerson.notes ?? []),
        ],
      });

      setNewNote("");
    }
  };

  const deleteNote = (noteId: string) => {
    if (editingPerson) {
      const updatedNotes = editingPerson?.notes?.filter((note) => note.id !== noteId) ?? [];
      actions.updatePerson(editingPerson.id, {
        ...editingPerson,
        notes: updatedNotes,
      });
    }
  };

  if (state.isLoading) {
    return <Spinner isLoading={state.isLoading} />;
  }

  const onTableChange = (updatedRecord: PersonForm) => {
    actions.savePerson(updatedRecord);
  };

  const onTableChangeAll = (updatedRecords: PersonForm[]) => {
    actions.savePeople(updatedRecords);
  };

  const table: AdvancedTableRecords<PersonForm> = {
    fields: [
      {
        id: "name",
        label: "Name",
      },
      {
        id: "companyId",
        label: "Company",
        readOnly: true,
        custom: (form: PersonForm) => companyDictionary[form.companyId],
      },
      {
        id: "emailAddress",
        label: "Email",
      },
      {
        id: "linkedinUrl",
        label: "LinkedIn",
        createInputLabel: (personForm: PersonForm) => {
          return personForm.linkedinUrlShorthand ?? "";
        },
        websiteOptions: {
          customUrl: (personForm: PersonForm) => {
            if (personForm.linkedinUrl.trim() === "") {
              return {
                url: `https://www.linkedin.com/search/results/people/?keywords=${personForm.name}`,
                icon: "search",
                isDisabled: personForm.name.trim() === "",
              };
            }
            return {
              url: personForm.linkedinUrl,
              icon: "openInNew",
              isDisabled: personForm.linkedinUrl.trim() === "",
            };
          },
        },
      },
    ],
    data: [...people],
    actions: [
      {
        icon: "edit",
        onClick: (personForm: PersonForm) => actions.toggleEditModal(true, personForm),
        tooltip: "Basic Edit",
        tooltipFollowMouse: true,
      },
      {
        icon: "email",
        onClick: (personForm: PersonForm) => actions.toggleEmailModal(true, personForm),
        tooltip: "Emails",
        tooltipFollowMouse: true,
        isDisabled: (personForm: PersonForm) => stringUtils.isNullOrWhitespace(personForm.emailAddress),
        getBadge: (personForm: PersonForm) => {
          if (personForm.emails.length > 0) {
            return personForm.emails.length.toString();
          }

          return undefined;
        },
      },
      {
        icon: "delete",
        onClick: (personForm: PersonForm) => actions.toggleDeleteModal(true, personForm),
        tooltip: "Delete Person?",
        tooltipFollowMouse: true,
        onSelectOnly: true,
      },
    ],
  };

  const createDefaultPersonForm = () => {
    const defaultForm: PersonForm = {
      id: v4(),
      name: "",
      companyId: "",
      jobTitle: "",
      linkedinUrl: "",
      linkedinUrlShorthand: "",
      emailAddress: "",
      phone: "",
      twitterHandle: "",
      notes: [],
      emails: [],
    };

    return defaultForm;
  };

  return (
    <div>
      <AdvancedTable
        title="People"
        table={table}
        orderByKey="name"
        createDefaultRecord={createDefaultPersonForm}
        onChange={onTableChange}
        onChangeAll={onTableChangeAll}
        onDelete={(recordsToDelete) => {
          const newPeople = people.filter((p) => !recordsToDelete.some((r) => r.id === p.id));

          const newState = { ...state };
          newState.people = newPeople;
          actions.savePeople(newState.people);
        }}
        isSearchMatch={(item, query) => {
          return (
            item.name.toLowerCase().includes(query.toLocaleLowerCase()) ||
            item.emailAddress.toLocaleLowerCase().includes(query.toLocaleLowerCase())
          );
        }}
        getDuplicates={(items) => {
          const emailMap: Map<string, PersonForm[]> = new Map();

          for (const item of items) {
            const email = item.emailAddress?.trim().toLocaleLowerCase();

            if (!stringUtils.isNullOrWhitespace(email)) {
              if (!emailMap.has(email)) {
                emailMap.set(email, []);
              }
              emailMap.get(email)?.push(item);
            }
          }

          const duplicates: PersonForm[] = [];
          for (const people of emailMap.values()) {
            if (people.length > 1) {
              console.log("dupes:", people);
              duplicates.push(...people);
            }
          }

          return duplicates;
        }}
      />
      <Dialog open={state.isEditModalOpen} onClose={() => actions.toggleEditModal(false)}>
        <DialogTitle>Edit Person</DialogTitle>
        <DialogContent>
          {editingPerson && (
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                value={editingPerson.name}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                id="emailAddress"
                label="Email Address"
                fullWidth
                variant="standard"
                value={editingPerson.emailAddress}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    emailAddress: e.target.value,
                  })
                }
              />
              <Autocomplete
                id="company-id-autocomplete"
                options={listUtils.sortByStringProperty(companies, "name", "asc")}
                getOptionLabel={(option) => option.name}
                value={
                  editingPerson.companyId ? companies.find((company) => company.id === editingPerson.companyId) : null
                }
                onChange={(event, newValue) => {
                  // if (newValue) {
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    companyId: newValue?.id ?? "",
                  });
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    id="businessId"
                    label="Business ID"
                    fullWidth
                    variant="standard"
                  />
                )}
              />

              <TextField
                margin="dense"
                id="jobTitle"
                label="Job Title"
                fullWidth
                variant="standard"
                value={editingPerson.jobTitle}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    jobTitle: e.target.value,
                  })
                }
              />
              <div style={{ display: "flex" }}>
                <TextField
                  margin="dense"
                  id="linkedinUrl"
                  label="LinkedIn URL"
                  fullWidth
                  variant="standard"
                  value={editingPerson.linkedinUrl}
                  onChange={(e) =>
                    actions.updatePerson(editingPerson.id, {
                      ...editingPerson,
                      linkedinUrl: e.target.value,
                    })
                  }
                />

                <a
                  href={
                    editingPerson.linkedinUrl
                      ? editingPerson.linkedinUrl
                      : "https://www.linkedin.com/search/results/people/?keywords=" +
                        encodeURI(editingPerson?.name ?? "")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(10, 102, 194)",
                  }}
                >
                  {editingPerson.linkedinUrl ? <OpenInNewIcon fontSize="medium" /> : <SearchIcon fontSize="medium" />}
                </a>
              </div>
              <TextField
                margin="dense"
                id="phone"
                label="Phone"
                fullWidth
                variant="standard"
                value={editingPerson.phone}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    phone: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                id="twitterHandle"
                label="Twitter Handle"
                fullWidth
                variant="standard"
                value={editingPerson.twitterHandle}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    twitterHandle: e.target.value,
                  })
                }
              />
              {/* New Note Input */}
              <div style={{ display: "flex" }}>
                <TextField
                  margin="dense"
                  id="newNote"
                  label="Add New Note"
                  fullWidth
                  multiline
                  maxRows={4}
                  variant="standard"
                  value={newNote}
                  onChange={(e) => handleNoteChange(e)}
                />
                <IconButton onClick={() => addNote()}>
                  <AddIcon />
                </IconButton>
              </div>

              <List style={{ maxHeight: "200px", overflowY: "auto" }}>
                {editingPerson?.notes?.map((note) => (
                  <ListItem key={note.id} sx={{ width: "100%" }}>
                    <ListItemText
                      primary={
                        <div className={style.markdownComment}>
                          <div className={style.markdownCommentLeft}>
                            <Avatar>U</Avatar>
                          </div>
                          <div className={style.markdownCommentRight}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <span>{moment(note.created).format("M/D/YYYY h:mmA")}</span>
                              <div>
                                <IconButton onClick={() => deleteNote(note.id)} edge="end" aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: md.render(note.note) }}></div>
                          </div>
                        </div>
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const personIndexToUpdate = people.findIndex((p) => p.id === editingPerson?.id);

              if (personIndexToUpdate >= 0) {
                const personToUpdate = people[personIndexToUpdate];
                actions.savePerson({
                  ...personToUpdate,
                  ...editingPerson,
                });
              }
            }}
          >
            Save
          </Button>
          <Button onClick={() => actions.toggleEditModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={state.isDeleteModalOpen}
        onClose={() => actions.toggleDeleteModal(false)}
        onConfirm={onDeleteConfirmation}
        title="Delete Person?"
        message={`Are you sure you want to delete "${state.deletingPerson?.name}"?`}
      />
      <SendEmailDialog
        isOpen={state.isEmailModalOpen}
        onSave={(newEmail: PersonFormEmail) => {
          if (state.editingPerson) {
            const updatedPerson = objectUtils.deepCopyObject(state.editingPerson);
            updatedPerson.emails = updatedPerson.emails ?? [];
            updatedPerson.emails.push(newEmail);

            actions.savePerson(updatedPerson);
          }
        }}
        onClose={() => actions.toggleEmailModal(false)}
        person={state.editingPerson || createDefaultPersonForm()}
        emailTemplates={state.availableEmailTemplates}
      />
    </div>
  );
};

export default People;
