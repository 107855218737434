import React, { PropsWithChildren } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface PhaseCardProps {
  title: string;
  actionButtonTitle: string;
  onActionClick: () => void;
  onInfoClick: () => void;
  isActive: boolean;
}

const cardItemStyle = (isActive: boolean): React.CSSProperties => ({
  flex: isActive ? "1 1 50%" : "1 1 25%",
  fontWeight: isActive ? "bold" : "normal",
});

const PhaseCard = (props: PropsWithChildren<PhaseCardProps>) => {
  const {
    title,
    actionButtonTitle,
    onActionClick,
    onInfoClick,
    isActive,
    children,
  } = props;

  return (
    <div style={cardItemStyle(isActive)}>
      <Card
        variant="outlined"
        sx={{
          border: isActive ? "2px solid black" : "1px solid #000000",
        }}
      >
        <CardContent style={{ textAlign: "center" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          {children}
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={onActionClick}>
            {actionButtonTitle}
          </Button>
          <div style={{ flex: "1" }}></div>
          <IconButton
            color="primary"
            onClick={onInfoClick}
            style={{
              backgroundColor: "transparent",
            }}
          >
            <InfoIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
};

export default PhaseCard;
