import { IdAble } from "api/models";
import { jsonMember, jsonArrayMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";

// Interfaces

export interface TeamStandingsTeam extends IdAble {
  id: string;
  teamName: string;
  totalMatchPoints: number;
}

export interface TeamStandings {
  teams: TeamStandingsTeam[];
}

// Classes

@jsonObject
export class TeamStandingsTeamBase implements TeamStandingsTeam {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public teamName: string = "";

  @jsonMember(Number)
  public totalMatchPoints: number = 0;

  static create(newObject: TeamStandingsTeam): TeamStandingsTeamBase {
    return objectUtils.deepCopyTypedObject(TeamStandingsTeamBase, newObject);
  }
}

@jsonObject
export class TeamStandingsBase implements TeamStandings {
  @jsonArrayMember(TeamStandingsTeamBase)
  public teams: TeamStandingsTeamBase[] = [];

  static create(newObject: TeamStandings): TeamStandingsBase {
    return objectUtils.deepCopyTypedObject(TeamStandingsBase, newObject);
  }
}
