import moment from "moment";
import type { Moment } from "moment";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";
import { typedJsonUtils } from "utils/typedJsonUtils";

const momentJsonOptions = typedJsonUtils.momentOptions("YYYY-MM-DDTHH:mm:ssZ");

// Interfaces

export interface ThisWeekMatchup {
  id: string;
  teamOnePlayersName: string;
  teamTwoPlayersName: string;
  teamOnePlayerOneId: string;
  teamOnePlayerTwoId: string;
  teamTwoPlayerOneId: string;
  teamTwoPlayerTwoId: string;
  matchTime: Moment;
  court: string;
}

export interface ThisWeek {
  id: string;
  matchWeek: Moment;
  firstMatchupName: string;
  firstMatchupTeamOneId: string;
  firstMatchupTeamTwoId: string;
  firstMatchups: ThisWeekMatchup[];
  secondMatchupName: string;
  secondMatchupTeamOneId: string;
  secondMatchupTeamTwoId: string;
  secondMatchups: ThisWeekMatchup[];
}

// Classes

@jsonObject
export class ThisWeekMatchupBase implements ThisWeekMatchup {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public teamOnePlayersName: string = "";

  @jsonMember(String)
  public teamTwoPlayersName: string = "";

  @jsonMember(String)
  public teamOnePlayerOneId: string = "";

  @jsonMember(String)
  public teamOnePlayerTwoId: string = "";

  @jsonMember(String)
  public teamTwoPlayerOneId: string = "";

  @jsonMember(String)
  public teamTwoPlayerTwoId: string = "";

  @jsonMember(String)
  public court: string = "-";

  @jsonMember(moment, momentJsonOptions)
  public matchTime: Moment = moment();

  static create(newObject: ThisWeekMatchup): ThisWeekMatchupBase {
    return objectUtils.deepCopyTypedObject(ThisWeekMatchupBase, newObject);
  }
}

@jsonObject
export class ThisWeekBase implements ThisWeek {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(moment, momentJsonOptions)
  public matchWeek: Moment = moment();

  @jsonMember(String)
  public firstMatchupName: string = "";

  @jsonMember(String)
  public firstMatchupTeamOneId: string = "";

  @jsonMember(String)
  public firstMatchupTeamTwoId: string = "";

  @jsonArrayMember(ThisWeekMatchupBase)
  public firstMatchups: ThisWeekMatchupBase[] = [];

  @jsonMember(String)
  public secondMatchupName: string = "";

  @jsonMember(String)
  public secondMatchupTeamOneId: string = "";

  @jsonMember(String)
  public secondMatchupTeamTwoId: string = "";

  @jsonArrayMember(ThisWeekMatchupBase)
  public secondMatchups: ThisWeekMatchupBase[] = [];

  static create(newObject: ThisWeek): ThisWeekBase {
    return objectUtils.deepCopyTypedObject(ThisWeekBase, newObject);
  }
}
