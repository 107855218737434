import { EnvironmentName, Domain } from "components/Config";
import About from "pages/About";
import AspectRatio from "pages/AspectRatio";
import ColorPalette from "pages/ColorPalette";
import Companies from "pages/Companies";
import EmailValidator from "pages/EmailValidator";
import Emails from "pages/Emails";
import Home from "pages/Home";
import NamecheapPage from "pages/Namecheap";
import PaddleMatchesPage from "pages/PaddleMatches";
import PaddlePlayersPage from "pages/PaddlePlayers";
import PaddleTeamStandingsPage from "pages/PaddleTeamStandings";
import PaddleThisWeekPage from "pages/PaddleThisWeek";
import People from "pages/People";
import WarmNiches from "pages/WarmNiches";
import { ValueOf } from "utils/valueOf";

export const Routes = {
  home: "/",
  about: "/about",
  warmNiches: "/warmNiches",
  businesses: "/businesses",
  people: "/people",
  colors: "/colors",
  aspects: "/aspects",
  emailValidation: "/emailValidation",
  teamStandings: "/teamStandings",
  matches: "/matches",
  players: "/players",
  thisWeek: "/thisWeek",
} as const;

export type Route = ValueOf<typeof Routes>;

export interface FullRoute {
  path: Route;
  title: string;
  element: JSX.Element;
  isPrimary: boolean;
  highestEnvironment: EnvironmentName;
}

const secondaryRoutes: FullRoute[] = [
  {
    path: "/colors",
    title: "Colors",
    element: <ColorPalette />,
    isPrimary: false,
    highestEnvironment: "local",
  },
  {
    path: "/aspects",
    title: "W x H",
    element: <AspectRatio />,
    isPrimary: false,
    highestEnvironment: "local",
  },
  {
    path: "/emailValidation",
    title: "Email Validation",
    element: <EmailValidator />,
    isPrimary: false,
    highestEnvironment: "local",
  },
  {
    path: "/namecheap",
    title: "Namecheap Checker",
    element: <NamecheapPage />,
    isPrimary: false,
    highestEnvironment: "local",
  },
] as FullRoute[];

const fullRoutes: FullRoute[] = [
  {
    path: "/",
    title: "Home",
    element: <Home />,
    isPrimary: false,
    highestEnvironment: "prod",
  },
  {
    path: "/about",
    title: "About",
    element: <About />,
    isPrimary: true,
    highestEnvironment: "local",
  },
  {
    path: "/warmNiches",
    title: "Warm Niches",
    element: <WarmNiches />,
    isPrimary: false,
    highestEnvironment: "prod",
  },
  {
    path: "/companies",
    title: "Companies",
    element: <Companies />,
    isPrimary: true,
    highestEnvironment: "prod",
  },
  {
    path: "/people",
    title: "People",
    element: <People />,
    isPrimary: true,
    highestEnvironment: "prod",
  },
  {
    path: "/emails",
    title: "Emails",
    element: <Emails />,
    isPrimary: true,
    highestEnvironment: "prod",
  },
  ...secondaryRoutes.map((r) => {
    r.isPrimary = false;
    return r;
  }),
] as FullRoute[];

export type SiteRoute = {
  [x in Domain]: FullRoute[];
};

export const SiteRoutes: SiteRoute = {
  "fabledfounders.com": fullRoutes,
  "titanpaddle.com": [
    {
      path: "/thisWeek",
      title: "This Week",
      element: <PaddleThisWeekPage />,
      isPrimary: true,
      highestEnvironment: "prod",
    },
    {
      path: "/",
      title: "Standings",
      element: <PaddleTeamStandingsPage />,
      isPrimary: true,
      highestEnvironment: "prod",
    },
    {
      path: "/matches",
      title: "Matches",
      element: <PaddleMatchesPage />,
      isPrimary: true,
      highestEnvironment: "prod",
    },
    {
      path: "/players",
      title: "Players",
      element: <PaddlePlayersPage />,
      isPrimary: true,
      highestEnvironment: "prod",
    },
    ...secondaryRoutes.map((r) => {
      r.isPrimary = false;
      return r;
    }),
  ],
};
