import { PropsWithChildren } from "react";
import { Dialog, DialogContent, Box } from "@mui/material";
import CustomIcon from "./CustomIcon";

interface FullScreenModalProps {
  isHidden: boolean;
  onClose: () => void;
}

const FullScreenModal = (props: PropsWithChildren<FullScreenModalProps>) => {
  const { isHidden, onClose, children } = props;

  if (isHidden) {
    return <></>;
  }

  return (
    <Dialog fullScreen open onClose={onClose}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomIcon icon="close" onClick={onClose} />
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenModal;
