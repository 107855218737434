import { jsonMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";

export interface Settings {
  exportFileName: string;
}

@jsonObject
export class SettingsBase implements Settings {
  @jsonMember(String)
  exportFileName: string = "";

  static create(newObject: Settings): SettingsBase {
    return objectUtils.deepCopyTypedObject(SettingsBase, newObject);
  }
}
