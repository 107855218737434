// useEmailsStore.tsx
import { EmailTemplateForm, GlobalForm } from "api/models";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useReducer } from "react";
import localStorageUtil from "utils/localStorageUtil";
import objectUtils from "utils/objectUtil";
import moment from "moment";
import listUtils from "utils/listUtils";

// Define action types
export enum ActionType {
  Load = "Load",
  LoadGlobal = "LoadGlobal",
  SaveEmailTemplates = "SaveEmailTemplates",
  SaveEmailTemplate = "SaveEmailTemplate",
  UpdateEmailTemplate = "UpdateEmailTemplate",
  ToggleDeleteModal = "ToggleEmailTemplateDeleteModal",
  ToggleEditModal = "ToggleEmailTemplateEditModal",
}

type GlobalEmailsState = {
  globalForm: GlobalForm;
  emailsState: EmailsState;
};

type EmailsState = {
  emailTemplates: EmailTemplateForm[]; // Updated type
  isInitiallyLoaded: boolean;
  shouldSave: boolean;
  isLoading: boolean;
  isDeleteModalOpen: boolean; // Updated property name
  isEditModalOpen: boolean; // Updated property name
  deletingEmailTemplate: EmailTemplateForm | undefined; // Updated property name
  editingEmailTemplate: EmailTemplateForm; // Updated property name and type
};

type Action =
  | { type: ActionType.Load; payload: { globalForm: GlobalForm; emailTemplates: EmailTemplateForm[] } }
  | { type: ActionType.LoadGlobal; payload: { globalForm: GlobalForm } }
  | { type: ActionType.SaveEmailTemplates; payload: EmailTemplateForm[] } // New action type
  | {
      type: ActionType.SaveEmailTemplate; // New action type
      payload: EmailTemplateForm; // New payload for saving a single email template
    }
  | {
      type: ActionType.UpdateEmailTemplate; // Updated action type
      payload: { id: string; emailTemplate: EmailTemplateForm }; // Updated payload
    }
  | {
      type: ActionType.ToggleDeleteModal; // Updated action type
      payload: { isOpen: boolean; emailTemplate?: EmailTemplateForm }; // Updated payload name
    }
  | {
      type: ActionType.ToggleEditModal; // Updated action type
      payload: { isOpen: boolean; editingEmailTemplate?: EmailTemplateForm }; // Updated payload name and type
    };

const localUtils = {
  sortEmailTemplatesByOrder: (emailTemplates: EmailTemplateForm[], sortOrder: "asc" | "desc"): EmailTemplateForm[] => {
    const sortedEmailTemplates = [...emailTemplates].sort((a, b) => {
      const valueA = a.order;
      const valueB = b.order;

      if (valueA === valueB) {
        return 0;
      }

      const sortDirection = sortOrder === "desc" ? -1 : 1;
      return valueA - valueB * sortDirection;
    });

    return [...sortedEmailTemplates];
  },
};

// Reducer function
const reducer = (state: GlobalEmailsState, action: Action): GlobalEmailsState => {
  const newState = objectUtils.deepCopyObject(state);

  switch (action.type) {
    case ActionType.Load: {
      const { globalForm, emailTemplates } = action.payload;
      const emailTemplatesWithAtLeastOne = [...emailTemplates];

      if (emailTemplatesWithAtLeastOne.length === 0) {
        emailTemplatesWithAtLeastOne.push({
          id: uuidv4(),
          name: "",
          order: 1,
          templates: [],
          isDisabled: false,
          created: moment(),
          updated: moment(),
        });
      }

      newState.globalForm = globalForm;
      newState.emailsState.emailTemplates = localUtils.sortEmailTemplatesByOrder(emailTemplatesWithAtLeastOne, "asc");
      newState.emailsState.isInitiallyLoaded = true;
      newState.emailsState.isLoading = false;
      newState.emailsState.isDeleteModalOpen = false;
      newState.emailsState.isEditModalOpen = false;
      newState.emailsState.deletingEmailTemplate = undefined;
      newState.emailsState.editingEmailTemplate = createNewEditingEmailTemplate();
      return newState;
    }
    case ActionType.LoadGlobal: {
      const { globalForm } = action.payload;
      newState.globalForm = globalForm;
      newState.emailsState.shouldSave = false;
      return newState;
    }
    case ActionType.SaveEmailTemplates: {
      const newEmailTemplates = [...action.payload];
      newState.emailsState.shouldSave = true;

      if (newEmailTemplates.some((e) => e.order <= 0)) {
        let largestOrder = listUtils.max(newEmailTemplates, "order", 0);

        for (let emailTemplate of newEmailTemplates) {
          if (emailTemplate.order <= 0) {
            emailTemplate.order = ++largestOrder;
          }
        }
      }

      let copyOfEmailTemplates = objectUtils.deepCopyObject(newEmailTemplates);
      copyOfEmailTemplates = copyOfEmailTemplates.sort((a, b) => a.order - b.order);

      const idToNewOrder: Record<string, number> = {};
      // Update the order of templates starting from 1
      for (let i = 0; i < copyOfEmailTemplates.length; i++) {
        copyOfEmailTemplates[i].order = i + 1;
        idToNewOrder[copyOfEmailTemplates[i].id] = copyOfEmailTemplates[i].order;
      }

      for (var emailTemplate of newEmailTemplates) {
        const newOrder = idToNewOrder[emailTemplate.id];

        if (emailTemplate.order !== newOrder) {
          emailTemplate.order = newOrder;
        }
      }

      newState.emailsState.emailTemplates = newEmailTemplates;
      newState.emailsState.isDeleteModalOpen = false;
      newState.emailsState.isEditModalOpen = false;
      newState.emailsState.deletingEmailTemplate = undefined;
      return newState;
    }
    case ActionType.SaveEmailTemplate: {
      const newEmailTemplate = { ...action.payload };
      let emailTemplatesToUpdate = [...newState.emailsState.emailTemplates];

      newState.emailsState.shouldSave = true;

      // Updating the "order" property if it isn't set properly
      if (newEmailTemplate.order <= 0) {
        newEmailTemplate.order = listUtils.max(newState.emailsState.emailTemplates, "order", 1);
      }

      const existingEmailTemplateIndex = state.emailsState.emailTemplates.findIndex(
        (emailTemplate) => emailTemplate.id === newEmailTemplate.id
      );

      // Updating or adding email templates
      if (existingEmailTemplateIndex !== -1) {
        emailTemplatesToUpdate[existingEmailTemplateIndex] = newEmailTemplate;
      } else {
        emailTemplatesToUpdate.push(newEmailTemplate);
      }

      newState.emailsState.isEditModalOpen = false;
      newState.emailsState.emailTemplates = emailTemplatesToUpdate;
      return newState;
    }
    case ActionType.UpdateEmailTemplate: {
      const idToUpdate = action.payload.id;
      const partialEmailTemplate = action.payload.emailTemplate;

      if (state.emailsState.isEditModalOpen && state.emailsState.editingEmailTemplate?.id === idToUpdate) {
        const updatedEditedEmailTemplate = {
          ...newState.emailsState.editingEmailTemplate,
          ...partialEmailTemplate,
        };

        newState.emailsState.editingEmailTemplate = updatedEditedEmailTemplate;
        return newState;
      }

      const updatedEmailTemplates = state.emailsState.emailTemplates.map((emailTemplate) => {
        if (emailTemplate.id === idToUpdate) {
          const updatedEmailTemplate = {
            ...emailTemplate,
            ...partialEmailTemplate,
          };

          return updatedEmailTemplate;
        }
        return emailTemplate;
      });

      newState.emailsState.emailTemplates = updatedEmailTemplates;
      return newState;
    }
    case ActionType.ToggleDeleteModal: {
      return {
        ...state,
        emailsState: {
          ...state.emailsState,
          isDeleteModalOpen: action.payload.isOpen,
          deletingEmailTemplate: action.payload.emailTemplate,
        },
      };
    }
    case ActionType.ToggleEditModal: {
      const { isOpen, editingEmailTemplate } = action.payload;

      newState.emailsState.isEditModalOpen = isOpen;

      if (editingEmailTemplate !== undefined) {
        newState.emailsState.editingEmailTemplate = editingEmailTemplate;
      }

      return newState;
    }
    default:
      return newState;
  }
};

const createNewEditingEmailTemplate = (): EmailTemplateForm => {
  return {
    id: "",
    name: "",
    order: -1,
    templates: [
      {
        id: "",
        version: 1,
        fields: [],
        subject: "",
        template: "",
        defaultValue: "",
        isDisabled: false,
        created: moment(),
        updated: moment(),
      },
    ],
    isDisabled: false,
    created: moment(),
    updated: moment(),
  };
};

const defaultGlobalEmailsState: GlobalEmailsState = {
  globalForm: {
    companies: {
      list: [],
    },
    people: {
      list: [],
    },
    emails: {
      list: [],
    },
    ideas: {
      list: [],
    },
  },
  emailsState: {
    emailTemplates: [],
    isInitiallyLoaded: false,
    shouldSave: false,
    isLoading: true,
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    deletingEmailTemplate: undefined,
    editingEmailTemplate: { ...createNewEditingEmailTemplate() },
  },
};

interface EmailsActions {
  load: () => void;
  saveEmailTemplates: (emailTemplates: EmailTemplateForm[]) => void;
  saveEmailTemplate: (emailTemplate: EmailTemplateForm) => void;
  updateEmailTemplate: (id: string | undefined, emailTemplate: EmailTemplateForm) => void;
  toggleDeleteModal: (isOpen: boolean, emailTemplate?: EmailTemplateForm) => void;
  toggleEditModal: (isOpen: boolean, editingEmailTemplate?: EmailTemplateForm) => void;
}

export const useEmailsStore = (): [EmailsState, EmailsActions] => {
  const [globalState, dispatch] = useReducer(reducer, defaultGlobalEmailsState);

  useEffect(() => {
    if (globalState.emailsState.isInitiallyLoaded && globalState.emailsState.shouldSave) {
      const newGlobalForm = localStorageUtil.setEmails(globalState.globalForm, globalState.emailsState.emailTemplates);

      dispatch({
        type: ActionType.LoadGlobal,
        payload: { globalForm: newGlobalForm },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.emailsState.editingEmailTemplate,
    globalState.emailsState.shouldSave,
    globalState.emailsState.isInitiallyLoaded,
  ]);

  const load = () => {
    const globalForm = localStorageUtil.get();

    const emailTemplates = globalForm.emails.list;

    dispatch({
      type: ActionType.Load,
      payload: {
        globalForm: globalForm,
        emailTemplates: emailTemplates,
      },
    });
  };

  const saveEmailTemplates = (emailTemplates: EmailTemplateForm[]) => {
    dispatch({ type: ActionType.SaveEmailTemplates, payload: emailTemplates });
  };

  const saveEmailTemplate = (emailTemplate: EmailTemplateForm) => {
    dispatch({
      type: ActionType.SaveEmailTemplate,
      payload: emailTemplate,
    });
  };

  const updateEmailTemplate = (id: string | undefined, emailTemplate: EmailTemplateForm) => {
    if (!id) {
      return;
    }

    dispatch({
      type: ActionType.UpdateEmailTemplate,
      payload: { id, emailTemplate },
    });
  };

  const toggleDeleteModal = (isOpen: boolean, emailTemplate?: EmailTemplateForm) => {
    dispatch({
      type: ActionType.ToggleDeleteModal,
      payload: { isOpen, emailTemplate },
    });
  };

  const toggleEditModal = (isOpen: boolean, editingEmailTemplate?: EmailTemplateForm) => {
    dispatch({
      type: ActionType.ToggleEditModal,
      payload: {
        isOpen,
        editingEmailTemplate: editingEmailTemplate,
      },
    });
  };

  return [
    globalState.emailsState,
    {
      load,
      saveEmailTemplates,
      saveEmailTemplate,
      updateEmailTemplate,
      toggleDeleteModal,
      toggleEditModal,
    },
  ];
};
