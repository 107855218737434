import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import { usePaddlePlayersStore } from "./store/usePaddlePlayersStore";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { PaddlePlayersBase, PaddlePlayersPlayer } from "api/paddle/viewTeamsModels";

const PaddlePlayersPage = () => {
  const [state, actions] = usePaddlePlayersStore();

  useEffectOnLoad(() => actions.load());

  const { players } = state.paddlePlayers;

  const onTableChangeAll = (updatedRecords: PaddlePlayersPlayer[]) => {
    const newPaddlePlayers = PaddlePlayersBase.create(state.paddlePlayers);
    newPaddlePlayers.players = updatedRecords;
    actions.save(newPaddlePlayers);
  };

  const table: AdvancedTableRecords<PaddlePlayersPlayer> = {
    fields: [
      {
        id: "playerFullName",
        label: "Player",
        readOnly: true,
        width: "30%",
        isSticky: true,
      },
      {
        id: "teamName",
        label: "Team",
        readOnly: true,
        width: "25%",
      },
      {
        id: "matchesPlayed",
        label: "Matches Played",
        readOnly: true,
        width: "15%",
      },
      {
        id: "matchPoints",
        label: "Match Points",
        readOnly: true,
        width: "15%",
      },
      {
        id: "pointsPerMatch",
        label: "Points Ratio",
        readOnly: true,
        width: "15%",
      },
    ],
    data: [...players],
  };

  return (
    <AdvancedTable
      title="Players"
      table={table}
      orderByKey="playerFullName"
      onChange={() => {}}
      onChangeAll={onTableChangeAll}
      onDelete={() => {}}
      itemsPerPage={100}
      isSearchMatch={(item, query) => {
        return (
          item.playerFullName.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
          item.teamName.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        );
      }}
      minWidthPx={0}
    />
  );
};

export default PaddlePlayersPage;
