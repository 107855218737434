import { useEffect } from "react";
export function useEffectOnLoad(
  callback: () => void,
  onUnload?: () => void,
  dependencies?: React.DependencyList | undefined
) {
  const deps = dependencies ?? [];

  const callbackWithOnLoad = () => {
    callback();

    return onUnload;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callbackWithOnLoad, deps);
}
