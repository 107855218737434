import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { isMoment } from "moment";
import moment from "moment";

interface DropdownProps<T> {
  label: string;
  keyField: keyof T;
  labelField: keyof T;
  values: T[];
  selectedValue?: T;
  firstOptionLabel?: string | undefined;
  onChange: (selection: T | undefined) => void;
}

const Dropdown = <T,>(props: DropdownProps<T>) => {
  const { label, keyField, labelField, values, selectedValue, firstOptionLabel, onChange } = props;
  const [state, setState] = useState<string>(selectedValue ? (selectedValue[keyField] as string) : "");

  useEffect(() => {
    if (selectedValue && state !== undefined && state !== selectedValue[keyField]) {
      setState(selectedValue ? (selectedValue[keyField] as string) : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setState(newValue);

    const matchingValue = values.find((v) => {
      const field = v[keyField];

      if (isMoment(field)) {
        return moment(field).isSame(moment(newValue));
      }

      return field === newValue;
    });
    onChange(matchingValue);
  };

  const createMenuItems = () => {
    return values.map((v) => {
      const key = String(v[keyField]);
      return (
        <MenuItem key={key} value={key} selected={selectedValue && selectedValue[keyField] === key}>
          {String(v[labelField])}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl sx={{ mb: 2, minWidth: 250, width: "100%" }}>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={state}
        label={label}
        onChange={handleChange}
      >
        {firstOptionLabel ? (
          <MenuItem value="">
            <em>{firstOptionLabel}</em>
          </MenuItem>
        ) : null}
        {createMenuItems()}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
