// src/components/EmailValidator.tsx

import React, { useState, useEffect } from "react";
import { TextField, Button, Paper, Box, IconButton, Typography, Container } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useCopyToClipboard } from "usehooks-ts";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Moment } from "moment";

interface Email {
  id: string;
  email: string;
  isValid: boolean;
  date: Moment;
}

const EmailValidator: React.FC = () => {
  const [apiKey, setApiKey] = useState<string>("");
  const [submittedApiKey, setSubmittedApiKey] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emails, setEmails] = useState<Email[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showApiKey, setShowApiKey] = useState<boolean>(false);

  const [, copyToClipboard] = useCopyToClipboard();

  const handleApiKeySubmit = () => {
    localStorage.setItem("apiKey", apiKey || "");
    setSubmittedApiKey(apiKey);
    setShowApiKey(false); // Hide the API key input/submit box after submitting
  };

  const handleEmailAdd = () => {
    setLoading(true);

    const url = `https://api.quickemailverification.com/v1/verify?email=${email}&apikey=${submittedApiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const isSuccess = data?.success === "true";
        const isSafeToSend = data?.safe_to_send === "true";

        if (isSuccess) {
          const newEmail: Email = {
            id: uuid(),
            email: email,
            isValid: isSafeToSend,
            date: moment(),
          };

          const updatedEmails = [...emails, newEmail].sort((a, b) => {
            const dateA = a ? moment(a.date ?? "1970-01-01") : moment("1970-01-01");
            const dateB = b ? moment(b.date ?? "1970-01-01") : moment("1970-01-01");

            return dateB.diff(dateA);
          });
          setEmails(updatedEmails);
          localStorage.setItem("emails", JSON.stringify(updatedEmails));
          setEmail("");
        } else {
          console.error(JSON.stringify(data, null, 2));
        }
      })
      .catch((error) => {
        console.error("API request failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEmailDelete = (id: string) => {
    const updatedEmails = emails.filter((email) => email.id !== id);
    setEmails(updatedEmails);
    localStorage.setItem("emails", JSON.stringify(updatedEmails));
  };

  useEffect(() => {
    const localStorageApiKey = localStorage.getItem("apiKey");

    if (localStorageApiKey) {
      setSubmittedApiKey(localStorageApiKey);
    }

    const storedEmails = JSON.parse(localStorage.getItem("emails") || "[]");
    setEmails(storedEmails);
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Email Validator
      </Typography>
      {submittedApiKey && !showApiKey ? (
        // Existing code for showing email input, add button, and email list
        <Box>
          <TextField
            type="text"
            label="Enter an email address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "10px", width: "100%" }}
          />
          <Box style={{ display: "flex", gap: "10px" }}>
            <Button onClick={handleEmailAdd} disabled={loading} variant="contained" style={{ width: "10em" }}>
              {loading ? "Adding..." : "Add"}
            </Button>
            <Button onClick={() => setShowApiKey(true)} variant="outlined" style={{ width: "13em" }}>
              Update API Key
            </Button>
          </Box>
          <Paper elevation={3} style={{ padding: "10px", marginTop: "20px" }}>
            {emails.map((email) => (
              <Box
                key={email.id}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                  marginBottom: "10px",
                  backgroundColor: email.isValid ? "#d9ffd9" : "#ffd9d9", // Light green for valid, light red for invalid
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>{email.email}</Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: "2em", fontSize: "0.8em", fontStyle: "italic" }}>
                    {moment(email.date ?? "1970-01-01").format("MM/DD/YYYY h:mm A")}
                  </Box>

                  <IconButton
                    onClick={() => {
                      copyToClipboard(email.email);
                    }}
                    color="default"
                    style={{ marginLeft: "auto" }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEmailDelete(email.id)} color="error" style={{ marginLeft: "auto" }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Paper>
        </Box>
      ) : (
        // Existing code for showing API key input and submit box, or Update API Key button
        <Box>
          <TextField
            type="password"
            label="Enter your API key"
            variant="outlined"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            style={{ marginBottom: "10px", width: "100%" }}
          />
          <Box style={{ width: "100%" }}>
            <Button onClick={handleApiKeySubmit} variant="contained" style={{ width: "100%" }}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default EmailValidator;
