import { AdvancedTable, AdvancedTableRecords, HeadCell } from "components/AdvancedTable";
import { usePaddleMatchesStore } from "./store/usePaddleMatchesStore";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import {
  AllMatchesBase,
  SingleDayMatchTeam,
  SingleDayMatches,
  SingleDayMatchesOverallResult,
} from "api/paddle/viewAllMatchesModels";
import { Box, Button, Divider, Grid } from "@mui/material";
import Dropdown from "components/Dropdown";
import CustomIcon from "components/CustomIcon";
import { useState } from "react";
import CreatePaddleMatchModal from "pages/PaddlePlayers/CreatePaddleMatchModal";
import { useConfig } from "components/Config";
import AddIcon from "@mui/icons-material/Add";

const PaddleMatchesPage = () => {
  const [state, actions] = usePaddleMatchesStore();
  const { config } = useConfig();
  const [isOpen, setIsOpen] = useState(false);

  useEffectOnLoad(() => actions.load());

  const { allMatches, selectedMatchDate } = state;
  const { matches, availableMatchDates, availableTeams, availablePlayers } = allMatches;

  const selectedMatch = matches.find((m) => selectedMatchDate && m.matchDate.isSame(selectedMatchDate.date));

  if (!selectedMatch) {
    return (
      <Dropdown
        keyField="date"
        labelField="displayName"
        label="Match Dates"
        selectedValue={selectedMatchDate}
        values={availableMatchDates}
        onChange={(newValue) => console.log(newValue)}
      />
    );
  }

  const onOverallResultTableChangeAll = (
    selectedMatch: SingleDayMatches,
    updatedRecords: SingleDayMatchesOverallResult[]
  ) => {
    const newState = AllMatchesBase.create(state.allMatches);
    const matchingMatchIndex = newState.matches.findIndex((m) => m.id === selectedMatch.id);
    newState.matches[matchingMatchIndex].overallResults = updatedRecords;
    actions.save(newState);
  };

  const overallResultsTable: AdvancedTableRecords<SingleDayMatchesOverallResult> = {
    fields: [
      {
        id: "matchupName",
        label: "Matchup",
        readOnly: true,
        width: "24%",
      },
      {
        id: "teamName",
        label: "Name",
        readOnly: true,
        width: "19%",
      },
      {
        id: "totalMatches",
        label: "Matches",
        readOnly: true,
        width: "19%",
      },
      {
        id: "points",
        label: "Points",
        readOnly: true,
        width: "19%",
      },
      {
        id: "totalGames",
        label: "Games",
        readOnly: true,
        width: "19%",
      },
    ],
    data: [...selectedMatch.overallResults],
  };

  const matchTableFields: HeadCell<SingleDayMatchTeam>[] = [
    {
      id: "teamName",
      label: "Team",
      readOnly: true,
      width: "18%",
    },
    {
      id: "players",
      label: "Players",
      readOnly: true,
      width: "30%",
      custom: (match) => {
        if (match.isOverallWinner) {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              {match.players}
              <CustomIcon icon="star" size="xs" style={{ paddingLeft: "0.5em", color: "green" }} />
            </Box>
          );
        }

        return match.players;
      },
    },
    {
      id: "setOne",
      label: "Set 1",
      readOnly: true,
      width: "12%",
      custom: (match) => {
        if (match.isSetOneWinner) {
          return <span style={{ color: "green", fontWeight: "bold" }}>*{match.setOne}*</span>;
        }
        return match.setOne;
      },
    },
    {
      id: "setTwo",
      label: "Set 2",
      readOnly: true,
      width: "12%",
      custom: (match) => {
        if (match.isSetTwoWinner) {
          return <span style={{ color: "green", fontWeight: "bold" }}>*{match.setTwo}*</span>;
        }
        return match.setTwo;
      },
    },
    {
      id: "setThree",
      label: "Set 3",
      readOnly: true,
      width: "12%",
      custom: (match) => {
        if (match.isSetThreeWinner) {
          return <span style={{ color: "green", fontWeight: "bold" }}>*{match.setThree}*</span>;
        }
        return match.setThree;
      },
    },
    {
      id: "points",
      label: "Points",
      custom: (match) => {
        if (match.isOverallWinner) {
          return <span style={{ color: "green", fontWeight: "bold" }}>*{match.points}*</span>;
        }

        return match.points;
      },
      readOnly: true,
      width: "12%",
    },
  ];

  const { matchOne, matchTwo, matchThree, matchFour, matchFive, matchSix, matchSeven, matchEight } = selectedMatch;

  const flattenedMatches = [
    matchOne,
    matchTwo,
    matchThree,
    matchFour,
    matchFive,
    matchSix,
    matchSeven,
    matchEight,
  ].filter((m) => m !== undefined);

  const displayAllMatches = () => {
    const matchAdvancedTables = flattenedMatches.map((match, index) => {
      if (!match) {
        throw new Error("Match should not be undefined");
      }

      const { teamOne, teamTwo } = match;
      const data = teamOne.points > teamTwo.points ? [match.teamOne, match.teamTwo] : [match.teamTwo, match.teamOne];
      const matchTable: AdvancedTableRecords<SingleDayMatchTeam> = {
        fields: [...matchTableFields],
        data: data,
      };

      const matchNumber = `Match ${index + 1}`;

      return (
        <AdvancedTable
          key={matchNumber}
          title={matchNumber}
          table={matchTable}
          orderByKey="teamName"
          onChange={() => {}}
          onChangeAll={() => {}}
          onDelete={() => {}}
          hidePagination
          minWidthPx={0}
        />
      );
    });

    return matchAdvancedTables;
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8} md={5} lg={4} style={{ justifyContent: "center" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"}>
            <Dropdown
              keyField="date"
              labelField="displayName"
              label="Match Dates"
              selectedValue={selectedMatchDate}
              values={availableMatchDates}
              onChange={(newValue) => actions.selectMatchDate(newValue)}
            />
            {config.featureFlags.createMatch ? (
              <Box>
                <Button
                  sx={{ ml: "1em", mb: "1em" }}
                  onClick={() => setIsOpen(true)}
                  endIcon={<AddIcon />}
                  color="primary"
                  variant="contained"
                >
                  {"Add"}
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={10} lg={7} style={{ justifyContent: "center" }}>
          <AdvancedTable
            title={`Overall Results (${selectedMatch.matchDate.format("MMMM Do")})`}
            table={overallResultsTable}
            orderByKey="matchupName"
            onChange={() => {}}
            onChangeAll={(data) => onOverallResultTableChangeAll(selectedMatch, data)}
            onDelete={() => {}}
            hidePagination
            minWidthPx={0}
          />
        </Grid>
      </Grid>
      <Box sx={{ py: "2em" }} alignItems={"center"}>
        <Divider />
      </Box>
      {displayAllMatches()}
      <CreatePaddleMatchModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        availableTeams={availableTeams}
        availablePlayers={availablePlayers}
      />
    </>
  );
};

export default PaddleMatchesPage;
