import moment from "moment";
import type { Moment } from "moment";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { typedJsonUtils } from "utils/typedJsonUtils";
import objectUtils from "utils/objectUtil";
import { IdAble } from "api/models";

const momentJsonOptions = typedJsonUtils.momentOptions("YYYY-MM-DDTHH:mm:ssZ");

// Interfaces

export interface AvailableMatchDate {
  date: Moment;
  displayName: string;
}

export interface AvailableTeam {
  teamId: string;
  displayName: string;
}

export interface AvailablePlayer {
  playerId: string;
  teamId: string;
  displayName: string;
  teamDisplayName: string;
}

export interface SingleDayMatchesOverallResult extends IdAble {
  id: string;
  teamId: string;
  teamName: string;
  opponentTeamId: string | undefined;
  matchupName: string;
  totalMatches: number;
  totalGames: number;
  points: number;
}

export interface SingleDayMatchTeam extends IdAble {
  id: string;
  teamId: string;
  teamName: string;
  playerOneId: string;
  playerTwoId: string;
  players: string;
  setOne: number;
  isSetOneWinner: boolean;
  setTwo: number;
  isSetTwoWinner: boolean;
  setThree?: number | undefined;
  isSetThreeWinner: boolean;
  points: number;
  isOverallWinner: boolean;
}

export interface SingleDayMatch {
  teamOne: SingleDayMatchTeam;
  teamTwo: SingleDayMatchTeam;
}

export interface SingleDayMatches {
  id: string;
  matchDate: Moment;
  overallResults: SingleDayMatchesOverallResult[];
  matchOne: SingleDayMatch;
  matchTwo?: SingleDayMatch | undefined;
  matchThree?: SingleDayMatch | undefined;
  matchFour?: SingleDayMatch | undefined;
  matchFive?: SingleDayMatch | undefined;
  matchSix?: SingleDayMatch | undefined;
  matchSeven?: SingleDayMatch | undefined;
  matchEight?: SingleDayMatch | undefined;
  matchNine?: SingleDayMatch | undefined;
  matchTen?: SingleDayMatch | undefined;
}

export interface AllMatches {
  availableMatchDates: AvailableMatchDateBase[];
  availableTeams: AvailableTeamBase[];
  availablePlayers: AvailablePlayerBase[];
  matches: SingleDayMatchesBase[];
}

// Classes

@jsonObject
export class AvailableMatchDateBase implements AvailableMatchDate {
  @jsonMember(moment, momentJsonOptions)
  public date: Moment = moment();

  @jsonMember(String)
  public displayName: string = "";

  static create(newObject: AvailableMatchDate): AvailableMatchDateBase {
    return objectUtils.deepCopyTypedObject(AvailableMatchDateBase, newObject);
  }
}

@jsonObject
export class AvailableTeamBase implements AvailableTeam {
  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public displayName: string = "";

  static create(newObject: AvailableTeam): AvailableTeamBase {
    return objectUtils.deepCopyTypedObject(AvailableTeamBase, newObject);
  }
}

@jsonObject
export class AvailablePlayerBase implements AvailablePlayer {
  @jsonMember(String)
  public playerId: string = "";

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public displayName: string = "";

  @jsonMember(String)
  public teamDisplayName: string = "";

  static create(newObject: AvailablePlayer): AvailablePlayerBase {
    return objectUtils.deepCopyTypedObject(AvailablePlayerBase, newObject);
  }
}

@jsonObject
export class SingleDayMatchesOverallResultBase implements SingleDayMatchesOverallResult {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public teamName: string = "";

  @jsonMember(String)
  public opponentTeamId: string = "";

  @jsonMember(String)
  public matchupName: string = "";

  @jsonMember(Number)
  public totalMatches: number = 0;

  @jsonMember(Number)
  public totalGames: number = 0;

  @jsonMember(Number)
  public points: number = 0;

  static create(newObject: SingleDayMatchesOverallResult): SingleDayMatchesOverallResultBase {
    return objectUtils.deepCopyTypedObject(SingleDayMatchesOverallResultBase, newObject);
  }
}

@jsonObject
export class SingleDayMatchTeamBase implements SingleDayMatchTeam {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public teamName: string = "";

  @jsonMember(String)
  public playerOneId: string = "";

  @jsonMember(String)
  public playerTwoId: string = "";

  @jsonMember(String)
  public players: string = "";

  @jsonMember(Number)
  public setOne: number = 0;

  @jsonMember(Number)
  public setTwo: number = 0;

  @jsonMember(Number)
  public setThree?: number | undefined;

  @jsonMember(Number)
  public points: number = 0;

  @jsonMember(Boolean)
  public isSetOneWinner: boolean = false;

  @jsonMember(Boolean)
  public isSetTwoWinner: boolean = false;

  @jsonMember(Boolean)
  public isSetThreeWinner: boolean = false;

  @jsonMember(Boolean)
  public isOverallWinner: boolean = false;
}

@jsonObject
export class SingleDayMatchBase implements SingleDayMatch {
  @jsonMember(SingleDayMatchTeamBase)
  public teamOne: SingleDayMatchTeam = new SingleDayMatchTeamBase();

  @jsonMember(SingleDayMatchTeamBase)
  public teamTwo: SingleDayMatchTeam = new SingleDayMatchTeamBase();

  static create(newObject: SingleDayMatch): SingleDayMatchBase {
    return objectUtils.deepCopyTypedObject(SingleDayMatchBase, newObject);
  }
}

@jsonObject
export class SingleDayMatchesBase implements SingleDayMatches {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(moment, momentJsonOptions)
  public matchDate: Moment = moment();

  @jsonArrayMember(SingleDayMatchesOverallResultBase)
  public overallResults: SingleDayMatchesOverallResult[] = [];

  @jsonMember(moment, momentJsonOptions)
  public createdDate: Moment = moment();

  @jsonMember(moment, momentJsonOptions)
  public modifiedDate: Moment = moment();

  @jsonMember(SingleDayMatchBase)
  public matchOne: SingleDayMatch = new SingleDayMatchBase();

  @jsonMember(SingleDayMatchBase)
  public matchTwo?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchThree?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchFour?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchFive?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchSix?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchSeven?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchEight?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchNine?: SingleDayMatch | undefined;

  @jsonMember(SingleDayMatchBase)
  public matchTen?: SingleDayMatch | undefined;

  static create(newObject: SingleDayMatches): SingleDayMatchesBase {
    return objectUtils.deepCopyTypedObject(SingleDayMatchesBase, newObject);
  }
}

@jsonObject
export class AllMatchesBase implements AllMatches {
  @jsonArrayMember(AvailableMatchDateBase)
  public availableMatchDates: AvailableMatchDateBase[] = [];

  @jsonArrayMember(AvailableTeamBase)
  public availableTeams: AvailableTeamBase[] = [];

  @jsonArrayMember(AvailablePlayerBase)
  public availablePlayers: AvailablePlayerBase[] = [];

  @jsonArrayMember(SingleDayMatchesBase)
  public matches: SingleDayMatchesBase[] = [];

  static create(newObject: AllMatches): AllMatchesBase {
    return objectUtils.deepCopyTypedObject(AllMatchesBase, newObject);
  }
}
