import { getPaletteBase } from "./palette-base";
import { createTheme } from "@mui/material/styles";

const theme = {
  base: getPaletteBase(),
  white: "#fff",
};

export const themeForProvider = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // Other theme properties
});

export default theme;
