import { type ThisWeek, ThisWeekBase } from "api/paddle/thisWeekModels";
import moment from "moment";
import { useReducer } from "react";
import { jsonMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";
import stringUtils from "utils/stringUtils";

export interface PaddleThisWeekActions {
  load: () => void;
  save: (data: ThisWeek) => void;
}

@jsonObject
export class PaddleThisWeekState {
  @jsonMember(ThisWeekBase)
  thisWeek: ThisWeek = new ThisWeekBase();
}

export enum ActionType {
  Load = "Load",
  Save = "Save",
}

type Action =
  | { type: ActionType.Load; payload: { thisWeek: ThisWeek } }
  | { type: ActionType.Save; payload: { thisWeek: ThisWeek } };

const reducer = (state: PaddleThisWeekState, action: Action): PaddleThisWeekState => {
  const newState = objectUtils.deepCopyTypedObject(PaddleThisWeekState, state);

  switch (action.type) {
    case ActionType.Load: {
      const { thisWeek } = action.payload;
      const newTeamStandings = ThisWeekBase.create(thisWeek);
      newState.thisWeek = newTeamStandings;
      return newState;
    }
    case ActionType.Save: {
      const { thisWeek } = action.payload;
      const newThisWeek = ThisWeekBase.create(thisWeek);
      newState.thisWeek = newThisWeek;
      return newState;
    }
    default:
      return state;
  }
};

export const usePaddleThisWeekStore = (): [PaddleThisWeekState, PaddleThisWeekActions] => {
  const [state, dispatch] = useReducer(reducer, new PaddleThisWeekState());

  const load = () => {
    const thisWeek = ThisWeekBase.create({
      id: stringUtils.uuid(),
      matchWeek: moment("2024-02-26T06:00:00Z"),
      firstMatchupName: "Team Keeley vs Team Thiagarajan (Vinny)",
      firstMatchupTeamOneId: "",
      firstMatchupTeamTwoId: "",
      firstMatchups: [
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-20T01:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-20T02:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-20T03:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-22T01:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-22T02:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-22T03:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
      ],
      secondMatchupName: "Team Bohr vs Team Wilmes",
      secondMatchupTeamOneId: "",
      secondMatchupTeamTwoId: "",
      secondMatchups: [
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-13T00:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-13T01:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-13T02:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-13T03:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
        {
          id: stringUtils.uuid(),
          teamOnePlayersName: "",
          teamTwoPlayersName: "",
          matchTime: moment("2024-02-15T02:00:00Z"),
          court: "-",
          teamOnePlayerOneId: "",
          teamOnePlayerTwoId: "",
          teamTwoPlayerOneId: "",
          teamTwoPlayerTwoId: "",
        },
      ],
    });

    dispatch({
      type: ActionType.Load,
      payload: {
        thisWeek,
      },
    });
  };
  const save = (thisWeek: ThisWeek) => {
    dispatch({
      type: ActionType.Save,
      payload: {
        thisWeek,
      },
    });
  };

  return [
    state,
    {
      load,
      save,
    },
  ];
};
