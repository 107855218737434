import { v4 } from "uuid";

const isNullOrWhitespace = (str: any | null | undefined) => {
  if (str === null || str === undefined) {
    return true;
  }

  if (typeof str === "string") {
    return str.toString().trim() === "";
  }

  return false;
};

const hexToRgb = (hex: string) => {
  // Function to convert Hex to RGB
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
  }
  return "";
};

const rgbToHex = (rgbValue: string) => {
  // Ensure that the input is in the format "rgb(x, x, x)"
  const match = /rgb\((\d+), (\d+), (\d+)\)/.exec(rgbValue);
  if (!match) {
    throw new Error("Invalid RGB format");
  }

  // Convert each RGB component to a hexadecimal value and pad with zeros if needed
  const r = parseInt(match[1], 10).toString(16).padStart(2, "0");
  const g = parseInt(match[2], 10).toString(16).padStart(2, "0");
  const b = parseInt(match[3], 10).toString(16).padStart(2, "0");

  // Combine the hexadecimal values to form the final Hex color code
  return `#${r}${g}${b}`;
};

const uuid = () => v4();

const isNumber = (val: any) => {
  try {
    parseInt(val);
    return true;
  } catch {}

  return false;
};

const stringUtils = {
  isNullOrWhitespace,
  hexToRgb,
  rgbToHex,
  uuid,
  isNumber,
};

export default stringUtils;
