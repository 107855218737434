import { Moment } from "moment";
import { SortOrder } from "./SortOrder";
import moment from "moment";

const sortByString = <T>(a: T, b: T, stringAccessor: (obj: T) => string | undefined, order: SortOrder = "asc") => {
  const aString = stringAccessor(a)?.toLocaleLowerCase();
  const bString = stringAccessor(b)?.toLocaleLowerCase();

  if (aString !== undefined && bString !== undefined) {
    if (aString < bString) return order === "asc" ? -1 : 1;
    if (aString > bString) return order === "asc" ? 1 : -1;
  }

  if (aString === undefined && bString !== undefined) return order === "asc" ? -1 : 1;
  if (bString === undefined && aString !== undefined) return order === "asc" ? 1 : -1;

  return 0;
};

const sortUtils = {
  byDate: <T>(a: T, b: T, dateAccessor: (obj: T) => Moment | undefined, order: SortOrder = "asc") => {
    const aDate = moment(dateAccessor(a));
    const bDate = moment(dateAccessor(b));

    if (aDate !== undefined && bDate !== undefined) {
      if (aDate.isAfter(bDate)) return -1;
      if (aDate.isBefore(bDate)) return 1;
    }

    if (aDate === undefined && bDate !== undefined) return -1;
    if (bDate === undefined && aDate !== undefined) return 1;

    return 0;
  },
  byString: <T>(a: T, b: T, order: SortOrder = "asc", ...stringAccessors: ((obj: T) => string | undefined)[]) => {
    for (var stringAccessor of stringAccessors) {
      const orderNum = sortByString(a, b, stringAccessor, order);

      if (orderNum !== 0) {
        return orderNum;
      }
    }

    return 0;
  },
};

export default sortUtils;
