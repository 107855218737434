import React, { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Typography,
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const AspectRatio: React.FC = () => {
  const [originalWidth, setOriginalWidth] = useState<number | string>("");
  const [originalHeight, setOriginalHeight] = useState<number | string>("");
  const [newWidth, setNewWidth] = useState<number | string>("");
  const [newHeight, setNewHeight] = useState<number | string>("");
  const [editingField, setEditingField] = useState<"width" | "height" | undefined>(undefined);

  const hasOriginalWidthAndHeightValues = Number(originalWidth) > 0 && Number(originalHeight) > 0;
  const hasNewWidthAndHeightValues = Number(newWidth) > 0 && Number(newHeight) > 0;

  useEffect(() => {
    const hasNoNewWidthOrHeight = (newWidth == null || newWidth === "") && (newHeight == null || newHeight === "");
    const isMissingOneNewValue = newWidth == null || newWidth === "" || newHeight == null || newHeight === "";

    if (hasNoNewWidthOrHeight) {
      console.log("detected no editing field and reset width/height");

      setEditingField(undefined);
      setNewWidth("");
      setNewHeight("");
    } else if (hasOriginalWidthAndHeightValues && Number(newWidth) > 0 && editingField !== "height") {
      console.log("detected width change");

      setEditingField("width");

      const newHeight = Number(newWidth) / ((originalWidth as number) / (originalHeight as number));
      setNewHeight(newHeight);
    } else if (hasOriginalWidthAndHeightValues && Number(newHeight) > 0 && editingField !== "width") {
      console.log("detected height change");
      setEditingField("height");

      const newWidth = Number(newHeight) * ((originalWidth as number) / (originalHeight as number));
      setNewWidth(newWidth);
    } else if (hasOriginalWidthAndHeightValues && editingField !== undefined && isMissingOneNewValue) {
      setNewWidth("");
      setNewHeight("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalWidth, originalHeight, newWidth, newHeight]);

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const width = Number(event.target.value ?? 0);
    setOriginalWidth(width > 0 ? width : "");
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const height = Number(event.target.value ?? 0);
    setOriginalHeight(height > 0 ? height : "");
  };

  const handleNewWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const width = Number(event.target.value ?? 0);
    setNewWidth(width > 0 ? width : "");
  };

  const handleNewHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const height = Number(event.target.value ?? 0);
    setNewHeight(height > 0 ? height : "");
  };

  const handleFieldFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const commonAspectRatios = [
    { name: "16:9", width: 16, height: 9 },
    { name: "4:3", width: 4, height: 3 },
    // Add more aspect ratios as needed
  ];

  return (
    <Container>
      <Box mt={3}>
        <Typography variant="h4" gutterBottom>
          Aspect Ratio Calculator
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Original Width"
            type="number"
            value={originalWidth}
            onChange={handleWidthChange}
            onFocus={handleFieldFocus}
            autoComplete="off"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Original Height"
            type="number"
            value={originalHeight}
            onChange={handleHeightChange}
            onFocus={handleFieldFocus}
            autoComplete="off"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="New Width"
            type="number"
            value={newWidth}
            onChange={handleNewWidthChange}
            onFocus={handleFieldFocus}
            disabled={!hasOriginalWidthAndHeightValues || editingField === "height"}
            autoComplete="off"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="New Height"
            type="number"
            value={newHeight}
            onChange={handleNewHeightChange}
            onFocus={handleFieldFocus}
            disabled={!hasOriginalWidthAndHeightValues || editingField === "width"}
            autoComplete="off"
            fullWidth
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        {hasNewWidthAndHeightValues && (
          <Typography variant="body1">
            New Width: {Number(newWidth).toFixed(0)}, New Height: {Number(newHeight).toFixed(0)}
          </Typography>
        )}
      </Box>
      <Box mt={3}>
        <Typography variant="h5" gutterBottom>
          Common Aspect Ratios
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Aspect Ratio</TableCell>
                <TableCell>Width</TableCell>
                <TableCell>Height</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commonAspectRatios.map((ratio) => (
                <TableRow key={ratio.name}>
                  <TableCell>{ratio.name}</TableCell>
                  <TableCell>{ratio.width}</TableCell>
                  <TableCell>{ratio.height}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default AspectRatio;
