// Companies.tsx
import React, { useEffect, useMemo, useState } from "react";
import { useCompaniesStore } from "./store/useCompaniesStore";
import Spinner from "components/Spinner";
import { CompanyForm } from "api/models";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import moment from "moment";
import stringUtils from "utils/stringUtils";
import ConfirmationDialog from "components/ConfirmationDialog";

const Companies: React.FC = () => {
  const [state, actions] = useCompaniesStore();
  const [count, setCount] = useState(0);
  const companies = state.companies || [];
  const { editingPerson, editingCompany } = state;

  useEffectOnLoad(() => actions.load());

  const focusNameInput = (index: number) => {
    const nameInput = document.getElementById(`company-name-input-${index}`);
    if (nameInput) {
      nameInput.focus();
    }
  };

  const focusEmployeeNameInput = () => {
    const nameInput = document.getElementById(`employee-name`);

    if (nameInput) {
      nameInput.focus();
    }
  };

  const focusLastCompanyNameMemo = useMemo(
    () => () => {
      if (state.companies.length > 0) {
        const lastIndex = state.companies.length - 1;
        focusNameInput(lastIndex);
      }
    },
    [state]
  );

  useEffect(() => {
    if (count !== 0 && count < state.companies.length) {
      focusLastCompanyNameMemo();
    }

    setCount(state.companies.length);
  }, [state, count, focusLastCompanyNameMemo]);

  useEffect(() => {
    if (state.isAddEmployeeModalOpen) {
      setTimeout(() => {
        focusEmployeeNameInput();
      }, 100);
    }
  }, [state.isAddEmployeeModalOpen]);

  if (state.isLoading) {
    return <Spinner isLoading={state.isLoading} />;
  }
  const onTableChange = (updatedRecord: CompanyForm) => {
    actions.saveCompany(updatedRecord);
  };

  const onTableChangeAll = (updatedRecords: CompanyForm[]) => {
    actions.saveCompanies(updatedRecords);
  };

  const onDeleteConfirmation = () => {
    const deletingCompany = state.deletingCompany;

    if (deletingCompany) {
      const newCompanies = companies.filter((c) => c.id !== deletingCompany.id);
      actions.saveCompanies(newCompanies);
    }

    actions.toggleDeleteModal(false);
  };

  const table: AdvancedTableRecords<CompanyForm> = {
    fields: [
      {
        id: "name",
        label: "Name",
        customIcon: (companyForm: CompanyForm) => {
          return {
            icon: "personAdd",
            tooltip: `Add person to ${companyForm.name}`,
            onClick: () => {
              actions.toggleAddEmployeeModal({
                isOpen: true,
                editingCompany: companyForm,
                editingPerson: {
                  id: stringUtils.uuid(),
                  companyId: "",
                  name: "",
                  linkedinUrl: "",
                  emailAddress: "",
                  jobTitle: "",
                  phone: "",
                  twitterHandle: "",
                  notes: [],
                  emails: [],
                  created: moment(),
                  updated: moment(),
                },
              });
            },
          };
        },
      },
      {
        id: "employeeCount",
        label: "Emps",
        readOnly: true,
      },
      {
        id: "linkedinUrl",
        label: "LinkedIn",
        websiteOptions: {
          customUrl: (companyForm: CompanyForm) => {
            if (companyForm.linkedinUrl.trim() === "") {
              return {
                url: `https://www.linkedin.com/search/results/companies/?keywords=${companyForm.name}`,
                icon: "search",
                isDisabled: companyForm.name.trim() === "",
              };
            }
            return {
              url: companyForm.linkedinUrl,
              icon: "openInNew",
              isDisabled: companyForm.linkedinUrl.trim() === "",
            };
          },
        },
      },
      { id: "linkedinUrlShorthand", label: "in", readOnly: true },
      {
        id: "website",
        label: "Website",
        websiteOptions: {
          customUrl: (companyForm: CompanyForm) => {
            if (companyForm.website.trim() === "") {
              return {
                url: `https://www.google.com/search?q=${companyForm.name}+company`,
                icon: "search",
                isDisabled: companyForm.name.trim() === "",
              };
            }
            return {
              url: companyForm.website,
              icon: "openInNew",
            };
          },
        },
      },
    ],
    data: [...companies],
    actions: [
      {
        icon: "delete",
        onClick: (form: CompanyForm) => actions.toggleDeleteModal(true, form),
        tooltip: "Delete Company?",
        tooltipFollowMouse: true,
      },
    ],
  };

  return (
    <>
      <AdvancedTable
        title="Companies"
        table={table}
        orderByKey="name"
        createDefaultRecord={() => {
          const defaultCompanyForm: CompanyForm = {
            id: stringUtils.uuid(),
            name: "",
            linkedinUrl: "",
            linkedinUrlShorthand: "",
            website: "",
            industry: "",
            location: "",
            employees: [],
            employeeCount: 0,
          };

          return defaultCompanyForm;
        }}
        onChange={onTableChange}
        onChangeAll={onTableChangeAll}
        onDelete={(recordsToDelete) => {
          const newCompanies = companies.filter((p) => !recordsToDelete.some((r) => r.id === p.id));

          const newState = { ...state };
          newState.companies = newCompanies;
          actions.saveCompanies(newState.companies);
        }}
        isSearchMatch={(item, query) => {
          return item.name.toLowerCase().includes(query);
        }}
      />
      <ConfirmationDialog
        isOpen={state.isDeleteModalOpen}
        onClose={() => actions.toggleDeleteModal(false)}
        onConfirm={onDeleteConfirmation}
        title="Delete Company?"
        message={`Are you sure you want to delete "${
          stringUtils.isNullOrWhitespace(state.deletingCompany?.name) ? "Untitled" : state.deletingCompany?.name
        }"?`}
      />
      <Dialog open={state.isAddEmployeeModalOpen} onClose={() => actions.toggleAddEmployeeModal({ isOpen: false })}>
        <DialogTitle>Add Employee</DialogTitle>
        <DialogContent>
          {editingPerson && editingCompany && (
            <div>
              <TextField
                margin="dense"
                id="businessName"
                label="Selected Business"
                fullWidth
                variant="standard"
                value={editingCompany.name || ""}
                disabled
              />
              <TextField
                margin="dense"
                id="employee-name"
                label="Name"
                fullWidth
                variant="standard"
                value={editingPerson.name || ""}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                id="employee-linkedinUrl"
                label="LinkedIn URL"
                fullWidth
                variant="standard"
                value={editingPerson?.linkedinUrl || ""}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    linkedinUrl: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                id="employee-emailAddress"
                label="Email Address"
                fullWidth
                variant="standard"
                value={editingPerson.emailAddress || ""}
                onChange={(e) =>
                  actions.updatePerson(editingPerson.id, {
                    ...editingPerson,
                    emailAddress: e.target.value,
                  })
                }
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (editingPerson) {
                actions.addPerson(editingPerson?.id, editingPerson, true);
              }
            }}
          >
            Save & New
          </Button>
          <Button
            onClick={() => {
              if (editingPerson) {
                actions.addPerson(editingPerson?.id, editingPerson);
              }
            }}
          >
            Save
          </Button>
          <Button style={{ color: "darkRed" }} onClick={() => actions.toggleAddEmployeeModal({ isOpen: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Companies;
