import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  Box,
  IconButton,
  Typography,
  Container,
  Tooltip,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useCopyToClipboard } from "usehooks-ts";
import moment from "moment";
import { Moment } from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import stringUtils from "utils/stringUtils";

interface ColorPaletteForm {
  id: string;
  hexColors: string[];
  isHidden: boolean;
  created: Moment;
}

const ColorPalette: React.FC = () => {
  const [colorPalettes, setColorPalettes] = useState<ColorPaletteForm[]>([]);
  const [colors, setColors] = useState<string[]>(["", "", "", ""]);
  const [multiInputMode, setMultiInputMode] = useState(true);
  const [hexInputMode, setHexInputMode] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [convertValue, setConvertValue] = useState("");
  const [convertedValue, setConvertedValue] = useState("");
  const [conversionType, setConversionType] = useState("hexToRgb");

  useEffect(() => {
    const storedColorPalettes = JSON.parse(localStorage.getItem("colorPalettes") ?? "[]");
    if (storedColorPalettes) {
      setColorPalettes(storedColorPalettes);
    }
  }, []);

  const createPalette = () => {
    let newPalette: string[] = [];

    if (multiInputMode) {
      newPalette = colors[0].split(",").map((color) => color.trim());
      newPalette = newPalette.filter((color) => color !== "");
    } else {
      newPalette = colors.filter((color) => color !== "");
    }

    newPalette = newPalette.map((c) => c.toLocaleLowerCase());

    for (var i = 0; i < newPalette.length; i++) {
      const color = newPalette[i];

      if (!color.startsWith("#")) {
        const newColor = `#${color}`;
        newPalette[i] = newColor;
      }
    }

    // Fill the remaining slots with white (#fff)
    while (newPalette.length < 4) {
      newPalette.push("#fff");
    }

    const newColorPalette: ColorPaletteForm = {
      id: stringUtils.uuid(),
      hexColors: newPalette, // Store colors as hex values
      isHidden: false,
      created: moment(),
    };

    setColorPalettes([...colorPalettes, newColorPalette]);
    setColors(["", "", "", ""]);
  };

  const copyPalette = (id: string) => {
    const index = colorPalettes.findIndex((palette) => palette.id === id);
    if (index !== -1) {
      const colorsToCopy = hexInputMode
        ? colorPalettes[index].hexColors.join(",")
        : colorPalettes[index].hexColors.map(stringUtils.hexToRgb).join(",");
      copyToClipboard(colorsToCopy);
    }
  };

  const deletePalette = (id: string) => {
    const updatedPalettes = colorPalettes.filter((palette) => palette.id !== id);
    setColorPalettes(updatedPalettes);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newColors = [...colors];
    newColors[index] = event.target.value;
    setColors(newColors);
  };

  const handleToggleInputMode = () => {
    setMultiInputMode(!multiInputMode);
    setColors(["", "", "", ""]);
  };

  const handleToggleHexRgbMode = () => {
    setHexInputMode(!hexInputMode);
  };

  const handleConvertDialogOpen = () => {
    setConvertValue("");
    setConvertedValue("");
    setDialogOpen(true);
  };

  const handleConvertDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConversionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConversionType(event.target.value);
  };

  const handleConvertValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setConvertValue(inputValue);

    // Perform the conversion and update the converted value
    if (conversionType === "hexToRgb") {
      const rgbValue = stringUtils.hexToRgb(inputValue);
      setConvertedValue(rgbValue);
    } else {
      const hexValue = stringUtils.rgbToHex(inputValue);
      setConvertedValue(hexValue);
    }
  };

  useEffect(() => {
    localStorage.setItem("colorPalettes", JSON.stringify(colorPalettes));
  }, [colorPalettes]);

  const toggleMinimizedPalette = (id: string) => {
    setColorPalettes((prevColorPalettes) =>
      prevColorPalettes.map((palette) => (palette.id === id ? { ...palette, isHidden: !palette.isHidden } : palette))
    );
  };

  const sortedColorPalettes = [...colorPalettes].sort((a, b) => (moment(b.created).isBefore(a.created) ? -1 : 1));

  const [copiedValue, copyToClipboard] = useCopyToClipboard();

  const showAll = () => {
    setColorPalettes((prevColorPalettes) => prevColorPalettes.map((palette) => ({ ...palette, isHidden: false })));
  };

  const hideAll = () => {
    setColorPalettes((prevColorPalettes) => prevColorPalettes.map((palette) => ({ ...palette, isHidden: true })));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Color Palette Generator
      </Typography>
      <div>
        <div style={{ display: "flex" }}>
          <label style={{ display: "flex", alignItems: "center", marginBottom: "10px", cursor: "pointer" }}>
            <Switch
              checked={multiInputMode}
              onChange={handleToggleInputMode}
              color="primary"
              inputProps={{ "aria-label": "Toggle Input Mode" }}
            />
            <Typography variant="body1" style={{ marginRight: "10px" }}>
              {multiInputMode ? "Switch to Single Input" : "Switch to Multi Input"}
            </Typography>
          </label>
          <label style={{ display: "flex", alignItems: "center", marginBottom: "10px", cursor: "pointer" }}>
            <Switch
              checked={hexInputMode}
              onChange={handleToggleHexRgbMode}
              color="primary"
              inputProps={{ "aria-label": "Toggle Hex/RGB Mode" }}
            />
            <Typography variant="body1" style={{ marginRight: "10px" }}>
              Hex Input Mode
            </Typography>
          </label>
        </div>
        {multiInputMode ? (
          <div style={{ display: "flex" }}>
            <TextField
              label={`Hex Color (up to 4 colors)`}
              autoComplete="off"
              value={colors[0]}
              onChange={(event) => handleColorChange(event as React.ChangeEvent<HTMLInputElement>, 0)}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            {colors.map((color, index) => (
              <TextField
                key={index}
                autoComplete="off"
                label={`Hex Color ${index + 1}`}
                value={color}
                onChange={(event) => handleColorChange(event as React.ChangeEvent<HTMLInputElement>, index)}
                style={{ marginRight: "10px" }}
              />
            ))}
          </div>
        )}
        <Button style={{ marginTop: "10px" }} variant="contained" color="primary" onClick={createPalette}>
          Create Palette
        </Button>
        <Button style={{ marginTop: "10px", marginLeft: "10px" }} variant="outlined" onClick={showAll}>
          Show All <VisibilityIcon />
        </Button>
        <Button style={{ marginTop: "10px", marginLeft: "10px" }} variant="outlined" onClick={hideAll}>
          Hide All <VisibilityOffIcon />
        </Button>
        <Button style={{ marginTop: "10px", marginLeft: "10px" }} variant="outlined" onClick={handleConvertDialogOpen}>
          Convert Hex/RGB
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        {sortedColorPalettes.map((palette) => (
          <Paper key={palette.id} elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => toggleMinimizedPalette(palette.id)} size="small" color="primary">
                {palette.isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                {palette.isHidden ? "Hidden" : "Colors"}
              </Typography>
              <IconButton color="default" size="small" onClick={() => copyPalette(palette.id)}>
                <FileCopyIcon />
              </IconButton>
              <IconButton color="error" onClick={() => deletePalette(palette.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
            {palette.isHidden ? null : (
              <div style={{ display: "flex", padding: "10px" }}>
                {palette.hexColors.map((color, colorIndex) => (
                  <Tooltip key={colorIndex} title={hexInputMode ? color : stringUtils.hexToRgb(color)}>
                    <Box
                      width="50px"
                      height="50px"
                      bgcolor={color}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        copyToClipboard(hexInputMode ? color : stringUtils.hexToRgb(color));
                      }}
                    ></Box>
                  </Tooltip>
                ))}
              </div>
            )}
          </Paper>
        ))}
      </div>
      <p>Copied value: {copiedValue ?? "Nothing is copied yet!"}</p>
      <Dialog open={dialogOpen} onClose={handleConvertDialogClose}>
        <DialogTitle>Convert Hex/RGB</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Conversion Type</FormLabel>
            <RadioGroup
              aria-label="conversion-type"
              name="conversion-type"
              value={conversionType}
              onChange={handleConversionTypeChange}
              row
            >
              <FormControlLabel value="hexToRgb" control={<Radio />} label="Hex to RGB" />
              <FormControlLabel value="rgbToHex" control={<Radio />} label="RGB to Hex" />
            </RadioGroup>
          </FormControl>
          <TextField
            label={conversionType === "hexToRgb" ? "Hex Value" : "RGB Value"}
            fullWidth
            variant="outlined"
            value={convertValue}
            onChange={handleConvertValueChange}
            InputProps={
              conversionType === "hexToRgb"
                ? {
                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                  }
                : {}
            }
          />
          {convertedValue && (
            <Typography variant="body2" color="textSecondary">
              Converted Value: {convertedValue}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConvertDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ColorPalette;
