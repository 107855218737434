import React from "react";

interface SpinnerProps {
  isLoading: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ isLoading }) => {
  if (!isLoading) {
    // Early exit: If isLoading is false, return null
    return null;
  }

  // CSS styles to center the spinner vertically and horizontally
  const spinnerContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%", // Ensure the container fills the available height
  };

  return (
    <div style={spinnerContainerStyles}>
      {/* Replace this with your spinner component */}
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
