import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Box,
  Typography,
  DialogActions,
  Snackbar,
  Alert,
  Divider,
  MenuItem,
} from "@mui/material";
import {
  EmailTemplateForm,
  EmailTemplateFormField,
  EmailTemplateFormVersion,
  PersonForm,
  PersonFormEmail,
  PersonFormEmailTemplateField,
  PersonFormEmailTemplateFieldOptions,
} from "api/models";
import stringUtils from "utils/stringUtils";
import { Moment } from "moment";
import moment from "moment";
import CustomIcon from "components/CustomIcon";
import { useCopyToClipboard } from "usehooks-ts";
import objectUtils from "utils/objectUtil";
import { Liquid } from "liquidjs";
import sortUtils from "utils/sortUtils";

interface SendEmailDialogProps {
  isOpen: boolean;
  onSave: (newEmail: PersonFormEmail) => void;
  onClose: () => void;
  person: PersonForm;
  emailTemplates: EmailTemplateForm[];
}

interface ActiveEmail {
  email?: PersonFormEmail;
  subject: string;
  contents: string;
  date: Moment;
}

const getLatestEmailOrDefault = (person: PersonForm): ActiveEmail => {
  let activeEmail: ActiveEmail = {
    subject: "",
    contents: "",
    date: moment(),
  };

  const sortedEmails = person.emails.sort((a, b) => sortUtils.byDate(a, b, (x) => x.created)) ?? [];

  if (sortedEmails.length > 0) {
    const firstEmail = sortedEmails[0];

    activeEmail = {
      date: firstEmail.created,
      contents: firstEmail.usedTemplate?.parsedEmail
        ? firstEmail.usedTemplate.parsedEmail
        : firstEmail.customEmail ?? "",
      subject: firstEmail.subject,
      email: firstEmail,
    };
  }
  return activeEmail;
};

const engine = new Liquid();

interface NewEmailFromTemplate {
  templateContent: string;
  parsedTemplateContent: string;
  templateSubject: string;
  templateFields: PersonFormEmailTemplateField[];
}

const SendEmailDialog: React.FC<SendEmailDialogProps> = (props: SendEmailDialogProps) => {
  const { isOpen, onSave, onClose, person, emailTemplates } = props;
  const [activeEmail, setActiveEmail] = useState<ActiveEmail>(getLatestEmailOrDefault(person));
  const [copiedValue, copyToClipboard] = useCopyToClipboard();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalState, setModalState] = useState<"view" | "compose">("view");
  const [showTemplateFieldData, setShowTemplateFieldData] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateForm | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<EmailTemplateFormVersion | null>(null);
  const [internalPerson, setInternalPerson] = useState<PersonForm>(person);

  const [newEmail, setNewEmail] = useState<NewEmailFromTemplate>({
    templateContent: "",
    parsedTemplateContent: "",
    templateSubject: "",
    templateFields: [],
  });

  const onCloseAdvanced: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void = (_, reason) => {
    const isBackdropClickOrEscapePress = reason === "backdropClick" || reason === "escapeKeyDown";

    if (modalState === "compose") {
      if (isBackdropClickOrEscapePress) {
        return;
      }
    }

    onClose();
  };

  // const [templateContents, setTemplateContents] = useState<string>("");
  const [templateSubject, setTemplateSubject] = useState<string>("");
  const [templateFields, setTemplateFields] = useState<PersonFormEmailTemplateField[]>([]);
  const [emails, setEmails] = useState<PersonFormEmail[]>([]);

  useEffect(() => {
    const sortedEmails = internalPerson.emails.sort((a, b) => sortUtils.byDate(a, b, (x) => x.created)) ?? [];
    setEmails(sortedEmails);

    const latestEmailOrDefault = getLatestEmailOrDefault(internalPerson);

    if (activeEmail.email?.id !== latestEmailOrDefault.email?.id) {
      setActiveEmail(latestEmailOrDefault);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPerson, internalPerson.emails]);

  useEffect(() => {
    setInternalPerson(person);
  }, [person]);

  useEffect(() => {
    const fields = templateFields.reduce((accumulator: any, t: PersonFormEmailTemplateField) => {
      const value = stringUtils.isNullOrWhitespace(t.value) ? t.rawTemplate : t.value;
      accumulator[t.key] = value;
      return accumulator;
    }, {});

    const parsedTemplateContent = engine.parseAndRenderSync(newEmail.templateContent, fields);

    setNewEmailState((newEmailState: NewEmailFromTemplate) => {
      newEmailState.parsedTemplateContent = parsedTemplateContent;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFields]);

  const setNewEmailState = (updater: (newEmailCopy: NewEmailFromTemplate) => void) => {
    const newEmailCopy = objectUtils.deepCopyObject(newEmail);
    updater(newEmailCopy);
    setNewEmail(newEmailCopy);
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(person.emailAddress);
    setIsPopupOpen(true);
  };

  const handleComposeClick = () => {
    setModalState("compose");
  };

  const handleGmailSentClick = () => {
    var to = person.emailAddress;
    var gmailSentByEmail = `https://mail.google.com/mail/u/0/#search/in%3Asent+${encodeURIComponent(to)}`;
    window.open(gmailSentByEmail, "_blank");
  };

  const handleCloseCompose = () => {
    setModalState("view");
  };

  const handleEmailClick = (email: PersonFormEmail) => {
    let contents = "";

    if (email) {
      if (email.customEmail && !stringUtils.isNullOrWhitespace(email.customEmail)) {
        contents = email.customEmail;
      }

      if (email.usedTemplate) {
        contents = email.usedTemplate.parsedEmail;
      }
    }

    setActiveEmail({
      email: email,
      subject: email.subject,
      contents: contents,
      date: email.created,
    });
  };

  const convertFields = (version: EmailTemplateFormVersion | null) => {
    const fields = version?.fields ?? [];
    const convertedTemplateFields: PersonFormEmailTemplateField[] = fields.map((field: EmailTemplateFormField) => {
      const options = field.options;
      let convertedOption: PersonFormEmailTemplateFieldOptions = { type: "string" };

      switch (options.type) {
        case "string":
          convertedOption = { type: "string" };
          break;
        case "number":
          convertedOption = { type: "number", decimals: options.decimals };
          break;
        case "date":
          convertedOption = { type: "date", format: options.format };
          break;
        case "conditional":
          convertedOption = { type: "conditional", inputType: options.inputType };
          break;
      }

      const versionField: PersonFormEmailTemplateField = {
        id: field.id,
        rawTemplate: field.rawTemplate,
        key: field.key,
        options: convertedOption,
        value: "",
        order: field.order,
        created: field.created,
        updated: field.updated,
      };

      return versionField;
    });

    return convertedTemplateFields;
  };

  const handleTemplateChange = (templateId: string) => {
    const template = emailTemplates.find((template) => template.id === templateId);
    setSelectedTemplate(template ? template : null);

    const latestVersion =
      template?.templates.filter((t) => !t.isDisabled).sort((a, b) => b.version - a.version)[0] ?? null;
    setSelectedVersion(latestVersion);
    setTemplateFields(convertFields(latestVersion));
    setTemplateSubject(latestVersion?.subject ?? "");

    setNewEmailState((newEmailState: NewEmailFromTemplate) => {
      const templateContent = latestVersion ? latestVersion.template : "";

      newEmailState.templateContent = templateContent;
      newEmailState.parsedTemplateContent = templateContent;
    });
  };

  const handleVersionChange = (versionId: string) => {
    const version = selectedTemplate?.templates.find((version) => version.id === versionId) ?? null;
    setSelectedVersion(version);

    setTemplateFields(convertFields(version));
    setTemplateSubject(version?.subject ?? "");

    setNewEmailState((newEmailState: NewEmailFromTemplate) => {
      const templateContent = version?.template ?? "";

      newEmailState.templateContent = templateContent;
      newEmailState.parsedTemplateContent = templateContent;
    });
  };

  const handleEmailSend = (sendToGmail?: boolean) => {
    var to = person.emailAddress;
    var subject = templateSubject;
    var body = newEmail.parsedTemplateContent;

    if (sendToGmail) {
      var createGmailLink = (to: string, subject: string, body: string) => {
        return (
          "https://mail.google.com/mail/?view=cm" +
          "&to=" +
          encodeURIComponent(to) +
          "&su=" +
          encodeURIComponent(subject) +
          "&body=" +
          encodeURIComponent(body)
        );
      };
      var gmailLink = createGmailLink(to, subject, body);

      if (gmailLink.length > 2048) {
        gmailLink = createGmailLink(to, subject, "Message too long. Please copy email body from Fabled Founders.");
      }

      window.open(gmailLink, "_blank");
    }

    const newPersonFormEmail: PersonFormEmail = {
      id: stringUtils.uuid(),
      created: moment(),
      updated: moment(),
      subject: subject,
    };

    if (selectedTemplate && selectedVersion) {
      newPersonFormEmail.usedTemplate = {
        parsedEmail: body,
        template: {
          id: selectedTemplate.id,
          created: selectedTemplate.created,
          updated: selectedTemplate.updated,
          name: selectedTemplate.name,
          isDisabled: selectedTemplate.isDisabled,
          template: {
            id: selectedVersion.id,
            created: selectedVersion.created,
            updated: selectedVersion.updated,
            fields: templateFields.map((f) => {
              const field: PersonFormEmailTemplateField = {
                id: f.id,
                rawTemplate: f.rawTemplate,
                key: f.key,
                options: f.options,
                value: f.value,
                order: f.order,
                created: f.created,
                updated: f.updated,
              };
              return field;
            }),
            version: selectedVersion.version,
            template: selectedVersion.template,
            isDisabled: selectedVersion.isDisabled,
          },
        },
      };
    } else {
      newPersonFormEmail.customEmail = body;
    }

    onSave(newPersonFormEmail);

    const newInternalPerson = objectUtils.deepCopyObject(internalPerson);
    newInternalPerson.emails = newInternalPerson.emails ?? [];
    newInternalPerson.emails.push(newPersonFormEmail);
    setInternalPerson(newInternalPerson);

    setActiveEmail({
      date: newPersonFormEmail.created,
      contents: newPersonFormEmail.usedTemplate?.parsedEmail
        ? newPersonFormEmail.usedTemplate.parsedEmail
        : newPersonFormEmail.customEmail ?? "",
      subject: newPersonFormEmail.subject,
      email: newPersonFormEmail,
    });
    handleCloseCompose();
  };

  const hasUnselectedEmail = stringUtils.isNullOrWhitespace(activeEmail.subject);

  const subject = hasUnselectedEmail ? "Untitled" : activeEmail.subject;

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const currentViewDialogContent = () => (
    <DialogContent dividers>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Typography>
          Recipient: <span style={{ fontWeight: "bold" }}>{person.emailAddress}</span>
        </Typography>
        <CustomIcon
          style={{ paddingLeft: "0.5em" }}
          icon="copy"
          tooltip="Copy to Clipboard"
          onClick={handleCopyToClipboard}
        />
      </Box>
      <Divider sx={{ mt: "0.5em", mb: "1em" }} />
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} sm={4} style={{ overflowY: "auto", maxHeight: "500px" }}>
          {emails.length > 0 ? (
            <List sx={{ py: "0.5em" }}>
              {emails.map((email) => (
                <>
                  <ListItemButton
                    key={email.id}
                    onClick={() => handleEmailClick(email)}
                    selected={activeEmail.email?.id === email.id}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      mb: "2px",
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      primary={stringUtils.isNullOrWhitespace(email.subject) ? "Untitled" : email.subject}
                      secondary={
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "italic",
                          }}
                        >
                          {moment(email.created).format("M/D/YY")}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </>
              ))}
            </List>
          ) : (
            <Box>There are no emails.</Box>
          )}
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={8} style={{ paddingLeft: "12px" }}>
          <Box>
            <Typography variant="h6" sx={{ fontStyle: hasUnselectedEmail ? "italic" : null, paddingLeft: "12px" }}>
              {subject ?? "Untitled"}
            </Typography>
            <TextField
              multiline
              fullWidth
              margin="dense"
              id="email-content"
              minRows={17}
              maxRows={17}
              value={activeEmail.contents}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );

  const newComposeDialogContent = () => {
    const groupedFields = templateFields.reduce(
      (
        result: {
          conditionals: PersonFormEmailTemplateField[];
          others: { key: string; order: number; element: JSX.Element }[];
        },
        obj
      ) => {
        if (!obj.isDisabled) {
          if (obj.options.type === "conditional") {
            result.conditionals.push(obj);
          } else {
            const element = (
              <Box key={obj.id} sx={{ mb: 1 }}>
                <TextField
                  id={obj.id}
                  key={obj.key}
                  label={obj.key}
                  value={obj.value}
                  onChange={(e) => {
                    const newTemplateFields = objectUtils.deepCopyObject(templateFields);
                    const matchingField = newTemplateFields.find((f) => f.id === obj.id);

                    if (matchingField) {
                      matchingField.value = e.target.value;
                      setTemplateFields(newTemplateFields);
                    }
                  }}
                  fullWidth
                  multiline
                  variant="outlined"
                  margin="dense"
                />
              </Box>
            );

            result.others.push({
              key: obj.key,
              order: obj.order,
              element,
            });
          }
        }
        return result;
      },
      { conditionals: [], others: [] }
    );

    groupedFields.conditionals.sort((a, b) => a.key.localeCompare(b.key));
    groupedFields.others.sort((a, b) => a.order - b.order);

    return (
      <DialogContent dividers>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", pt: "1em" }}>
          {/* Template Selection Dropdown */}
          <TextField
            select
            style={{ marginRight: "16px", width: "100%", maxWidth: "400px" }}
            label="Select Template"
            value={selectedTemplate ? selectedTemplate.id : ""}
            onChange={(e) => handleTemplateChange(e.target.value)}
            sx={{ mb: 2 }}
          >
            {emailTemplates
              .filter((template) => !template.isDisabled)
              .map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
          </TextField>

          {/* Version Selection Dropdown */}
          <TextField
            select
            style={{ width: "12em", minWidth: "150px" }}
            label="Select Version"
            value={selectedVersion ? selectedVersion.id : ""}
            onChange={(e) => handleVersionChange(e.target.value)}
            sx={{ mb: 2 }}
          >
            {selectedTemplate &&
              selectedTemplate.templates.map((version) => (
                <MenuItem key={version.id} value={version.id}>
                  {`Version ${version.version}`}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} sm={8}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
              <Typography variant="h6" sx={{ mr: 1.5 }}>
                {"Email"}
              </Typography>
              <CustomIcon
                icon={showTemplateFieldData ? "visibleOff" : "visibleOn"}
                tooltip="Show/hide template fields"
                onClick={() => setShowTemplateFieldData(!showTemplateFieldData)}
              />
            </Box>
            <Box>
              {/* Subject Line Input */}
              <TextField
                id="emailTo"
                label="To"
                fullWidth
                variant="outlined"
                margin="dense"
                value={`${person.name}<${person.emailAddress}>`}
              />
              <TextField
                id="emailSubject"
                label="Subject"
                value={templateSubject}
                fullWidth
                variant="outlined"
                margin="dense"
              />
              {/* Textarea pre-populated with template email contents */}
              <TextField
                multiline
                fullWidth
                margin="dense"
                id="email-contents"
                label="Email Content"
                minRows={20}
                maxRows={20}
                value={showTemplateFieldData ? newEmail.parsedTemplateContent : newEmail.templateContent}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={4}>
            {/* Add your fields logic here */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography variant="h6" sx={{ mr: 1.5 }}>
                {"Template Fields"}
              </Typography>
              <CustomIcon
                icon="info"
                tooltip="More about liquidjs templates"
                onClick={() => window.open("https://liquidjs.com/", "_blank")}
              />
            </Box>
            {groupedFields.others.map((field) => {
              return field.element;
            })}
            {groupedFields.conditionals.map((field) => {
              return <Box key={field.id}>{field.key}</Box>;
            })}
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  const renderModalContent = modalState === "compose" ? newComposeDialogContent : currentViewDialogContent;

  return (
    <>
      <Dialog open={isOpen} onClose={onCloseAdvanced} maxWidth="xl" fullWidth>
        <DialogTitle>{modalState === "compose" ? "Compose Email" : "Emails"}</DialogTitle>
        <CustomIcon
          aria-label="close"
          icon="close"
          onClick={() => onCloseAdvanced()}
          style={{
            position: "absolute",
            right: "1.5em",
            top: "0.75em",
          }}
        />
        {renderModalContent()}
        <DialogActions sx={{ padding: "1em" }}>
          {modalState === "compose" ? (
            <>
              <Button onClick={() => handleEmailSend(false)} color="primary" variant="contained">
                {"Save"}
              </Button>
              <Button onClick={() => handleEmailSend(true)} color="primary" variant="contained">
                {"Send w/ Gmail"}
              </Button>
              <Button onClick={handleCloseCompose} variant="outlined">
                {"Cancel"}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleComposeClick} color="primary" variant="contained">
                {"Compose"}
              </Button>
              <Button onClick={handleGmailSentClick} color="primary" variant="outlined">
                {"Gmail Sent"}
              </Button>
              <Button onClick={() => onCloseAdvanced()} color="primary" variant="outlined">
                {"Close"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar open={isPopupOpen} autoHideDuration={3000} onClose={handlePopupClose}>
        <Alert onClose={handlePopupClose} sx={{ width: "100%" }}>
          Copied "{copiedValue}"
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendEmailDialog;
