const primaryLight = "#4889c4";
const primaryMain = "#26577c";
const primaryDark = "#152e3e";
const primaryContrastText = "#ebe4d1";

const secondaryLight = "#ff8c4b";
const secondaryMain = "#e55604";
const secondaryDark = "#b73900";
const secondaryContrastText = "#ebe4d1";

export type PrimaryColor = typeof primaryLight | typeof primaryMain | typeof primaryDark | typeof primaryContrastText;
export type SecondaryColor =
  | typeof secondaryLight
  | typeof secondaryMain
  | typeof secondaryDark
  | typeof secondaryContrastText;

export interface PaletteBaseProps {
  primary: {
    light: PrimaryColor;
    main: PrimaryColor;
    dark: PrimaryColor;
    contrastText: PrimaryColor;
  };
  secondary: {
    light: SecondaryColor;
    main: SecondaryColor;
    dark: SecondaryColor;
    contrastText: SecondaryColor;
  };
}

export const getPaletteBase = (): PaletteBaseProps => {
  return {
    primary: {
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: primaryContrastText,
    },
    secondary: {
      light: secondaryLight,
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: secondaryContrastText,
    },
  };
};

/*
Total Color Scheme - #ebe4d1,#b4b4b3,#26577c,#e55604

Newer Color Schemes
#4889c4,#26577c,#152e3e,#fbfbfb - blue
#ff8c4b,#e55604,#b73900,#fbfbfb - orange

Old Color Schemes
#9FF1D2, #127C71, #0D6A69, #fbfbfb
#FDE6A8, #ffaf35, #D0821C, #fbfbfb
*/
