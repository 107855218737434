import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, Menu, MenuItem, Box, useMediaQuery, Theme } from "@mui/material";
import localStorageUtil from "utils/localStorageUtil";
import moment from "moment";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Domain, environmentOrder, useConfig } from "./Config";
import Logo from "./Logo";
import theme from "config/theme/theme";
import CustomIcon from "./CustomIcon";
import { TypedJSON } from "typedjson";
import { PaddleClubBase } from "api/paddle/paddleStorageModels";
import localPaddleStorageUtil from "utils/localPaddleStorageUtil";
import FullScreenModal from "./FullScreenModal";
import localSettingsStorageUtil from "utils/localSettingsStorageUtil";

type DomainDownloadConfig = {
  [x in Domain]: {
    createFileName: (currentDate: string) => string;
    getDomainJsonData: () => string;
  };
};

const Navigation: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const settings = localSettingsStorageUtil.get();

  const { config } = useConfig();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    if (isMdScreen) {
      setShowModal(false);
    }
  };

  const mobileLinkStyle: React.CSSProperties = !isMdScreen
    ? {}
    : {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      };

  const mobileButtonStyle: React.CSSProperties = !isMdScreen
    ? {}
    : {
        width: "100%",
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
      };

  const configIndex = environmentOrder.indexOf(config.environment);
  const { primaryRoutes, secondaryRoutes } = config.fullRoutes;

  const routesForCurrentEnv = primaryRoutes.filter((r) => {
    const highestEnvIndex = environmentOrder.indexOf(r.highestEnvironment);
    return highestEnvIndex >= configIndex;
  });

  const secondaryRoutesForCurrentEnv = secondaryRoutes.filter((r) => {
    const highestEnvIndex = environmentOrder.indexOf(r.highestEnvironment);
    return highestEnvIndex >= configIndex;
  });

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const domainDownloadConfig: DomainDownloadConfig = {
    "fabledfounders.com": {
      createFileName: (currentDate: string) => {
        return `${settings.exportFileName}-${currentDate}.json`;
      },
      getDomainJsonData: () => {
        const globalData = localStorageUtil.get();
        const jsonData = JSON.stringify(globalData, null, 2);
        return jsonData;
      },
    },
    "titanpaddle.com": {
      createFileName: (currentDate: string) => {
        return `${settings.exportFileName}-${currentDate}.json`;
      },
      getDomainJsonData: () => {
        const serializer = new TypedJSON(PaddleClubBase);
        const paddleClub = localPaddleStorageUtil.get();
        const jsonData = serializer.stringify(PaddleClubBase.create(paddleClub));
        return jsonData;
      },
    },
  };

  const downloadAllData = () => {
    const currentDate = moment().format("YYYYMMDD-HHmm");

    const { createFileName, getDomainJsonData } = domainDownloadConfig[config.domain];
    const jsonData = getDomainJsonData();
    const fileName = createFileName(currentDate);

    // Create a Blob containing the JSON data
    const blob = new Blob([jsonData], { type: "application/json" });

    // Create a download link and trigger a click event to prompt the user to download the file
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up the temporary link
    document.body.removeChild(a);
  };

  const PrimaryLinks = () => {
    return (
      <>
        {routesForCurrentEnv
          .filter((r) => r.isPrimary)
          .map((r) => {
            return (
              <Link
                key={r.title.toLowerCase().replaceAll(" ", "_")}
                to={r.path}
                style={{ ...mobileLinkStyle, textDecoration: "none", color: theme.base.primary.main }}
                onClick={closeModal}
              >
                <Button color="inherit" style={{ ...mobileButtonStyle }}>
                  {r.title}
                </Button>
              </Link>
            );
          })}
      </>
    );
  };

  const SecondaryLinks = () => {
    if (secondaryRoutesForCurrentEnv.length <= 0) {
      return <></>;
    }

    return (
      <Box sx={{ width: isMdScreen ? "100%" : undefined }}>
        <Button
          onClick={handleMenuOpen}
          style={{ ...mobileButtonStyle, color: theme.base.primary.main }}
          color="inherit"
          aria-controls="secondary-menu"
          aria-haspopup="true"
        >
          Other Tools {Boolean(anchorEl) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Button>
        <Menu id="secondary-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {secondaryRoutesForCurrentEnv.map((r) => (
            <Link
              to={r.path}
              style={{ ...mobileLinkStyle, textDecoration: "none", color: theme.base.primary.main }}
              key={r.title.toLowerCase().replaceAll(" ", "_")}
              onClick={closeModal}
            >
              <MenuItem onClick={handleMenuClose} style={{ ...mobileButtonStyle }}>
                {r.title}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
    );
  };

  const showLinks = () => {
    if (isMdScreen) {
      return <CustomIcon icon="menu" onClick={toggleModal} />;
    }

    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <PrimaryLinks />
        {SecondaryLinks()}
        <CustomIcon icon="download" onClick={downloadAllData} tooltip="Export all data" />
      </Box>
    );
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.base.primary.contrastText }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: "1em" }}>
        <Logo />
        {showLinks()}
      </Toolbar>
      <FullScreenModal isHidden={!showModal} onClose={toggleModal}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            height: "70%",
            padding: "1em",
            gap: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: isMdScreen ? "100%" : undefined,
            }}
          >
            <PrimaryLinks />
            {SecondaryLinks()}
            <CustomIcon
              icon="download"
              onClick={downloadAllData}
              tooltip="Export all data"
              sx={{ m: isMdScreen ? "1em" : undefined }}
            />
          </Box>
        </Box>
      </FullScreenModal>
    </AppBar>
  );
};

export default Navigation;
