// useCompaniesStore.tsx
import { CompanyForm, GlobalForm, PersonForm } from "api/models";
import { useEffect, useReducer } from "react";
import localStorageUtil from "utils/localStorageUtil";
import { SortOrder } from "utils/SortOrder";
import moment from "moment";
import objectUtils from "utils/objectUtil";
import stringUtils from "utils/stringUtils";

export enum ActionType {
  Load = "Load",
  LoadGlobal = "LoadGlobal",
  SyncedWithGlobal = "SyncedWithGlobal",
  SaveCompanies = "SaveCompanies",
  SaveCompany = "SaveCompany",
  UpdateCompany = "UpdateCompany",
  ToggleDeleteModal = "ToggleDeleteModal",
  ToggleEditModal = "ToggleEditModal",
  Sort = "Sort",
  ToggleAddEmployeeModal = "ToggleAddEmployeeModal",
  UpdatePerson = "UpdatePerson",
}

interface Defaults {
  company: CompanyForm;
  person: PersonForm;
}

const defaults: Defaults = {
  company: {
    id: "",
    name: "",
    linkedinUrl: "",
    linkedinUrlShorthand: "",
    website: "",
    industry: "",
    location: "",
    employees: [],
  },
  person: {
    id: "",
    name: "",
    companyId: "",
    jobTitle: "",
    linkedinUrl: "",
    linkedinUrlShorthand: "",
    emailAddress: "",
    phone: "",
    twitterHandle: "",
    notes: [],
    emails: [],
  },
};

type GlobalCompanyState = {
  globalForm: GlobalForm;
  companyState: CompaniesState;
};

// Define action types and the initial state
type CompaniesState = {
  companies: CompanyForm[];
  availablePeople: PersonForm[];
  isInitiallyLoaded: boolean;
  shouldSave: boolean;
  isLoading: boolean;
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  isAddEmployeeModalOpen: boolean;
  deletingCompany: CompanyForm | undefined;
  editingCompany: CompanyForm;
  editingPerson: PersonForm | undefined;
  nameSortOrder?: SortOrder;
};

type Action =
  | { type: ActionType.Load; payload: { globalForm: GlobalForm; companies: CompanyForm[] } }
  | { type: ActionType.LoadGlobal; payload: { globalForm: GlobalForm } }
  | { type: ActionType.SyncedWithGlobal; payload: boolean }
  | { type: ActionType.SaveCompanies; payload: CompanyForm[] }
  | {
      type: ActionType.UpdateCompany;
      payload: { id: string; company: CompanyForm }; // Change 'index' to 'id'
    }
  | {
      type: ActionType.SaveCompany; // New action type
      payload: CompanyForm; // New payload for saving a single company
    }
  | {
      type: ActionType.ToggleDeleteModal;
      payload: { isOpen: boolean; company?: CompanyForm };
    }
  | {
      type: ActionType.ToggleEditModal;
      payload: { isOpen: boolean; editingCompany?: CompanyForm };
    }
  | {
      type: ActionType.Sort;
      payload: keyof CompanyForm;
    }
  | {
      type: ActionType.ToggleAddEmployeeModal;
      payload: { isOpen: boolean; editingCompany?: CompanyForm; editingPerson?: PersonForm };
    }
  | {
      type: ActionType.UpdatePerson; // Added UpdatePerson action type
      payload: { personId: string | undefined; editingPerson: PersonForm };
    };

const linkedInShorthandRegex = /linkedin\.com\/company\/([^/]+)/;

const localUtils = {
  getLinkedInUrlShorthand: (linkedInUrl: string | undefined): string | undefined => {
    if (linkedInUrl && linkedInUrl?.trim() !== "") {
      const match = linkedInUrl.match(linkedInShorthandRegex);

      if (match && match[1]) {
        const companyName = match[1];
        return companyName;
      }
    }

    return undefined;
  },
  sortCompaniesByName: (sortOrder: Exclude<SortOrder, "undefined">, companies: CompanyForm[]): CompanyForm[] => {
    const copiedCompanies = [...companies];
    const sortedCompany = copiedCompanies.sort((a, b) => {
      const valueA = (a.name as string) || ""; // Convert null or undefined to an empty string
      const valueB = (b.name as string) || ""; // Convert null or undefined to an empty string

      if (valueA === valueB) {
        return 0;
      } else if (valueA === "" || valueA == null) {
        return 1; // Place empty strings, null, or undefined values at the bottom
      } else if (valueB === "" || valueB == null) {
        return -1; // Place empty strings, null, or undefined values at the bottom
      } else {
        return valueA.localeCompare(valueB) * (sortOrder === "desc" ? -1 : 1);
      }
    });

    return [...sortedCompany];
  },
};

const reducer = (state: GlobalCompanyState, action: Action): GlobalCompanyState => {
  const newState = objectUtils.deepCopyObject(state);

  switch (action.type) {
    case ActionType.Load: {
      const { globalForm, companies } = action.payload;
      const companiesWithAtLeastOne = companies;

      if (companiesWithAtLeastOne.length === 0) {
        companiesWithAtLeastOne.push({
          id: stringUtils.uuid(),
          name: "",
          industry: "",
          location: "",
          linkedinUrl: "",
          website: "",
          employees: [],
        });
      }

      newState.globalForm = globalForm;

      const peopleList = globalForm.people.list ?? [];
      const modifiedCompanies = companiesWithAtLeastOne.map((c) => {
        return {
          ...c,
          employeeCount: peopleList.filter((p) => p.companyId === c.id).length ?? 0,
        };
      });

      newState.companyState = {
        companies: modifiedCompanies,
        availablePeople: globalForm.people.list ?? [],
        isInitiallyLoaded: true,
        shouldSave: false,
        isLoading: false,
        isDeleteModalOpen: false,
        isEditModalOpen: false,
        isAddEmployeeModalOpen: false,
        deletingCompany: undefined,
        editingCompany: { ...defaults.company, id: stringUtils.uuid() },
        editingPerson: {
          ...defaults.person,
          id: stringUtils.uuid(),
        },
      };

      return newState;
    }
    case ActionType.LoadGlobal: {
      const { globalForm } = action.payload;

      const newPeopleList = globalForm.people.list;
      const existingPeopleList = newState.globalForm.people.list;

      if (existingPeopleList.length < newPeopleList.length) {
        newState.companyState.companies = newState.companyState.companies.map((c) => {
          return {
            ...c,
            employeeCount: newPeopleList.filter((p) => p.companyId === c.id).length ?? 0,
          };
        });
      }

      newState.globalForm = globalForm;
      newState.companyState.shouldSave = false;
      return newState;
    }
    case ActionType.SaveCompanies: {
      newState.companyState.companies = [...action.payload];
      newState.companyState.shouldSave = true;
      newState.companyState.isDeleteModalOpen = false;
      newState.companyState.isEditModalOpen = false;
      return newState;
    }
    case ActionType.SaveCompany: {
      const newCompany = { ...action.payload };

      newCompany.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(newCompany?.linkedinUrl);

      const existingCompanyIndex = newState.companyState.companies.findIndex((company) => company.id === newCompany.id);

      if (existingCompanyIndex >= 0) {
        // If the company with the same ID exists, replace it
        newState.companyState.companies[existingCompanyIndex] = newCompany;
      } else {
        // If the company with the same ID doesn't exist, add it as a new company
        newState.companyState.companies.push(newCompany);
      }

      newState.companyState.companies = [...newState.companyState.companies];
      newState.companyState.shouldSave = true;
      newState.companyState.isDeleteModalOpen = false;
      newState.companyState.isEditModalOpen = false;

      return { ...newState };
    }
    case ActionType.UpdateCompany: {
      const { id, company } = action.payload;

      // Update editing company if it exists and matches
      if (newState.companyState.editingCompany?.id === id) {
        const updatedEditedCompany = {
          ...newState.companyState.editingCompany,
          ...company,
        };
        updatedEditedCompany.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(
          updatedEditedCompany?.linkedinUrl
        );

        newState.companyState.editingCompany = updatedEditedCompany;
        return newState;
      }

      // Update a record directly on the table format. Has the effect of saving directly to local storage.
      const updatedCompanies = newState.companyState.companies.map((company) => {
        if (company.id === id) {
          const updatedCompany = { ...company, ...company };
          updatedCompany.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(updatedCompany.linkedinUrl);
          return updatedCompany;
        }
        return company;
      });

      newState.companyState.companies = [...updatedCompanies];
      return newState;
    }
    case ActionType.ToggleDeleteModal: {
      const { isOpen, company } = action.payload;

      newState.companyState.isDeleteModalOpen = isOpen;
      newState.companyState.deletingCompany = company ?? undefined;
      return newState;
    }
    case ActionType.ToggleEditModal: {
      let { isOpen, editingCompany } = action.payload;

      let updatedEditingCompany =
        editingCompany === undefined ? { ...defaults.company, id: stringUtils.uuid() } : { ...editingCompany };

      newState.companyState.isEditModalOpen = isOpen;
      newState.companyState.editingCompany = updatedEditingCompany;
      return newState;
    }
    case ActionType.Sort: {
      const { companyState } = newState;
      const { companies } = companyState;
      const sortOrder = companyState.nameSortOrder;
      let newSortOrder = sortOrder;

      if (sortOrder === undefined) {
        newSortOrder = "asc";
      } else if (sortOrder === "asc") {
        newSortOrder = "desc";
      } else {
        newSortOrder = undefined;
      }

      const sortedCompanies = localUtils.sortCompaniesByName(newSortOrder ?? "asc", companies);
      newState.companyState.companies = sortedCompanies;
      newState.companyState.nameSortOrder = newSortOrder;

      return newState;
    }
    case ActionType.ToggleAddEmployeeModal: {
      const isOpen = action.payload.isOpen;
      let editingPerson = action.payload.editingPerson;
      const editingCompany = action.payload.editingCompany;

      newState.companyState.isAddEmployeeModalOpen = isOpen;

      newState.companyState.editingCompany =
        editingCompany !== undefined
          ? editingCompany
          : {
              ...defaults.company,
              id: stringUtils.uuid(),
            };

      newState.companyState.editingPerson =
        editingPerson !== undefined
          ? editingPerson
          : {
              ...defaults.person,
              id: stringUtils.uuid(),
            };

      return newState;
    }
    case ActionType.UpdatePerson: {
      const personIdToUpdate = action.payload.personId ?? stringUtils.uuid();
      const partialPerson = action.payload.editingPerson;

      if (partialPerson === undefined) {
        newState.companyState.editingPerson = {
          ...defaults.person,
          id: stringUtils.uuid(),
        };
      } else if (newState.companyState.editingPerson?.id === personIdToUpdate) {
        const updatedEditedPerson = {
          ...newState.companyState.editingPerson,
          ...partialPerson,
        };

        newState.companyState.editingPerson = updatedEditedPerson;
      }

      return newState;
    }
    default:
      return state;
  }
};

const defaultCompaniesState: CompaniesState = {
  companies: [],
  availablePeople: [],
  isInitiallyLoaded: false,
  shouldSave: false,
  isLoading: true,
  isDeleteModalOpen: false,
  isEditModalOpen: false,
  isAddEmployeeModalOpen: false,
  deletingCompany: undefined,
  editingCompany: { ...defaults.company, id: stringUtils.uuid() },
  editingPerson: { ...defaults.person, id: stringUtils.uuid() },
};

interface CompaniesActions {
  load: () => void;
  saveCompanies: (companies: CompanyForm[]) => void;
  saveCompany: (company: CompanyForm) => void;
  updateCompany: (id: string | undefined, company: CompanyForm) => void;
  toggleDeleteModal: (isOpen: boolean, company?: CompanyForm) => void;
  toggleEditModal: (isOpen: boolean, editingCompany?: CompanyForm) => void;
  sort: (field: keyof CompanyForm) => void;
  toggleAddEmployeeModal: (
    options: { isOpen: true; editingCompany: CompanyForm; editingPerson?: PersonForm } | { isOpen: false }
  ) => void;
  updatePerson: (personId: string | undefined, editingPerson: PersonForm) => void;
  addPerson: (personId: string | undefined, editingPerson: PersonForm, keepAdding?: boolean) => void;
}

export const useCompaniesStore = (): [CompaniesState, CompaniesActions] => {
  // Initialize state using the reducer
  const [globalState, dispatch] = useReducer(reducer, {
    globalForm: {
      companies: {
        list: [],
      },
      people: {
        list: [],
      },
      emails: {
        list: [],
      },
      ideas: {
        list: [],
      },
    },
    companyState: defaultCompaniesState,
  });

  const state = globalState.companyState;

  useEffect(() => {
    if (globalState.companyState.isInitiallyLoaded && globalState.companyState.shouldSave) {
      const newGlobalForm = localStorageUtil.setCompany(globalState.globalForm, globalState.companyState.companies);

      dispatch({
        type: ActionType.LoadGlobal,
        payload: { globalForm: newGlobalForm },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.companyState.companies,
    globalState.companyState.shouldSave,
    globalState.companyState.isInitiallyLoaded,
  ]);

  const load = () => {
    const globalForm = localStorageUtil.get();
    const companies = globalForm.companies.list;

    dispatch({
      type: ActionType.Load,
      payload: {
        companies: companies,
        globalForm: globalForm,
      },
    });
  };

  const saveCompanies = (companies: CompanyForm[]) => {
    dispatch({ type: ActionType.SaveCompanies, payload: companies });
  };

  const saveCompany = (company: CompanyForm) => {
    dispatch({
      type: ActionType.SaveCompany,
      payload: company,
    });
  };

  const updateCompany = (id: string | undefined, company: CompanyForm) => {
    if (!id) {
      return;
    }

    dispatch({
      type: ActionType.UpdateCompany,
      payload: { id, company },
    });
  };

  const toggleDeleteModal = (isOpen: boolean, company?: CompanyForm) => {
    dispatch({
      type: ActionType.ToggleDeleteModal,
      payload: { isOpen, company },
    });
  };

  const toggleEditModal = (isOpen: boolean, editingCompany?: CompanyForm) => {
    dispatch({
      type: ActionType.ToggleEditModal,
      payload: {
        isOpen,
        editingCompany: editingCompany,
      },
    });
  };

  const sort = (field: keyof CompanyForm) => {
    dispatch({
      type: ActionType.Sort,
      payload: field,
    });
  };

  const toggleAddEmployeeModal = (
    options: { isOpen: true; editingCompany: CompanyForm; editingPerson?: PersonForm } | { isOpen: false }
  ) => {
    const { isOpen } = options;

    if (isOpen) {
      dispatch({
        type: ActionType.ToggleAddEmployeeModal,
        payload: { isOpen: true, editingCompany: options.editingCompany, editingPerson: options.editingPerson },
      });
    } else {
      dispatch({
        type: ActionType.ToggleAddEmployeeModal,
        payload: { isOpen: false },
      });
    }
  };

  // Implement the updatePerson action
  const updatePerson = (personId: string | undefined, editingPerson: PersonForm) => {
    dispatch({
      type: ActionType.UpdatePerson,
      payload: { personId, editingPerson },
    });
  };

  const addPerson = (personId: string | undefined, editingPerson: PersonForm, keepAdding?: boolean) => {
    const { globalForm, companyState } = globalState;
    const { people } = globalForm;

    if (!companyState.editingCompany) {
      return;
    }

    let newId = personId ?? "";

    if (newId === "" || people.list.some((p) => p.id === newId)) {
      newId = stringUtils.uuid();
    }

    const newPerson: PersonForm = {
      id: newId,
      companyId: companyState.editingCompany.id,
      name: editingPerson.name ?? "",
      linkedinUrl: editingPerson.linkedinUrl ?? "",
      emailAddress: editingPerson.emailAddress ?? "",
      jobTitle: "",
      phone: "",
      twitterHandle: "",
      notes: [],
      emails: [],
      created: moment(),
      updated: moment(),
    };

    const newPeopleList = [...people.list, newPerson];
    const newGlobalForm = localStorageUtil.setPeople(globalForm, newPeopleList);

    dispatch({
      type: ActionType.LoadGlobal,
      payload: { globalForm: newGlobalForm },
    });

    const isAddingMore = keepAdding === true;

    if (isAddingMore) {
      dispatch({
        type: ActionType.ToggleAddEmployeeModal,
        payload: {
          isOpen: true,
          editingCompany: companyState.editingCompany,
          editingPerson: {
            ...defaults.person,
            id: stringUtils.uuid(),
          },
        },
      });
    } else {
      dispatch({
        type: ActionType.ToggleAddEmployeeModal,
        payload: {
          isOpen: false,
        },
      });
    }
  };

  return [
    state,
    {
      load,
      saveCompanies,
      saveCompany,
      updateCompany,
      toggleDeleteModal,
      toggleEditModal,
      sort,
      toggleAddEmployeeModal,
      updatePerson,
      addPerson,
    },
  ];
};
