import { Badge, IconButton, SxProps, Theme, Tooltip, TooltipProps } from "@mui/material";
import theme from "config/theme/theme";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FilterListIcon from "@mui/icons-material/FilterList";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import NumbersIcon from "@mui/icons-material/Numbers";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestoreIcon from "@mui/icons-material/Restore";
import RuleIcon from "@mui/icons-material/Rule";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type TooltipPosition = TooltipProps["placement"];

export interface CustomIconProps {
  icon: SupportedIcons;
  tooltip?: string;
  tooltipOptions?: {
    position?: TooltipPosition;
    followMouse?: boolean;
  };
  onClick?: () => void;
  style?: React.CSSProperties;
  sx?: SxProps<Theme> | undefined;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  isDisabled?: boolean;
  rotationDegrees?: number;
  badge?: string | boolean | undefined;
}

export type SupportedIcons =
  | "add"
  | "boolean"
  | "calendar"
  | "checklist"
  | "clear"
  | "close"
  | "conditional"
  | "conditional2"
  | "conditional3"
  | "copy"
  | "delete"
  | "download"
  | "edit"
  | "email"
  | "eyeOn"
  | "eyeOff"
  | "filterList"
  | "info"
  | "menu"
  | "number"
  | "openInNew"
  | "personAdd"
  | "personEdit"
  | "question"
  | "refresh"
  | "restore"
  | "star"
  | "search"
  | "textField"
  | "trophy"
  | "visibleOn"
  | "visibleOff";

type IconMapping = {
  [x in SupportedIcons]: (sx?: SxProps<Theme> | undefined) => JSX.Element;
};

const CustomIcon = (props: CustomIconProps) => {
  const { icon, tooltip, onClick, style, sx, isDisabled, rotationDegrees, tooltipOptions, size, badge } = props;

  const iconMappings: IconMapping = {
    add: () => <AddIcon />,
    boolean: () => <RuleIcon />,
    calendar: () => <CalendarMonthIcon />,
    checklist: () => <ChecklistIcon />,
    clear: () => <ClearIcon />,
    close: () => <CloseIcon />,
    conditional: () => <CallSplitIcon />,
    conditional2: () => <ForkRightIcon />,
    conditional3: () => <AccountTreeIcon />,
    copy: () => <ContentCopyIcon />,
    delete: () => <DeleteIcon />,
    download: () => <DownloadIcon />,
    edit: () => <EditIcon />,
    email: () => <EmailIcon />,
    eyeOn: () => <VisibilityIcon />,
    eyeOff: () => <VisibilityOffIcon />,
    filterList: () => <FilterListIcon />,
    info: () => <InfoIcon />,
    menu: () => <MenuIcon />,
    number: () => <NumbersIcon />,
    openInNew: () => <OpenInNewIcon />,
    personAdd: () => <PersonAddIcon />,
    personEdit: () => <CoPresentIcon />,
    question: () => <QuestionMarkIcon />,
    refresh: () => <RefreshIcon />,
    restore: () => <RestoreIcon />,
    search: () => <SearchIcon />,
    star: (sx) => <StarIcon sx={sx} />,
    textField: () => <TextFieldsIcon />,
    trophy: () => <EmojiEventsIcon />,
    visibleOn: () => <VisibilityIcon />,
    visibleOff: () => <VisibilityOffIcon />,
  };

  const defaultStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.base.primary.main,
    transform: rotationDegrees ? `rotate(${rotationDegrees}deg)` : undefined,
  };

  const defaultSx: SxProps<Theme> = {
    color: theme.base.primary.main,
  };

  let iconSx: SxProps<Theme> | undefined = undefined;

  if (size === "xs") {
    iconSx = { fontSize: "1rem" };
  }

  const newStyle = style ? { ...defaultStyle, ...style } : { ...defaultStyle };
  const newSx = sx ? { ...defaultSx, ...sx } : { ...defaultSx };

  const iconItem =
    badge !== undefined ? (
      <Badge badgeContent={badge} color="error" variant={badge === true ? "dot" : "standard"}>
        {iconMappings[icon](iconSx)}
      </Badge>
    ) : (
      iconMappings[icon](iconSx)
    );

  const iconButtonWithNoTooltip = (
    <div className="customIcon" style={newStyle}>
      <IconButton onClick={() => onClick && onClick()} sx={newSx} disabled={isDisabled}>
        {iconItem}
      </IconButton>
    </div>
  );

  const iconWithNoTooltip = (
    <div className="customIcon" style={newStyle}>
      {iconItem}
    </div>
  );

  let iconElement = onClick ? iconButtonWithNoTooltip : iconWithNoTooltip;

  if (!tooltip) {
    return iconElement;
  }

  return (
    <Tooltip
      className="customIconTooltip"
      title={tooltip}
      placement={tooltipOptions?.position}
      followCursor={tooltipOptions?.followMouse}
    >
      {iconElement}
    </Tooltip>
  );
};

export default CustomIcon;
