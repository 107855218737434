// useWarmNichesStore.tsx
import { IdAble } from "api/models";
import { useReducer } from "react";
import { v4 } from "uuid";

// Constant for the local storage key
const LOCAL_STORAGE_KEY = "warmNiches";

// Define action types with unique UUIDs
export enum ActionType {
  Load = "e2e925d1-4e19-4d10-bf3b-020727462396",
  Save = "a38a34f2-9073-48c4-85c3-5d6404ad9861",
  Clear = "4dfe45fc-1b0a-4bf0-9bf1-29351f8ea4a3",
  Update = "98c52d61-1c0d-4d2d-bb3d-50a4aa862fcf",
  ToggleClearModal = "6eef86f3-54d2-4d4a-bba9-47ef0b651769",
  ToggleDeleteModal = "ed7f9277-20cd-49c3-a979-76a135158b4e",
}

// Define the interface for warmNiches
export interface WarmNichesFormPerson extends IdAble {
  person: string;
  hobby: string;
  workExperience: string;
}

// Define the type for the data property
export interface WarmNichesForm {
  id: string;
  people: WarmNichesFormPerson[];
}

// Define action types and the initial state
type WarmNichesState = {
  data: WarmNichesForm;
  isLoading: boolean;
  isClearModalOpen: boolean;
  isDeleteModalOpen: boolean; // New property for delete confirmation dialog
  deletingPerson: string; // New property to store the name of the person being deleted
};

type Action =
  | { type: ActionType.Load; payload: WarmNichesForm }
  | { type: ActionType.Save; payload: WarmNichesForm }
  | { type: ActionType.Clear }
  | { type: ActionType.Update; payload: Partial<WarmNichesForm> }
  | { type: ActionType.ToggleClearModal; payload: boolean }
  | {
      type: ActionType.ToggleDeleteModal;
      payload: { isOpen: boolean; personName: string };
    }; // New action type for delete confirmation dialog

const defaultWarmNichesForm: WarmNichesForm = {
  id: "",
  people: [{ id: v4(), person: "", hobby: "", workExperience: "" }] as WarmNichesFormPerson[],
};

// Serialize the data object to JSON for storage
const serializeData = (data: WarmNichesForm) => JSON.stringify(data);
// Deserialize the JSON data from storage
const deserializeData = (dataString: string | null) =>
  dataString ? JSON.parse(dataString) : { ...defaultWarmNichesForm, id: v4() };

// Reducer function
const reducer = (state: WarmNichesState, action: Action): WarmNichesState => {
  switch (action.type) {
    case ActionType.Load:
      return {
        data: action.payload,
        isLoading: false,
        deletingPerson: "",
        isClearModalOpen: false,
        isDeleteModalOpen: false,
      };
    case ActionType.Save:
      return { ...state, data: action.payload };

    case ActionType.Clear:
      return {
        ...state,
        data: {
          id: "",
          people: [{ id: "", person: "", hobby: "", workExperience: "" }],
        },
        isClearModalOpen: false,
      };

    case ActionType.Update:
      return { ...state, data: { ...state.data, ...action.payload } };

    case ActionType.ToggleClearModal:
      return {
        ...state,
        data: { ...state.data },
        isClearModalOpen: action.payload,
      };

    case ActionType.ToggleDeleteModal:
      return {
        ...state,
        isDeleteModalOpen: action.payload.isOpen,
        deletingPerson: action.payload.personName,
      };

    default:
      return state;
  }
};

const defaultWarmNichesState: WarmNichesState = {
  data: { ...defaultWarmNichesForm },
  isLoading: true,
  isClearModalOpen: false,
  isDeleteModalOpen: false,
  deletingPerson: "",
};

// Define the interface for the return type
interface WarmNichesStore {
  state: WarmNichesState;
  actions: {
    load: () => void;
    saveData: (data: WarmNichesForm) => void;
    clearData: () => void;
    updateData: (data: Partial<WarmNichesForm>) => void;
    toggleClearModal: (payload: boolean) => void;
    toggleDeleteModal: (isOpen: boolean, personName: string) => void; // New action to toggle delete confirmation dialog
  };
}

export const useWarmNichesStore = (): WarmNichesStore => {
  // Initialize state using the reducer
  const [state, dispatch] = useReducer(reducer, defaultWarmNichesState);

  const load = () => {
    const savedData = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (savedData === null || savedData === undefined || savedData.trim() === "") {
      dispatch({
        type: ActionType.Load,
        payload: { ...defaultWarmNichesForm },
      });
    }

    dispatch({
      type: ActionType.Load,
      payload: deserializeData(savedData) as WarmNichesForm,
    });
  };

  const saveData = (data: WarmNichesForm) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, serializeData(data));
    dispatch({ type: ActionType.Save, payload: data });
  };

  const clearData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    dispatch({ type: ActionType.Clear });
  };

  const updateData = (data: Partial<WarmNichesForm>) => {
    dispatch({ type: ActionType.Update, payload: data });
  };

  const toggleClearModal = (payload: boolean) => {
    dispatch({ type: ActionType.ToggleClearModal, payload: payload });
  };

  const toggleDeleteModal = (isOpen: boolean, personName: string) => {
    dispatch({
      type: ActionType.ToggleDeleteModal,
      payload: { isOpen, personName },
    });
  }; // New action to toggle delete confirmation dialog

  return {
    state,
    actions: {
      load,
      saveData,
      clearData,
      updateData,
      toggleClearModal,
      toggleDeleteModal, // New action to toggle delete confirmation dialog
    },
  };
};
