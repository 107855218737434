const getMinMax = (digits: number) => {
  const min = 10 ** (digits - 1);
  const max = 10 ** digits - 1;
  return [min, max];
};

const getRandom = (digits: number) => {
  const [min, max] = getMinMax(digits);
  const randomNumberWithSpecifiedDigits = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumberWithSpecifiedDigits;
};

const parse = (numberAsString: string, decimals: number = 0) => {
  const parsedNumber = parseFloat(numberAsString);

  if (isNaN(parsedNumber)) {
    throw new Error(`Unable to parse value: ${numberAsString}`);
  }

  const roundedNumber = round(parsedNumber, decimals);

  return roundedNumber;
};

const round = (num: number, decimals: number = 0) => {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

const numberUtil = {
  getMinMax: getMinMax,
  random: getRandom,
  parse: parse,
  round: round,
  randomCurrency: (dollarsDigits: number, centsDigits: number) => {
    const dollarRandom = getRandom(dollarsDigits);
    const centsRandom = getRandom(centsDigits);

    return Number(`${dollarRandom}.${centsRandom}`);
  },
};

export default numberUtil;
