import { Match } from "api/paddle/paddleStorageModels";
import { type TeamStandings, TeamStandingsBase, TeamStandingsTeamBase } from "api/paddle/viewTeamStandingsModels";
import { useReducer } from "react";
import { jsonMember, jsonObject } from "typedjson";
import localPaddleStorageUtil from "utils/localPaddleStorageUtil";
import objectUtils from "utils/objectUtil";

export interface PaddleTeamStandingsActions {
  load: () => void;
  save: (data: TeamStandings) => void;
}

@jsonObject
export class PaddleTeamStandingsState {
  @jsonMember(TeamStandingsBase)
  teamStandings: TeamStandings = new TeamStandingsBase();
}

export enum ActionType {
  Load = "Load",
  Save = "Save",
}

type Action =
  | { type: ActionType.Load; payload: { teamStandings: TeamStandings } }
  | { type: ActionType.Save; payload: { teamStandings: TeamStandings } };

const reducer = (state: PaddleTeamStandingsState, action: Action): PaddleTeamStandingsState => {
  const newState = objectUtils.deepCopyTypedObject(PaddleTeamStandingsState, state);

  switch (action.type) {
    case ActionType.Load: {
      const { teamStandings } = action.payload;
      const newTeamStandings = TeamStandingsBase.create(teamStandings);
      newTeamStandings.teams = newTeamStandings.teams.sort((t1, t2) => t2.totalMatchPoints - t1.totalMatchPoints);
      newState.teamStandings = newTeamStandings;
      return newState;
    }
    case ActionType.Save: {
      const { teamStandings } = action.payload;
      const newTeamStandings = TeamStandingsBase.create(teamStandings);
      newState.teamStandings = newTeamStandings;
      return newState;
    }
    default:
      return state;
  }
};

export const usePaddleTeamStandingsStore = (): [PaddleTeamStandingsState, PaddleTeamStandingsActions] => {
  const [state, dispatch] = useReducer(reducer, new PaddleTeamStandingsState());

  const load = () => {
    const paddleClub = localPaddleStorageUtil.get();

    const pointsByTeam = paddleClub.matches.reduce((accumulator, match) => {
      const teamOneId = match.teamOne.teamId;
      const teamTwoId = match.teamTwo.teamId;

      // Group matches under teamOne
      if (!accumulator.has(teamOneId)) {
        accumulator.set(teamOneId, []);
      }
      accumulator.get(teamOneId)?.push(match);

      // Group matches under teamTwo
      if (!accumulator.has(teamTwoId)) {
        accumulator.set(teamTwoId, []);
      }
      accumulator.get(teamTwoId)?.push(match);

      return accumulator;
    }, new Map<string, Match[]>());

    const teamStandings = TeamStandingsBase.create({
      teams: paddleClub.teams.map((t) => {
        const matches = pointsByTeam.get(t.id) ?? [];
        const totalPoints = matches.reduce((accumulator, match) => {
          const { teamOne, teamTwo } = match;

          if (teamOne.teamId === t.id) {
            return accumulator + teamOne.points;
          }

          if (teamTwo.teamId === t.id) {
            return accumulator + teamTwo.points;
          }

          return accumulator;
        }, 0);

        return TeamStandingsTeamBase.create({
          id: t.id,
          teamName: t.name,
          totalMatchPoints: totalPoints,
        });
      }),
    });

    dispatch({
      type: ActionType.Load,
      payload: {
        teamStandings,
      },
    });
  };

  const save = (teamStandings: TeamStandings) => {
    dispatch({
      type: ActionType.Save,
      payload: {
        teamStandings,
      },
    });
  };

  return [state, { load, save }];
};
