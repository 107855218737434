import React, { useEffect } from "react";
import { WarmNichesFormPerson, useWarmNichesStore } from "./store/useWarmNichesStore";
import Spinner from "components/Spinner";
import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import { v4 } from "uuid";

const WarmNiches: React.FC = () => {
  const { state, actions } = useWarmNichesStore();
  const data = state.data;

  // Load data from local storage when the component mounts
  useEffect(() => {
    if (state.isLoading) {
      actions.load();
    }
  }, [actions, state.isLoading]);

  if (state.isLoading) {
    return <Spinner isLoading={state.isLoading} />;
  }

  const onTableChange = (updatedRecord: WarmNichesFormPerson) => {
    const updatedWarmNiches = [...data.people];
    const index = updatedWarmNiches.findIndex((wn) => wn.id === updatedRecord.id);

    if (index >= 0) {
      updatedWarmNiches[index] = updatedRecord;

      const updatedStateData = { ...state.data };
      updatedStateData.people = updatedWarmNiches;
      actions.saveData(updatedStateData);
    }
  };

  const onTableChangeAll = (updatedRecords: WarmNichesFormPerson[]) => {
    const updatedStateData = { ...state.data };
    updatedStateData.people = updatedRecords;
    actions.saveData(updatedStateData);
  };

  const table: AdvancedTableRecords<WarmNichesFormPerson> = {
    fields: [
      { id: "person", label: "Person" },
      { id: "hobby", label: "Hobby" },
      { id: "workExperience", label: "Work Experience" },
    ],
    data: [...data.people],
  };

  return (
    <AdvancedTable
      title="Warm Niches"
      table={table}
      orderByKey="person"
      createDefaultRecord={() => {
        const defaultWarmNichesFormPerson: WarmNichesFormPerson = {
          id: v4(),
          person: "",
          hobby: "",
          workExperience: "",
        };

        return defaultWarmNichesFormPerson;
      }}
      onChange={onTableChange}
      onChangeAll={onTableChangeAll}
      onDelete={(recordsToDelete) => {
        const newPeople = state.data.people.filter((p) => !recordsToDelete.some((r) => r.id === p.id));

        const newState = { ...state };
        newState.data.people = newPeople;
        actions.saveData(newState.data);
      }}
      isSearchMatch={(item, query) => {
        return item.person.toLowerCase().includes(query);
      }}
    />
  );
};

export default WarmNiches;
