export type LiquidField = {
  key: string;
  order: number;
  options:
    | { type: "string" }
    | { type: "number"; decimals: number }
    | { type: "date"; format: string }
    | { type: "conditional"; inputType: "string" | "number" | "boolean" };
  rawFieldTemplate: string;
};

const parseFields = (template: string): LiquidField[] => {
  const variableFields = parseVariableFields(template);
  const conditionalFields = parseConditionalFields(template);
  return [...variableFields, ...conditionalFields];
};

const parseVariableFields = (template: string): LiquidField[] => {
  const combinedRegex = /\{\{\s([^\s}]+)\s*(?:\|\s*([a-z]+)(?:\s*:\s*([^}]+))?)?\s\}\}/g;
  const matches = template.match(combinedRegex);

  if (!matches) {
    return [];
  }

  const parsedFields: LiquidField[] = [];

  let order = 0;

  for (const match of matches) {
    const filterRegex = /\s*([a-zA-Z0-9_]+)\s*(?:\|\s*([a-z]+)(?:\s*:\s*([^}]+))?)?\s*/;
    const fieldMatch = match.match(filterRegex);

    if (fieldMatch) {
      const [, fieldName, type, options] = fieldMatch;
      const parsedOptions: LiquidField = {
        key: fieldName.trim(),
        order: order++,
        options: {
          type: "string",
        },
        rawFieldTemplate: match,
      };

      if (type) {
        switch (type) {
          case "date":
            parsedOptions.options = { type: "date", format: options?.trim() || "%Y-%m-%d" };
            break;
          case "number":
            parsedOptions.options = { type: "number", decimals: parseInt(options?.trim()) || 0 };
            break;
          default:
            break;
        }
      }

      const existingFieldByKey = parsedFields.find(
        (pf) => pf.key === parsedOptions.key && pf.rawFieldTemplate === parsedOptions.rawFieldTemplate
      );

      if (!existingFieldByKey) {
        parsedFields.push(parsedOptions);
      }
    }
  }

  return parsedFields;
};

const parseConditionalFields = (template: string): LiquidField[] => {
  const conditionalRegex =
    /\{%\s*if\s+([a-zA-Z0-9]+)\s*[<>=!]+\s*("[^"]+"|'[^']+'|[0-9]+(?:\.[0-9]+)?|true|false)\s*%\}/g;
  const matches = template.match(conditionalRegex);

  if (!matches) {
    return [];
  }

  const parsedFields: LiquidField[] = [];

  let order = 0;

  for (const match of matches) {
    const conditionalMatch = match.match(
      /\{%\s*if\s+([a-zA-Z0-9]+)\s*[<>=!]+\s*("[^"]+"|'[^']+'|[0-9]+(?:\.[0-9]+)?|true|false)\s*%\}/
    );

    if (conditionalMatch) {
      const [, fieldName, value] = conditionalMatch;
      let inputType: "string" | "number" | "boolean" = "string";

      if (/^\d+$/.test(value) || /^\d+\.\d+$/.test(value)) {
        inputType = "number";
      } else if (value === '"true"' || value === '"false"') {
        inputType = "boolean";
      }

      const parsedOptions: LiquidField = {
        key: fieldName.trim(),
        order: order++,
        options: {
          type: "conditional",
          inputType,
        },
        rawFieldTemplate: match,
      };

      const existingFieldByKey = parsedFields.find(
        (pf) => pf.key === parsedOptions.key && pf.rawFieldTemplate === parsedOptions.rawFieldTemplate
      );

      if (!existingFieldByKey) {
        parsedFields.push(parsedOptions);
      }
    }
  }

  return parsedFields;
};

const liquidUtils = {
  parseFields,
};

export default liquidUtils;
