// usePeopleStore.tsx
import { CompanyForm, EmailTemplateForm, GlobalForm, PersonForm } from "api/models"; // Updated import
import { useEffect, useReducer } from "react";
import localStorageUtil from "utils/localStorageUtil";
import { SortOrder } from "utils/SortOrder";
import objectUtils from "utils/objectUtil";
import moment from "moment";
import stringUtils from "utils/stringUtils";

// Define action types
export enum ActionType {
  Load = "Load",
  LoadGlobal = "LoadGlobal",
  SyncedWithGlobal = "SyncedWithGlobal",
  SavePeople = "SavePeople", // Updated action type
  SavePerson = "SavePerson", // New action type
  UpdatePerson = "UpdatePerson", // Updated action type
  ToggleDeleteModal = "ToggleDeleteModal", // Updated action type
  ToggleEditModal = "ToggleEditModal", // Updated action type
  ToggleEmailModal = "ToggleEmailModal",
}

type GlobalPeopleState = {
  globalForm: GlobalForm;
  peopleState: PeopleState;
};

// Define action types and the initial state
type PeopleState = {
  people: PersonForm[]; // Updated type
  availableCompanies: CompanyForm[];
  availableEmailTemplates: EmailTemplateForm[];
  isInitiallyLoaded: boolean;
  isLoading: boolean;
  isDeleteModalOpen: boolean; // Updated property name
  isEditModalOpen: boolean; // Updated property name
  isEmailModalOpen: boolean;
  deletingPerson: PersonForm | undefined; // Updated property name
  editingPerson: PersonForm | undefined; // Updated property name and type
};

type Action =
  | {
      type: ActionType.Load;
      payload: {
        globalForm: GlobalForm;
        people: PersonForm[];
        companies: CompanyForm[];
        emailTemplates: EmailTemplateForm[];
      };
    }
  | { type: ActionType.LoadGlobal; payload: { globalForm: GlobalForm } }
  | { type: ActionType.SyncedWithGlobal; payload: boolean }
  | { type: ActionType.SavePeople; payload: PersonForm[] } // New action type
  | {
      type: ActionType.SavePerson; // New action type
      payload: PersonForm; // New payload for saving a single person
    }
  | {
      type: ActionType.UpdatePerson; // Updated action type
      payload: { id: string; person: Partial<PersonForm> }; // Updated payload
    }
  | {
      type: ActionType.ToggleDeleteModal; // Updated action type
      payload: { isOpen: boolean; person?: PersonForm }; // Updated payload name
    }
  | {
      type: ActionType.ToggleEditModal; // Updated action type
      payload: { isOpen: boolean; editingPerson?: PersonForm }; // Updated payload name and type
    }
  | {
      type: ActionType.ToggleEmailModal; // Updated action type
      payload: { isOpen: boolean; editingPerson?: PersonForm }; // Updated payload
    };

const linkedInShorthandRegex = /linkedin\.com\/in\/([^/]+)/;

const localUtils = {
  getLinkedInUrlShorthand: (linkedInUrl: string | undefined): string | undefined => {
    if (linkedInUrl && linkedInUrl?.trim() !== "") {
      const match = linkedInUrl.match(linkedInShorthandRegex);

      if (match && match[1]) {
        const companyName = match[1];
        return companyName;
      }
    }

    return undefined;
  },
  sortPeopleByName: (sortOrder: Exclude<SortOrder, "undefined">, people: PersonForm[]): PersonForm[] => {
    const copiedPeople = [...people];
    const sortedPeople = copiedPeople.sort((a, b) => {
      const valueA = (a.name as string) || ""; // Convert null or undefined to an empty string
      const valueB = (b.name as string) || ""; // Convert null or undefined to an empty string

      if (valueA === valueB) {
        return 0;
      } else if (valueA === "" || valueA == null) {
        return 1; // Place empty strings, null, or undefined values at the bottom
      } else if (valueB === "" || valueB == null) {
        return -1; // Place empty strings, null, or undefined values at the bottom
      } else {
        return valueA.localeCompare(valueB) * (sortOrder === "desc" ? -1 : 1);
      }
    });

    return [...sortedPeople];
  },
};

// Reducer function
const reducer = (state: GlobalPeopleState, action: Action): GlobalPeopleState => {
  const newState = objectUtils.deepCopyObject(state);

  switch (action.type) {
    case ActionType.Load: {
      const peopleWithAtLeastOne = action.payload.people;

      if (peopleWithAtLeastOne.length === 0) {
        peopleWithAtLeastOne.push({
          id: stringUtils.uuid(),
          name: "",
          age: undefined,
          companyId: "",
          jobTitle: "",
          linkedinUrl: "",
          emailAddress: "",
          phone: "",
          twitterHandle: "",
          notes: [],
          emails: [],
        });
      }

      for (var person of peopleWithAtLeastOne) {
        person.emails = person.emails === undefined ? [] : person.emails;
        person.created = person.created === undefined ? moment() : person.created;
        person.updated = person.updated === undefined ? moment() : person.updated;
      }

      newState.globalForm = action.payload.globalForm;

      const editingPerson = objectUtils.deepCopyObject(defaultEditingPerson);
      editingPerson.id = stringUtils.uuid();

      newState.peopleState = {
        people: peopleWithAtLeastOne,
        availableCompanies: action.payload.companies,
        availableEmailTemplates: action.payload.emailTemplates,
        isInitiallyLoaded: true,
        isLoading: false,
        isDeleteModalOpen: false,
        isEditModalOpen: false,
        isEmailModalOpen: false,
        deletingPerson: undefined,
        editingPerson: editingPerson,
      };

      return newState;
    }
    case ActionType.SavePeople: {
      newState.peopleState.people = [...action.payload];
      newState.peopleState.isDeleteModalOpen = false;
      newState.peopleState.isEditModalOpen = false;
      newState.peopleState.deletingPerson = undefined;
      return newState;
    }
    case ActionType.SavePerson: {
      const newPerson = { ...action.payload }; // Updated type
      newPerson.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(newPerson?.linkedinUrl);

      const existingPersonIndex = state.peopleState.people.findIndex(
        // Updated reference and type
        (person) => person.id === newPerson.id
      );

      let peopleToUpdate = [...state.peopleState.people]; // Updated reference

      if (existingPersonIndex !== -1) {
        // If the person with the same ID exists, replace it
        peopleToUpdate[existingPersonIndex] = newPerson;
      } else {
        // If the person with the same ID doesn't exist, add it as a new person
        peopleToUpdate.push(newPerson);
      }

      return {
        ...state,
        peopleState: {
          ...state.peopleState,
          isEditModalOpen: false, // Updated property name
          people: peopleToUpdate, // Updated property name
        },
      };
    }
    case ActionType.UpdatePerson: {
      const idToUpdate = action.payload.id; // Updated reference
      const partialPerson = action.payload.person; // Updated reference and type

      // Update editing person if it exists and matches
      if (state.peopleState.isEditModalOpen && state.peopleState.editingPerson?.id === idToUpdate) {
        // Updated property name and reference
        const updatedEditedPerson = {
          ...state.peopleState.editingPerson, // Updated property name and reference
          ...partialPerson,
        };
        updatedEditedPerson.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(updatedEditedPerson.linkedinUrl);

        return {
          ...state,
          peopleState: {
            ...state.peopleState,
            editingPerson: updatedEditedPerson, // Updated property name
          },
        };
      }

      // Update normal person array based on ID matching
      const updatedPeople = state.peopleState.people.map((person) => {
        // Updated reference and type
        if (person.id === idToUpdate) {
          const updatedPerson = { ...person, ...partialPerson }; // Updated reference and type
          updatedPerson.linkedinUrlShorthand = localUtils.getLinkedInUrlShorthand(updatedPerson.linkedinUrl);
          return updatedPerson;
        }
        return person;
      });

      return {
        ...state,
        peopleState: {
          ...state.peopleState,
          people: updatedPeople, // Updated property name
        },
      };
    }
    case ActionType.ToggleDeleteModal: {
      return {
        ...state,
        peopleState: {
          ...state.peopleState,
          isDeleteModalOpen: action.payload.isOpen, // Updated property name
          deletingPerson: action.payload.person, // Updated property name
        },
      };
    }
    case ActionType.ToggleEditModal: {
      const { isOpen, editingPerson } = action.payload;

      newState.peopleState.isEditModalOpen = isOpen;

      if (editingPerson !== undefined) {
        newState.peopleState.editingPerson = editingPerson;
      }

      return newState;
    }
    case ActionType.ToggleEmailModal: {
      const { isOpen, editingPerson } = action.payload;

      newState.peopleState.isEmailModalOpen = isOpen;

      if (editingPerson !== undefined) {
        newState.peopleState.editingPerson = editingPerson;
      }

      return newState;
    }
    default:
      return state;
  }
};

const defaultEditingPerson: PersonForm = {
  id: "",
  name: "",
  companyId: "",
  jobTitle: "",
  linkedinUrl: "",
  linkedinUrlShorthand: "",
  emailAddress: "",
  phone: "",
  twitterHandle: "",
  notes: [],
  emails: [],
};

const defaultGlobalPeopleState: GlobalPeopleState = {
  globalForm: {
    companies: {
      list: [],
    },
    people: {
      list: [],
    },
    emails: {
      list: [],
    },
    ideas: {
      list: [],
    },
  },
  peopleState: {
    people: [],
    availableCompanies: [],
    availableEmailTemplates: [],
    isInitiallyLoaded: false,
    isLoading: true,
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    isEmailModalOpen: false,
    deletingPerson: undefined,
    editingPerson: { ...defaultEditingPerson },
  },
};

interface PeopleActions {
  load: () => void;
  savePeople: (people: PersonForm[]) => void;
  savePerson: (person: PersonForm) => void;
  updatePerson: (id: string | undefined, person: Partial<PersonForm>) => void;
  toggleDeleteModal: (isOpen: boolean, person?: PersonForm) => void;
  toggleEditModal: (isOpen: boolean, editingPerson?: PersonForm) => void;
  toggleEmailModal: (isOpen: boolean, editingPerson?: PersonForm) => void;
}

export const usePeopleStore = (): [PeopleState, PeopleActions] => {
  // Initialize state using the reducer
  const [globalState, dispatch] = useReducer(reducer, defaultGlobalPeopleState);

  useEffect(() => {
    if (globalState.peopleState.isInitiallyLoaded) {
      const newGlobalForm = localStorageUtil.setPeople(globalState.globalForm, globalState.peopleState.people);

      dispatch({
        type: ActionType.LoadGlobal,
        payload: { globalForm: newGlobalForm },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.peopleState.people, globalState.peopleState.isInitiallyLoaded]);

  const load = () => {
    const globalForm = localStorageUtil.get();

    const people = globalForm.people?.list ?? [...defaultGlobalPeopleState.peopleState.people];
    const companies = globalForm.companies.list;
    const emailTemplates = globalForm.emails.list;

    dispatch({
      type: ActionType.Load,
      payload: {
        globalForm: globalForm,
        people: people,
        companies: companies,
        emailTemplates: emailTemplates,
      },
    });
  };

  const savePeople = (people: PersonForm[]) => {
    dispatch({ type: ActionType.SavePeople, payload: people });
  };

  const savePerson = (person: PersonForm) => {
    dispatch({
      type: ActionType.SavePerson,
      payload: person,
    });
  };

  const updatePerson = (id: string | undefined, person: Partial<PersonForm>) => {
    if (!id) {
      return;
    }

    dispatch({
      type: ActionType.UpdatePerson,
      payload: { id, person },
    });
  };

  const toggleDeleteModal = (isOpen: boolean, person?: PersonForm) => {
    dispatch({
      type: ActionType.ToggleDeleteModal,
      payload: { isOpen, person },
    });
  };

  const toggleEditModal = (isOpen: boolean, editingPerson?: PersonForm) => {
    dispatch({
      type: ActionType.ToggleEditModal,
      payload: {
        isOpen,
        editingPerson: editingPerson,
      },
    });
  };

  const toggleEmailModal = (isOpen: boolean, editingPerson?: PersonForm) => {
    dispatch({
      type: ActionType.ToggleEmailModal,
      payload: { isOpen, editingPerson },
    });
  };

  return [
    globalState.peopleState,
    {
      load,
      savePeople,
      savePerson,
      updatePerson,
      toggleDeleteModal,
      toggleEditModal,
      toggleEmailModal,
    },
  ];
};
