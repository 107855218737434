import { Settings, SettingsBase } from "api/settings";
import stringUtils from "./stringUtils";
import { TypedJSON } from "typedjson";

const settingsKey = "settings";

const get = (): Settings => {
  const settingsData = localStorage.getItem(settingsKey);

  if (settingsData && !stringUtils.isNullOrWhitespace(settingsData)) {
    try {
      const serializer = new TypedJSON(SettingsBase);
      const settings = serializer.parse(settingsData);

      if (settings) {
        return settings;
      }
    } catch (e) {
      console.log(e, e);
    }
  }

  const defaultSettings = SettingsBase.create({
    exportFileName: "CompanyAndPeopleData",
  });

  set(defaultSettings);
  return defaultSettings;
};

const set = (data: Settings): void => {
  const settingsBase = SettingsBase.create(data);
  const serializer = new TypedJSON(SettingsBase);
  localStorage.setItem(settingsKey, serializer.stringify(settingsBase));
};

const localSettingsStorageUtil = {
  get,
  set,
};

export default localSettingsStorageUtil;
