import { Container, ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import { ConfigProvider, Domain, environmentOrder, useConfig } from "components/Config";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { themeForProvider } from "config/theme/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

type DomainDocumentData = {
  [x in Domain]: {
    title: string;
    icon: string;
  };
};

const App: React.FC = () => {
  const LocalContainer = () => {
    const { config } = useConfig();

    const domainDocumentData: DomainDocumentData = {
      "fabledfounders.com": { title: "Fabled Founders", icon: "/favicon.ico" },
      "titanpaddle.com": { title: "Titan Paddle", icon: "/images/titanpaddle/paddle16.png" },
    };

    useEffectOnLoad(() => {
      const documentData = domainDocumentData[config.domain];
      document.title = documentData.title;

      // Dynamically change the favicon
      const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (favicon) {
        favicon.href = documentData.icon;
      }
    });

    const configIndex = environmentOrder.indexOf(config.environment);

    const { primaryRoutes, secondaryRoutes } = config.fullRoutes;
    const fullRoutes = [...primaryRoutes, ...secondaryRoutes];

    const routesForEnv = fullRoutes.filter((r) => {
      const highestEnvIndex = environmentOrder.indexOf(r.highestEnvironment);
      return highestEnvIndex >= configIndex;
    });

    return (
      <Container sx={{ pt: "1em" }}>
        <Routes>
          {routesForEnv.map((r) => {
            return <Route key={r.path} path={r.path} element={r.element} />;
          })}
        </Routes>
      </Container>
    );
  };

  return (
    <ConfigProvider>
      <ThemeProvider theme={themeForProvider}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router>
            <div style={{ paddingBottom: "48px" }}>
              <Navigation />
              <LocalContainer />
            </div>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default App;
