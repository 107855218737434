import { IdAble } from "api/models";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";

// Interfaces

export interface PaddlePlayersAvailableTeam {
  teamId: string;
  displayName: string;
}

export interface PaddlePlayersAvailablePlayer {
  playerId: string;
  teamId: string;
  displayName: string;
}

export interface PaddlePlayersPlayer extends IdAble {
  id: string;
  teamId: string;
  teamName: string;
  playerId: string;
  playerFirstName: string;
  playerLastName: string;
  playerNickname: string;
  playerFullName: string;
  matchesPlayed: number;
  matchPoints: number;
  pointsPerMatch: number;
}

export interface PaddlePlayers {
  availableTeamSelection: PaddlePlayersAvailableTeam[];
  availablePlayerSelection: PaddlePlayersAvailablePlayer[];
  players: PaddlePlayersPlayer[];
}

// Classes

@jsonObject
export class PaddlePlayersAvailableTeamBase implements PaddlePlayersAvailableTeam {
  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public displayName: string = "";

  static create(newObject: PaddlePlayersAvailableTeam): PaddlePlayersAvailableTeamBase {
    return objectUtils.deepCopyTypedObject(PaddlePlayersAvailableTeamBase, newObject);
  }
}

@jsonObject
export class PaddlePlayersAvailablePlayerBase implements PaddlePlayersAvailablePlayer {
  @jsonMember(String)
  public playerId: string = "";

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public displayName: string = "";

  static create(newObject: PaddlePlayersAvailablePlayer): PaddlePlayersAvailablePlayerBase {
    return objectUtils.deepCopyTypedObject(PaddlePlayersAvailablePlayerBase, newObject);
  }
}

@jsonObject
export class PaddlePlayersPlayerBase implements PaddlePlayersPlayer {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public teamName: string = "";

  @jsonMember(String)
  public playerId: string = "";

  @jsonMember(String)
  public playerFirstName: string = "";

  @jsonMember(String)
  public playerLastName: string = "";

  @jsonMember(String)
  public playerNickname: string = "";

  @jsonMember(String)
  public playerFullName: string = "";

  @jsonMember(Number)
  public matchesPlayed: number = 0;

  @jsonMember(Number)
  public matchPoints: number = 0;

  @jsonMember(Number)
  public pointsPerMatch: number = 0;

  static create(newObject: PaddlePlayersPlayer): PaddlePlayersPlayerBase {
    return objectUtils.deepCopyTypedObject(PaddlePlayersPlayerBase, newObject);
  }
}

@jsonObject
export class PaddlePlayersBase implements PaddlePlayers {
  @jsonArrayMember(PaddlePlayersAvailableTeamBase)
  public availableTeamSelection: PaddlePlayersAvailableTeamBase[] = [];

  @jsonArrayMember(PaddlePlayersAvailablePlayerBase)
  public availablePlayerSelection: PaddlePlayersAvailablePlayerBase[] = [];

  @jsonArrayMember(PaddlePlayersPlayerBase)
  public players: PaddlePlayersPlayerBase[] = [];

  static create(newObject: PaddlePlayers): PaddlePlayersBase {
    return objectUtils.deepCopyTypedObject(PaddlePlayersBase, newObject);
  }
}
