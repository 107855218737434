import { SxProps, Theme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import { IdAble } from "api/models";
import { useEffect, useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import CustomIcon, { CustomIconProps, SupportedIcons } from "./CustomIcon";
import stringUtils from "utils/stringUtils";
import listUtils from "utils/listUtils";
import moment from "moment";
import Toggle from "./Toggle";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order, field: HeadCell<T> | undefined) {
  let aValue: any = a[orderBy];
  let bValue: any = b[orderBy];

  if (field?.custom) {
    const aCustomValue = field.custom(a);

    if (typeof aCustomValue === "number" || typeof aCustomValue === "string") {
      aValue = aCustomValue;
      bValue = field.custom(b);
    }
  }

  const orderDirection = order === "desc" ? -1 : 1;

  if (stringUtils.isNullOrWhitespace(aValue)) {
    return -1 * orderDirection;
  }

  if (stringUtils.isNullOrWhitespace(bValue)) {
    return 1 * orderDirection;
  }

  if (typeof aValue === "number" && typeof bValue === "number") {
    const compareValue = (aValue - bValue) * orderDirection;
    return compareValue;
  }

  return listUtils.sortByStringComparison(aValue, bValue, order);
}

type Order = "asc" | "desc";

function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key,
  fields: HeadCell<T>[]
): (a: T, b: T) => number {
  const matchingField = fields.find((f) => f.id === orderBy);
  return (a, b) => descendingComparator(a, b, orderBy, order, matchingField);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<U>(array: readonly U[], comparator: (a: U, b: U) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [U, number]);
  stabilizedThis.sort((a, b) => {
    // Compare non-null values using the provided comparator
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const stickyStyling = (isSticky: boolean, isHeader?: boolean): SxProps<Theme> | undefined => {
  if (!isSticky) {
    return undefined;
  }

  return {
    position: "sticky",
    left: 0,
    zIndex: isHeader ? 900 : 100,
    backgroundColor: "#fff",
  };
};
export interface HeadCell<T> {
  id: keyof T;
  label: string;
  createInputLabel?: (item: T) => string;
  custom?: (item: T) => any;
  readOnly?: boolean;
  isNumeric?: boolean;
  isWebsite?: boolean;
  websiteOptions?: {
    url?: string;
    customUrl?: (item: T) => { url: string; icon: SupportedIcons; isDisabled?: boolean };
  };
  customIcon?: (item: T) => {
    icon: SupportedIcons;
    tooltip: string;
    onClick: () => void;
  };
  width?: string;
  isSticky?: boolean;
  dateFormat?: string;
}

interface EnhancedTableProps<T> {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string | number | symbol;
  rowCount: number;
  headCells: HeadCell<T>[];
  isSelectable: boolean;
  actions?: AdvancedTableRecordsAction<T>[];
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, isSelectable, actions } =
    props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isSelectable ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : (
          <></>
        )}
        {headCells.map((headCell) => (
          <TableCell
            id={`head-${headCell.id.toString()}`}
            key={`head-${headCell.id.toString()}`}
            style={{ fontWeight: "bold" }}
            align={headCell.isNumeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={stickyStyling(headCell.isSticky === true, true)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions ? (
          <TableCell id="head-actions" key="head-actions" align="center" style={{ fontWeight: "bold" }}>
            Quick Actions
          </TableCell>
        ) : (
          <></>
        )}
      </TableRow>
    </TableHead>
  );
}

function sort<T>(
  items: T[],
  order: Order,
  orderBy: keyof T,
  page: number,
  rowsPerPage: number,
  fields: HeadCell<T>[]
): T[] {
  let sortedItems = stableSort(items as any, getComparator(order, orderBy, fields));

  if (rowsPerPage <= 0) {
    return sortedItems;
  }

  return sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) as T[];
}

export interface AdvancedTableProps<T> {
  title: string;
  table: AdvancedTableRecords<T>;
  createDefaultRecord?: () => T;
  orderByKey: keyof T;
  initialSortOrder?: Order;
  onChange: (item: T) => void;
  onDelete: (items: T[]) => void;
  onChangeAll: (items: T[]) => void;
  isSearchMatch?: (item: T, query: string) => boolean;
  disableOnEnter?: true;
  itemsPerPage?: number;
  hidePagination?: true;
  hideHeader?: true;
  minWidthPx?: number;
  getDuplicates?: (items: T[]) => T[];
}

export interface AdvancedTableRecordsAction<T> {
  icon: SupportedIcons;
  onClick: (item: T) => void;
  tooltip?: string;
  tooltipFollowMouse?: boolean;
  isDisabled?: (item: T) => boolean;
  getBadge?: (item: T) => string | boolean | undefined;
  onSelectOnly?: boolean;
}

export interface AdvancedTableRecords<T> {
  fields: HeadCell<T>[];
  readonly data: T[];
  actions?: AdvancedTableRecordsAction<T>[];
}

interface DuplicateDetection<T> {
  hasDetection: boolean;
  showDupesOnly: boolean;
  duplicates: T[];
}

export function AdvancedTable<T extends IdAble>(props: AdvancedTableProps<T>) {
  const {
    title,
    table,
    orderByKey,
    initialSortOrder,
    createDefaultRecord,
    onChange,
    onDelete,
    onChangeAll,
    isSearchMatch,
    disableOnEnter,
    itemsPerPage,
    hidePagination,
    hideHeader,
    minWidthPx,
    getDuplicates,
  } = props;
  const { fields, data, actions } = table;
  const primaryFocusKey = fields[0].id;
  const minWidth = minWidthPx !== undefined ? `${minWidthPx.toString()}px` : "750px";

  const canCreateRecords = !!createDefaultRecord;
  const overrideCreateDefaultRecord: () => T = (): T => {
    return {} as any as T;
  };
  const defaultRecordCreator = createDefaultRecord ?? overrideCreateDefaultRecord;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage ?? -1);
  const [order, setOrder] = useState<Order>(initialSortOrder ? initialSortOrder : "asc");
  const [orderBy, setOrderBy] = useState<keyof T>(orderByKey);
  const [isSelectable, setIsSelectable] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [dense] = useState(true);
  const [tableData, setTableData] = useState<T[]>(sort([...data], order, orderBy, page, rowsPerPage, fields));
  const [editableRow, setEditableRow] = useState<T>(defaultRecordCreator);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [focusKey, setFocusKey] = useState<keyof T>(primaryFocusKey);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<T[]>([...data]);
  const [duplicates, setDuplicates] = useState<DuplicateDetection<T>>({
    hasDetection: getDuplicates !== undefined,
    showDupesOnly: false,
    duplicates: getDuplicates ? getDuplicates(data) : [],
  });

  const focusNextInput = (index: number, overrideKey?: keyof T) => {
    const keyToFocus = overrideKey !== undefined ? overrideKey : focusKey;
    const nameInput = document.getElementById(`${keyToFocus.toString()}-${index}`);
    if (nameInput) {
      nameInput.focus();
    }
  };

  const detectDuplicates = (newData: T[]) => {
    if (duplicates.hasDetection && getDuplicates) {
      const newDuplicates = getDuplicates(newData);

      setDuplicates({
        hasDetection: duplicates.hasDetection,
        showDupesOnly: duplicates.showDupesOnly,
        duplicates: newDuplicates,
      });
    }
  };

  useEffect(() => {
    setTableData([...data]);
    detectDuplicates(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder: Order = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    onChangeAll(sort([...tableData], newOrder, property, page, rowsPerPage, fields));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isSelectable) {
      return;
    }

    if (event.target.checked) {
      const newSelected = tableData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRefreshClick = () => {
    const sortedData = sort([...tableData], order, orderBy, page, rowsPerPage, fields);
    onChangeAll(sortedData);
  };

  const handleClick = (name: string) => {
    if (!isSelectable) {
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setPage(0);
  };

  const isSelected = (id: string) => {
    if (!isSelectable) {
      return false;
    }

    return selected.indexOf(id) !== -1;
  };

  const handleOnCreate = () => {
    const newRecord = defaultRecordCreator();
    const newTableData = [...tableData];
    newTableData.push(newRecord);
    onChangeAll(newTableData);

    if (rowsPerPage <= 0) {
      return;
    }

    const totalRecords = newTableData.length;
    const lastPageNumber = totalRecords <= rowsPerPage ? 0 : Math.floor((totalRecords - 1) / rowsPerPage); // Calculate the last page number as 0-based

    // Calculate newIndex based on whether totalRecords is a multiple of rowsPerPage
    const isMultipleOfRowsPerPage = totalRecords % rowsPerPage === 0;
    const newIndex = isMultipleOfRowsPerPage ? rowsPerPage : totalRecords % rowsPerPage;

    if (page === lastPageNumber) {
      setTimeout(() => {
        focusNextInput(newIndex - 1, primaryFocusKey);
      }, 10);
    } else {
      setTimeout(() => {
        setTimeout(() => {
          focusNextInput(newIndex - 1, primaryFocusKey);
        }, 50);
        setPage(lastPageNumber); // Increment the page by 1 to match 1-based representation
      }, 50);
    }
  };

  const filterDataBySearchOrDupes = (searchQuery: string, dupeDetection: DuplicateDetection<T>) => {
    const dupesOrData = dupeDetection.showDupesOnly ? dupeDetection.duplicates : data;

    const filtered = dupesOrData.filter((item) => {
      if (isSearchMatch) {
        return isSearchMatch(item, searchQuery);
      }

      return false;
    });

    setFilteredData(filtered);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    filterDataBySearchOrDupes(query, duplicates);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const onChangeRecord = (row: T, field: keyof T, value: string) => {
    const updatedTableData = [...tableData];
    const indexToUpdate = updatedTableData.findIndex((d) => d.id === row.id);

    if (indexToUpdate >= 0) {
      const rowToUpdate = updatedTableData[indexToUpdate];

      if ((rowToUpdate as any)[field] === value) {
        return;
      }

      (rowToUpdate as any)[field] = value;
      onChange(rowToUpdate);

      detectDuplicates(updatedTableData);
    }
  };

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (disableOnEnter) {
      return;
    }

    const smallerLength = Math.min(tableData.length, rowsPerPage);

    if (e.key === "Enter") {
      e.preventDefault();

      const isShiftPressed = e.shiftKey;
      const nextIndex = isShiftPressed ? index - 1 : index + 1;

      if (nextIndex >= 0 && nextIndex < smallerLength) {
        focusNextInput(nextIndex);
      } else if (!isShiftPressed) {
        handleOnCreate();
      }
    }
  };

  const onArrow = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    const smallerLength = Math.min(tableData.length, rowsPerPage);

    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (e.ctrlKey) {
        // Ctrl + Up Arrow: Focus the name field of the first business
        focusNextInput(0);
      } else {
        // Up Arrow: Focus the name field of the previous business or cycle to the last business
        const previousIndex = index === 0 ? smallerLength - 1 : index - 1;
        focusNextInput(previousIndex);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (e.ctrlKey) {
        // Ctrl + Down Arrow: Focus the name field of the last business
        focusNextInput(smallerLength - 1);
      } else {
        // Down Arrow: Focus the name field of the next business or cycle to the first business
        const nextIndex = index === smallerLength - 1 ? 0 : index + 1;
        focusNextInput(nextIndex);
      }
    }
  };

  const additionalCells = (props: { row: T; fields: HeadCell<T>[]; rowIndex: number; isDisabled: boolean }) => {
    const { row, fields, rowIndex, isDisabled } = props;
    const rowId = row.id;

    return (
      <>
        {fields.map((f) => {
          const value = (row as any)[f.id];
          const key = f.id.toString() + "-" + rowIndex;

          const cellStyle = f.width ? { width: f.width } : undefined;

          const { isWebsite, websiteOptions, customIcon, createInputLabel, isSticky, dateFormat } = f;
          const hasCustomIcon = !(!isWebsite && !websiteOptions && !customIcon);

          const createCustomLinkIcon = (item: T) => {
            if (!hasCustomIcon) {
              return <></>;
            }

            const tooltipOptions: CustomIconProps["tooltipOptions"] = {
              position: "top",
              followMouse: true,
            };

            if (customIcon) {
              const { icon, onClick, tooltip } = customIcon(item);

              const supportedIcon: SupportedIcons =
                icon === "personAdd" ? "personAdd" : icon === "search" ? "search" : "openInNew";

              return (
                <CustomIcon
                  icon={supportedIcon}
                  onClick={onClick}
                  tooltip={tooltip}
                  tooltipOptions={{ ...tooltipOptions }}
                />
              );
            }

            const linkValue = item[f.id]?.toString() ?? "";

            let openInNewTab = (
              <a
                href={encodeURI(linkValue)}
                onClick={(e) => (linkValue ? null : e.preventDefault())}
                tabIndex={linkValue ? undefined : -1}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: "8px", textDecoration: "none" }}
              >
                <CustomIcon
                  icon="openInNew"
                  isDisabled={!!linkValue}
                  tooltip="Open website"
                  tooltipOptions={{ position: "top", followMouse: true }}
                />
              </a>
            );

            if (websiteOptions && websiteOptions.customUrl) {
              const customUrl = websiteOptions.customUrl(item);
              const { url, icon, isDisabled } = customUrl;

              if (icon === "search") {
                return (
                  <a
                    href={encodeURI(url)}
                    onClick={(e) => (!isDisabled ? null : e.preventDefault())}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "8px", textDecoration: "none" }}
                  >
                    <CustomIcon
                      icon="search"
                      isDisabled={isDisabled}
                      tooltip="Search for website"
                      tooltipOptions={{
                        position: "top",
                        followMouse: true,
                      }}
                    />
                  </a>
                );
              }

              if (icon === "openInNew") {
                return openInNewTab;
              }
            }

            return openInNewTab;
          };

          if (f.custom) {
            return (
              <TableCell key={`tableCell-${key}`} style={cellStyle}>
                {f.custom(row)}
              </TableCell>
            );
          }

          if (f.readOnly) {
            const renderableDateFormat = stringUtils.isNullOrWhitespace(dateFormat) ? "M/DYYYY" : dateFormat;
            const renderableValue = moment.isMoment(value) ? value.format(renderableDateFormat) : value;

            if (hasCustomIcon) {
              return (
                <TableCell key={`tableCell-${key}`} style={cellStyle} sx={stickyStyling(isSticky === true)}>
                  {renderableValue}
                  {createCustomLinkIcon(row)}
                </TableCell>
              );
            }

            return (
              <TableCell key={`tableCell-${key}`} style={cellStyle} sx={stickyStyling(isSticky === true)}>
                {renderableValue}
              </TableCell>
            );
          }

          return (
            <TableCell key={`tableCell-${key}`} style={cellStyle} sx={stickyStyling(isSticky === true)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {editableRow && editableRow.id === rowId ? (
                  <>
                    <TextField
                      id={key}
                      key={`textField-${key}`}
                      label={createInputLabel && createInputLabel(editableRow)}
                      style={{ width: "100%" }}
                      sx={{ cursor: isDisabled ? "pointer" : undefined }}
                      size="small"
                      value={editableRow[f.id]}
                      autoComplete="off"
                      disabled={isDisabled}
                      onChange={(e) => {
                        const newEditableRow = { ...editableRow };
                        (newEditableRow as any)[f.id] = e.target.value;
                        setEditableRow(newEditableRow);
                      }}
                      onBlur={(e) => onChangeRecord(row, f.id, e.target.value)}
                      onKeyUp={(e) => onEnter(e, rowIndex)}
                      onKeyDown={(e) => onArrow(e, rowIndex)}
                      onFocus={() => setFocusKey(f.id)}
                    />
                    {createCustomLinkIcon(editableRow)}
                  </>
                ) : (
                  <>
                    <TextField
                      id={key}
                      key={`textField-${key}`}
                      label={createInputLabel && createInputLabel(row)}
                      style={{ width: "100%" }}
                      sx={{ cursor: isDisabled ? "pointer" : undefined }}
                      size="small"
                      value={value}
                      autoComplete="off"
                      disabled={isDisabled}
                      onChange={(e) => onChangeRecord(row, f.id, e.target.value)}
                      onFocus={() => {
                        setFocusKey(f.id);
                        setEditableRow(row);
                      }}
                    />
                    {createCustomLinkIcon(row)}
                  </>
                )}
              </div>
            </TableCell>
          );
        })}
        {actions ? (
          <TableCell sx={{ display: "flex", justifyContent: "center" }}>
            {actions
              .filter((a) => {
                const { onSelectOnly } = a;

                const isSelectableAction = onSelectOnly === true;
                return isSelectableAction === isSelectable;
              })
              .map((a) => {
                const { icon, onClick, tooltip, tooltipFollowMouse, isDisabled, getBadge } = a;
                const disabled = isDisabled === undefined ? false : isDisabled(row);
                const finalTooltip = disabled ? undefined : tooltip;

                let badge: string | boolean | undefined = undefined;
                if (getBadge) {
                  badge = getBadge(row);
                }

                return (
                  <CustomIcon
                    key={`action-${icon}`}
                    icon={icon}
                    onClick={() => onClick && onClick(row)}
                    tooltip={finalTooltip}
                    tooltipOptions={{
                      position: tooltipFollowMouse !== undefined && tooltipFollowMouse ? "top" : undefined,
                      followMouse: tooltipFollowMouse,
                    }}
                    isDisabled={disabled}
                    badge={badge}
                  />
                );
              })}
          </TableCell>
        ) : (
          <></>
        )}
      </>
    );
  };

  const numSelected = selected.length;
  const onChangeIsSelectable = () => {
    setIsSelectable(!isSelectable);
    setSelected([]);
  };

  const tableDataToRender =
    stringUtils.isNullOrWhitespace(searchQuery) && !duplicates.showDupesOnly ? tableData : filteredData;
  let rowsPerPageOptions: (
    | number
    | {
        value: number;
        label: string;
      }
  )[] = [];

  const reservedRowsPerPage = [5, 10, 25];
  if (rowsPerPage > 0 && rowsPerPage < 5 && !reservedRowsPerPage.includes(rowsPerPage)) {
    rowsPerPageOptions.push(rowsPerPage);
  }

  rowsPerPageOptions.push(5);

  if (rowsPerPage > 0 && rowsPerPage < 10 && !reservedRowsPerPage.includes(rowsPerPage)) {
    rowsPerPageOptions.push(rowsPerPage);
  }

  rowsPerPageOptions.push(10);

  if (rowsPerPage > 0 && rowsPerPage < 25 && !reservedRowsPerPage.includes(rowsPerPage)) {
    rowsPerPageOptions.push(rowsPerPage);
  }

  rowsPerPageOptions.push(25);

  if (rowsPerPage > 0 && rowsPerPage > 25 && !reservedRowsPerPage.includes(rowsPerPage)) {
    rowsPerPageOptions.push(rowsPerPage);
  }

  rowsPerPageOptions.push({ label: "all", value: -1 });

  const DupeDetectionSwitch = () => {
    console.log("duplicates", duplicates);
    if (!duplicates.hasDetection || duplicates.duplicates.length <= 0) {
      return <></>;
    }

    return (
      <Toggle
        label="Dupes only?"
        isChecked={duplicates.showDupesOnly}
        onChange={() => {
          console.log("On Dupe Switch");

          const newDuplicates = { ...duplicates, showDupesOnly: !duplicates.showDupesOnly };
          setDuplicates(newDuplicates);
          filterDataBySearchOrDupes(searchQuery, newDuplicates);
        }}
        minWidthPx={160}
      />
    );
  };

  return (
    <div style={{ paddingTop: "12px" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, pt: 1, overflow: "hidden" }}>
          {hideHeader ? (
            <></>
          ) : (
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
              }}
            >
              <CustomIcon icon="checklist" onClick={() => onChangeIsSelectable()} tooltip="Select items" />
              {numSelected > 0 ? (
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                  {numSelected} selected
                </Typography>
              ) : (
                <>
                  <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                    {title}
                  </Typography>
                  {isSearchMatch ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DupeDetectionSwitch />
                      <FormControl sx={{ m: 1, minWidth: "250px;" }} variant="outlined">
                        <InputLabel htmlFor="search-input">Search</InputLabel>
                        <OutlinedInput
                          id="search-input"
                          label="Search"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          endAdornment={
                            searchQuery ? (
                              <InputAdornment position="end">
                                <CustomIcon icon="clear" onClick={() => setSearchQuery("")} />
                              </InputAdornment>
                            ) : (
                              <></>
                            )
                          }
                        />
                      </FormControl>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {numSelected > 0 ? (
                <CustomIcon icon="delete" onClick={() => setIsDeleteModalOpen(true)} tooltip="Delete" />
              ) : (
                <>
                  {canCreateRecords ? (
                    <>
                      <CustomIcon icon="refresh" onClick={handleRefreshClick} tooltip="Refresh" />
                      <CustomIcon icon="add" onClick={handleOnCreate} tooltip="Add new" />
                      <CustomIcon icon="filterList" tooltip="Filter list" />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Toolbar>
          )}
          <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
            <Table
              sx={{ minWidth: minWidth }}
              className="customAdvancedTable"
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
                headCells={fields}
                isSelectable={isSelectable}
                actions={actions}
              />
              <TableBody>
                {(rowsPerPage <= 0
                  ? tableDataToRender
                  : tableDataToRender.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (isSelectable) {
                    return (
                      <TableRow
                        hover
                        id={`tableRow-${row.id}`}
                        key={`tableRow-${row.id}`}
                        onClick={() => handleClick(row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {additionalCells({ row, fields, rowIndex: index, isDisabled: true })}
                      </TableRow>
                    );
                  }

                  return (
                    <TableRow
                      hover
                      id={`tableRow-${row.id}`}
                      key={`tableRow-${row.id}`}
                      tabIndex={-1}
                      sx={{ cursor: "pointer" }}
                    >
                      {additionalCells({ row, fields, rowIndex: index, isDisabled: false })}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {hidePagination ? (
            <></>
          ) : (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          const items = tableData.filter((d) => selected.includes(d.id));
          onDelete(items);
          setSelected([]);
          setIsSelectable(false);
          setIsDeleteModalOpen(false);
        }}
        title={`Delete ${title}?`}
        message={`Are you sure you want to delete ${selected.length} records?`}
      />
    </div>
  );
}
