import moment from "moment";
import type { Moment } from "moment";
import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import objectUtils from "utils/objectUtil";
import { typedJsonUtils } from "utils/typedJsonUtils";

const momentJsonOptions = typedJsonUtils.momentOptions("YYYY-MM-DD");

// Interfaces

export interface Team {
  id: string;
  name: string;
  captainPlayerId: string;
  createdById: string;
  createdDate: Moment;
  modifiedById: string;
  modifiedDate: Moment;
}

export interface Player {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  nickname: string;
  email: string;
  secondaryEmail?: string | undefined;
  teamId: string;
  createdById: string;
  createdDate: Moment;
  modifiedById: string;
  modifiedDate: Moment;
}

export interface MatchTeam {
  teamId: string;
  playerOneId: string;
  playerTwoId: string;
  points: number;
}

export interface MatchSet {
  teamOne: number;
  teamTwo: number;
}

export interface Match {
  id: string;
  matchDate: Moment;
  createdById: string;
  createdDate: Moment;
  modifiedById: string;
  modifiedDate: Moment;
  teamOne: MatchTeam;
  teamTwo: MatchTeam;
  setOne: MatchSet;
  setTwo: MatchSet;
  setThree?: MatchSet | undefined;
}

export interface PaddleClub {
  teams: TeamBase[];
  players: PlayerBase[];
  matches: MatchBase[];
}

// Classes

@jsonObject
export class TeamBase implements Team {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public name: string = "";

  @jsonMember(String)
  public captainPlayerId: string = "";

  @jsonMember(String)
  public createdById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public createdDate: Moment = moment();

  @jsonMember(String)
  public modifiedById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public modifiedDate: Moment = moment();

  static create(newObject: Team): TeamBase {
    return objectUtils.deepCopyTypedObject(TeamBase, newObject);
  }
}

@jsonObject
export class PlayerBase implements Player {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(String)
  public firstName: string = "";

  @jsonMember(String)
  public lastName: string = "";

  @jsonMember(String)
  public nickname: string = "";

  @jsonMember(String)
  public fullName: string = "";

  @jsonMember(String)
  public email: string = "";

  @jsonMember(String)
  public secondaryEmail?: string | undefined = undefined;

  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public createdById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public createdDate: Moment = moment();

  @jsonMember(String)
  public modifiedById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public modifiedDate: Moment = moment();

  static create(newObject: Player): PlayerBase {
    return objectUtils.deepCopyTypedObject(PlayerBase, newObject);
  }
}

@jsonObject
export class MatchTeamBase implements MatchTeam {
  @jsonMember(String)
  public teamId: string = "";

  @jsonMember(String)
  public playerOneId: string = "";

  @jsonMember(String)
  public playerTwoId: string = "";

  @jsonMember(Number)
  public points: number = 0;

  static create(newObject: MatchTeam): MatchTeamBase {
    return objectUtils.deepCopyTypedObject(MatchTeamBase, newObject);
  }
}

@jsonObject
export class MatchSetBase implements MatchSet {
  @jsonMember(Number)
  public teamOne: number = 0;

  @jsonMember(Number)
  public teamTwo: number = 0;

  static create(newObject: MatchSet): MatchSetBase {
    return objectUtils.deepCopyTypedObject(MatchSetBase, newObject);
  }
}

@jsonObject
export class MatchBase implements Match {
  @jsonMember(String)
  public id: string = "";

  @jsonMember(moment, momentJsonOptions)
  public matchDate: Moment = moment();

  @jsonMember(String)
  public createdById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public createdDate: Moment = moment();

  @jsonMember(String)
  public modifiedById: string = "";

  @jsonMember(moment, momentJsonOptions)
  public modifiedDate: Moment = moment();

  @jsonMember(MatchTeamBase)
  public teamOne: MatchTeam = new MatchTeamBase();

  @jsonMember(MatchTeamBase)
  public teamTwo: MatchTeam = new MatchTeamBase();

  @jsonMember(MatchSetBase)
  public setOne: MatchSet = new MatchSetBase();

  @jsonMember(MatchSetBase)
  public setTwo: MatchSet = new MatchSetBase();

  @jsonMember(MatchSetBase)
  public setThree?: MatchSet | undefined;

  static create(newObject: Match): MatchBase {
    return objectUtils.deepCopyTypedObject(MatchBase, newObject);
  }
}

@jsonObject
export class PaddleClubBase implements PaddleClub {
  @jsonArrayMember(TeamBase)
  public teams: TeamBase[] = [];

  @jsonArrayMember(PlayerBase)
  public players: PlayerBase[] = [];

  @jsonArrayMember(MatchBase)
  public matches: MatchBase[] = [];

  @jsonMember(moment, momentJsonOptions)
  public createdDate: Moment = moment();

  @jsonMember(moment, momentJsonOptions)
  public modifiedDate: Moment = moment();

  static create(newObject: PaddleClub): PaddleClubBase {
    return objectUtils.deepCopyTypedObject(PaddleClubBase, newObject);
  }
}
