import { useState } from "react";
import { Typography } from "@mui/material";
import InformationDialog from "components/InformationDialog";
import { useNavigate } from "react-router-dom";
import PhaseCard from "./PhaseCard";

interface Phase3CardProps {
  isActive: boolean;
}

const Phase3Card: React.FC<Phase3CardProps> = ({ isActive }) => {
  const [isRefDialogOpen, setIsRefDialogOpen] = useState(false);

  const navigateTo = useNavigate();

  const navigateToPhase = (route: string) => {
    navigateTo(route);
  };

  return (
    <PhaseCard
      title="Phase 3"
      actionButtonTitle="More about Phase 3"
      onActionClick={() => navigateToPhase("/phase3")}
      onInfoClick={() => setIsRefDialogOpen(true)}
      isActive={isActive}
    >
      <Typography variant="body2" color="text.secondary">
        This is custom content for Phase 1.
      </Typography>
      <InformationDialog
        open={isRefDialogOpen}
        onClose={() => setIsRefDialogOpen(false)}
        title="Ref Confirmation"
        message={`Show references?`}
      />
    </PhaseCard>
  );
};

export default Phase3Card;
