// ConfigContext.tsx
import { createContext, PropsWithChildren, useContext, useState } from "react";
import Spinner from "./Spinner";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { FullRoute, SiteRoutes } from "MainRoutes";

type ConfigContextType = {
  config: Config;
  loading: boolean;
};

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

const domains = ["fabledfounders.com", "titanpaddle.com"] as const;

export type Domain = (typeof domains)[number];

export interface FullRoutes {
  primaryRoutes: FullRoute[];
  secondaryRoutes: FullRoute[];
}

export interface ConfigFeatureFlags {
  createMatch: boolean;
}

export interface Config {
  environment: EnvironmentName;
  domain: Domain;
  fullRoutes: FullRoutes;
  email: string;
  featureFlags: ConfigFeatureFlags;
}

export type EnvironmentName = "unknown" | "local" | "test" | "prod";

export const environmentOrder: EnvironmentName[] = ["unknown", "local", "test", "prod"];

export const ConfigProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const [config, setConfig] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffectOnLoad(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const data = await response.json();

        const configData: Config = {
          environment: "unknown",
          domain: "fabledfounders.com",
          fullRoutes: {
            primaryRoutes: [],
            secondaryRoutes: [],
          },
          email: "",
          featureFlags: {
            createMatch: false,
          },
        };

        if (data && typeof data === "object") {
          if ("environment" in data && data.environment) {
            configData.environment = data.environment;
          }

          if ("featureFlags" in data && data.featureFlags) {
            const featureFlags = data.featureFlags;

            if (
              "createMatch" in featureFlags &&
              featureFlags.createMatch &&
              typeof featureFlags.createMatch === "boolean"
            ) {
              configData.featureFlags.createMatch = featureFlags.createMatch;
            }
          }

          if ("domain" in data && data.domain) {
            const domain = data.domain;
            const matchingDomain = domains.find((d) => d === domain);

            if (!matchingDomain) {
              console.log("Failed to find matching domain. Reverting to fabledfounders.com");
            }

            configData.domain = matchingDomain ? matchingDomain : "fabledfounders.com";
            const fullRoutes = SiteRoutes[configData.domain];

            const routeBreakdown = fullRoutes.reduce(
              (accumulator, route) => {
                if (route.isPrimary) {
                  accumulator.primaryRoutes.push(route);
                } else {
                  accumulator.secondaryRoutes.push(route);
                }

                return accumulator;
              },
              { primaryRoutes: [], secondaryRoutes: [] } as FullRoutes
            );

            configData.fullRoutes = {
              primaryRoutes: routeBreakdown.primaryRoutes,
              secondaryRoutes: routeBreakdown.secondaryRoutes,
            };
          }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get("email");

        if (emailParam) {
          // Set email in the config data
          configData.email = emailParam;

          const newUrl = window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
        }

        setConfig(configData);
      } catch (error) {
        console.error("Error fetching config:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  });

  return (
    <ConfigContext.Provider value={{ config, loading }}>
      {loading ? <Spinner isLoading={loading} /> : children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
