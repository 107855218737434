import { MatchSet } from "api/paddle/paddleStorageModels";
import {
  type PaddlePlayers,
  PaddlePlayersBase,
  PaddlePlayersAvailableTeamBase,
  PaddlePlayersAvailableTeam,
  PaddlePlayersPlayerBase,
  PaddlePlayersPlayer,
  PaddlePlayersAvailablePlayer,
  PaddlePlayersAvailablePlayerBase,
} from "api/paddle/viewTeamsModels";
import { useReducer } from "react";
import { jsonMember, jsonObject } from "typedjson";
import localPaddleStorageUtil from "utils/localPaddleStorageUtil";
import numberUtil from "utils/numberUtil";
import objectUtils from "utils/objectUtil";
import sortUtils from "utils/sortUtils";
import stringUtils from "utils/stringUtils";

export enum ActionType {
  Load = "Load",
  Save = "Save",
}

export interface PaddleTeamStandingsActions {
  load: () => void;
  save: (players: PaddlePlayersBase) => void;
}

@jsonObject
export class PaddleTeamsState {
  @jsonMember(PaddlePlayersBase)
  paddlePlayers: PaddlePlayers = new PaddlePlayersBase();
}

type Action =
  | { type: ActionType.Load; payload: { players: PaddlePlayers } }
  | { type: ActionType.Save; payload: { players: PaddlePlayers } };

const reducer = (state: PaddleTeamsState, action: Action): PaddleTeamsState => {
  const newState = objectUtils.deepCopyTypedObject(PaddleTeamsState, state);

  switch (action.type) {
    case ActionType.Load: {
      const { players } = action.payload;
      const newPaddleTeams = PaddlePlayersBase.create(players);

      newPaddleTeams.players = newPaddleTeams.players.sort((p1, p2) => {
        return sortUtils.byString(p1, p2, "asc", (p) => p.playerFullName);
      });

      newState.paddlePlayers = newPaddleTeams;
      return newState;
    }
    case ActionType.Save: {
      const { players } = action.payload;
      const newPlayers = PaddlePlayersBase.create(players);
      newState.paddlePlayers = newPlayers;
      return newState;
    }
    default:
      return state;
  }
};

export const usePaddlePlayersStore = (): [PaddleTeamsState, PaddleTeamStandingsActions] => {
  const [state, dispatch] = useReducer(reducer, new PaddleTeamsState());

  const load = () => {
    const paddleClub = localPaddleStorageUtil.get();
    const { teams, players, matches } = paddleClub;

    const availableTeams: PaddlePlayersAvailableTeam[] =
      teams.map((t) => {
        return PaddlePlayersAvailableTeamBase.create({
          teamId: t.id,
          displayName: t.name,
        });
      }) ?? [];

    const teamPlayers: PaddlePlayersPlayer[] = players.map((player) => {
      type MatchesByPlayer = { matchesPlayed: number; matchPoints: number };

      const team = teams.find((t) => t.id === player.teamId);
      const matchesByPlayer: MatchesByPlayer = matches.reduce(
        (accumulator, match) => {
          const updateAccumulator = (teamKey: keyof MatchSet, accum: MatchesByPlayer) => {
            const team = match[teamKey];

            if (team.playerOneId === player.id) {
              const { points } = team;
              accum.matchPoints += points;
              accum.matchesPlayed += 1;
            }

            if (team.playerTwoId === player.id) {
              const { points } = team;
              accum.matchPoints += points;
              accum.matchesPlayed += 1;
            }
          };

          updateAccumulator("teamOne", accumulator);
          updateAccumulator("teamTwo", accumulator);

          return accumulator;
        },
        { matchesPlayed: 0, matchPoints: 0 } as MatchesByPlayer
      );

      const { matchesPlayed, matchPoints } = matchesByPlayer;
      const pointsPerMatch = numberUtil.round(matchPoints / matchesPlayed, 1);

      return PaddlePlayersPlayerBase.create({
        id: stringUtils.uuid(),
        teamId: player.teamId,
        teamName: team?.name ?? "Unassigned",
        playerId: player.id,
        playerFirstName: player.firstName,
        playerLastName: player.lastName,
        playerFullName: player.fullName,
        playerNickname: player.nickname,
        matchesPlayed: matchesPlayed,
        matchPoints: matchPoints,
        pointsPerMatch: pointsPerMatch,
      });
    });

    const availablePlayers: PaddlePlayersAvailablePlayer[] = players
      .map((player) => {
        const availablePlayer: PaddlePlayersAvailablePlayer = PaddlePlayersAvailablePlayerBase.create({
          teamId: player.teamId,
          playerId: player.id,
          displayName: player.fullName,
        });

        return availablePlayer;
      })
      .sort((a, b) => sortUtils.byString(a, b, "asc", (player) => player.displayName));

    const paddleTeams = PaddlePlayersBase.create({
      availableTeamSelection: availableTeams,
      availablePlayerSelection: availablePlayers,
      players: teamPlayers,
    });

    dispatch({
      type: ActionType.Load,
      payload: {
        players: paddleTeams,
      },
    });
  };

  const save = (players: PaddlePlayers) => {
    dispatch({
      type: ActionType.Save,
      payload: {
        players,
      },
    });
  };

  return [state, { load, save }];
};
