import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import moment from "moment";
import { Box, Button, CircularProgress, Container, Paper, TextField, Tooltip, Typography } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import secretsApi from "utils/secrets";

interface UrlForm {
  id: string;
  url: string;
  isValid: boolean;
  created: moment.Moment;
}

const NamecheapPage: React.FC = () => {
  const [urlForms, setUrlForms] = useState<UrlForm[]>([]);
  const [urlInput, setUrlInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedUrlForms = JSON.parse(localStorage.getItem("urlForms") ?? "[]");
    if (storedUrlForms) {
      setUrlForms(storedUrlForms);
    }
  }, []);

  const parseXmlResponse = (xmlString: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    // Find the DomainCheckResult element
    const domainCheckResult = xmlDoc.querySelector("CommandResponse > DomainCheckResult");

    if (domainCheckResult) {
      // Extract the Available attribute value
      const isDomainAvailable = domainCheckResult.getAttribute("Available") === "true";

      return {
        status: "OK", // Or you can use xmlDoc.querySelector("ApiResponse").getAttribute("Status") to get the overall status
        isDomainAvailable,
      };
    } else {
      return {
        status: "Error",
        isDomainAvailable: false,
      };
    }
  };

  const createUrlForm = async () => {
    if (isLoading) {
      return;
    }

    const secrets = secretsApi.getSecrets();

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://api.namecheap.com/xml.response?ApiUser=${secrets.username}&ApiKey=${secrets.key}&UserName=${secrets.username}&Command=namecheap.domains.check&ClientIp=${secrets.ip}&domainList=${urlInput}`
      );

      const data = await response.text();

      // Parse the XML response
      const parsedData = parseXmlResponse(data);

      // Check the overall status
      if (parsedData.status === "OK") {
        // Parse the API response and extract the availability status
        const isDomainAvailable = parsedData.isDomainAvailable;

        // Create a new URL form
        const newUrlForm: UrlForm = {
          id: v4(),
          url: urlInput,
          isValid: isDomainAvailable,
          created: moment(),
        };

        // Update the URL forms state
        setUrlForms([...urlForms, newUrlForm]);
        // Clear the input URL
        setUrlInput("");
      } else {
        console.error("Error in API response:", data);
        // Handle errors, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error checking domain availability:", error);
      // Handle errors, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Set loading state to false after API call completes
    }
  };

  const deleteUrlForm = (id: string) => {
    const updatedUrlForms = urlForms.filter((urlForm) => urlForm.id !== id);
    setUrlForms(updatedUrlForms);
  };

  useEffect(() => {
    localStorage.setItem("urlForms", JSON.stringify(urlForms));
  }, [urlForms]);

  const sortedUrlForms = [...urlForms].sort((a, b) => (moment(b.created).isBefore(a.created) ? -1 : 1));

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      createUrlForm();
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Namecheap URL Tracker
      </Typography>
      <div>
        <TextField
          label="Enter URL"
          autoComplete="off"
          value={urlInput}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
          onChange={(event) => setUrlInput(event.target.value)}
          style={{ width: "100%" }}
        />
        <Button style={{ marginTop: "10px" }} variant="contained" color="primary" onClick={createUrlForm}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Track URL"}
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        {sortedUrlForms.map((urlForm) => (
          <Paper key={urlForm.id} elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                {urlForm.isValid ? "Available" : "Unavailable"}
              </Typography>
              <CustomIcon
                icon="openInNew"
                onClick={() =>
                  window.open(`https://www.namecheap.com/domains/registration/results/?domain=${urlForm.url}`, "_blank")
                }
              />
              <CustomIcon icon="delete" onClick={() => deleteUrlForm(urlForm.id)} />
            </div>
            <Tooltip title={urlForm.url}>
              <Box
                component="div"
                overflow="hidden"
                textOverflow="ellipsis"
                style={{ marginTop: "10px", wordWrap: "break-word" }}
              >
                {urlForm.url}
              </Box>
            </Tooltip>
          </Paper>
        ))}
      </div>
    </Container>
  );
};

export default NamecheapPage;
