import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SxProps, Theme } from "@mui/material";

export interface ToggleProps {
  label: string;
  isChecked: boolean;
  onChange: () => void;
  minWidthPx?: number;
}

const Toggle = (props: ToggleProps) => {
  const { label, isChecked, onChange, minWidthPx } = props;

  const sxStyle: SxProps<Theme> | undefined =
    minWidthPx !== undefined
      ? {
          minWidth: `${minWidthPx}px`,
        }
      : undefined;

  return (
    <FormControl component="fieldset" variant="standard" sx={sxStyle}>
      <FormControlLabel
        control={
          <Switch checked={isChecked} onChange={onChange} name={label.replaceAll(" ", "_").toLocaleLowerCase()} />
        }
        label={label}
      />
    </FormControl>
  );
};

export default Toggle;
