import moment, { Moment } from "moment";
import { IJsonMemberOptions } from "typedjson";

export const typedJsonUtils = {
  momentOptions: (format: string): IJsonMemberOptions => {
    return {
      deserializer: (json) => {
        if (json == null) {
          return undefined;
        }

        return moment(json, format);
      },
      serializer: (value) => (value == null || !moment.isMoment(value) ? undefined : (value as Moment).format(format)),
    };
  },
};
