// useHomeStore.tsx
import { useReducer, useEffect } from "react";

// Constant for the local storage key
const LOCAL_STORAGE_KEY = "homeData";

// Define action types with unique UUIDs
export enum ActionType {
  Load = "e2e925d1-4e19-4d10-bf3b-020727462396",
  UpdateStage = "a38a34f2-9073-48c4-85c3-5d6404ad9861",
  ToggleRefDialog = "4dfe45fc-1b0a-4bf0-9bf1-29351f8ea4a3",
}

// Define the type for the data property
export interface HomeData {
  stage: HomeDataStage;
}
// Abstract the stage values as a type
export type HomeDataStage = 1 | 2 | 3;

// Define action types and the initial state
type HomeState = {
  data: HomeData;
  isRefDialogOpen: boolean;
  isPhase1CardActive: boolean; // Add new property
  isPhase2CardActive: boolean; // Add new property
  isPhase3CardActive: boolean; // Add new property
};

type Action =
  | { type: ActionType.Load }
  | { type: ActionType.UpdateStage; payload: HomeData }
  | { type: ActionType.ToggleRefDialog; payload: boolean };

// Serialize the data object to JSON for storage
const serializeData = (data: HomeData) => JSON.stringify(data);
// Deserialize the JSON data from storage
const deserializeData = (dataString: string | null) =>
  dataString ? JSON.parse(dataString) : { stage: 1, isRefDialogOpen: false };

// Reducer function
const reducer = (state: HomeState, action: Action): HomeState => {
  switch (action.type) {
    case ActionType.Load:
      const savedData = localStorage.getItem(LOCAL_STORAGE_KEY) || "";

      return { ...state, data: deserializeData(savedData) };

    case ActionType.UpdateStage:
      localStorage.setItem(LOCAL_STORAGE_KEY, serializeData(action.payload));
      return { ...state, data: action.payload };

    case ActionType.ToggleRefDialog:
      return { ...state, isRefDialogOpen: action.payload };

    default:
      return {
        ...state,
        isPhase1CardActive: state.data.stage === 1, // Update the active checks
        isPhase2CardActive: state.data.stage === 2,
        isPhase3CardActive: state.data.stage === 3,
      };
  }
};

// Define the interface for the return type
interface HomeStore {
  state: HomeState;
  actions: {
    load: () => void;
    updateStage: (stage: HomeDataStage) => void;
    toggleRefDialog: (isOpen: boolean) => void;
  };
}

export const useHomeStore = (): HomeStore => {
  // Initialize state using the reducer
  const [state, dispatch] = useReducer(reducer, {
    data: { stage: 1 },
    isRefDialogOpen: false,
    isPhase1CardActive: true,
    isPhase2CardActive: false,
    isPhase3CardActive: false,
  });

  // Load data from local storage when the component mounts
  useEffect(() => {
    dispatch({ type: ActionType.Load });
  }, []);

  const load = () => {
    dispatch({ type: ActionType.Load });
  };

  const updateStage = (stage: HomeDataStage) => {
    dispatch({ type: ActionType.UpdateStage, payload: { stage } });
  };

  const toggleRefDialog = (isOpen: boolean) => {
    dispatch({ type: ActionType.ToggleRefDialog, payload: isOpen });
  };

  return {
    state,
    actions: {
      load,
      updateStage,
      toggleRefDialog,
    },
  };
};
