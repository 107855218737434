import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import { usePaddleTeamStandingsStore } from "./store/usePaddleTeamStandingsStore";
import { TeamStandingsBase, TeamStandingsTeam } from "api/paddle/viewTeamStandingsModels";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { Grid } from "@mui/material";

const PaddleTeamStandingsPage = () => {
  const [state, actions] = usePaddleTeamStandingsStore();

  useEffectOnLoad(() => actions.load());

  const { teams } = state.teamStandings;

  const onTableChangeAll = (updatedRecords: TeamStandingsTeam[]) => {
    const newTeamStandings = TeamStandingsBase.create(state.teamStandings);
    newTeamStandings.teams = updatedRecords;
    actions.save(newTeamStandings);
  };

  const table: AdvancedTableRecords<TeamStandingsTeam> = {
    fields: [
      {
        id: "teamName",
        label: "Name",
        readOnly: true,
        width: "50%",
      },
      {
        id: "totalMatchPoints",
        label: "Total Match Points",
        readOnly: true,
        width: "50%",
      },
    ],
    data: [...teams],
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={9} md={7} lg={5} style={{ justifyContent: "center" }}>
        <AdvancedTable
          title="Team Standings"
          table={table}
          orderByKey="totalMatchPoints"
          initialSortOrder="desc"
          onChange={() => {}}
          onChangeAll={onTableChangeAll}
          onDelete={() => {}}
          hidePagination
          minWidthPx={0}
        />
      </Grid>
    </Grid>
  );
};

export default PaddleTeamStandingsPage;
