import { Match, MatchSet } from "api/paddle/paddleStorageModels";
import {
  AllMatchesBase,
  AvailableMatchDateBase,
  AvailablePlayer,
  AvailablePlayerBase,
  AvailableTeamBase,
  SingleDayMatchBase,
  SingleDayMatchTeam,
  SingleDayMatchesBase,
  SingleDayMatchesOverallResultBase,
} from "api/paddle/viewAllMatchesModels";
import moment from "moment";
import { useReducer } from "react";
import { jsonMember, jsonObject } from "typedjson";
import localPaddleStorageUtil from "utils/localPaddleStorageUtil";
import objectUtils from "utils/objectUtil";
import sortUtils from "utils/sortUtils";
import stringUtils from "utils/stringUtils";
import { v4 } from "uuid";

export enum ActionType {
  Load = "Load",
  SelectMatchDate = "SelectMatchDate",
  Save = "Save",
}

export interface PaddleTeamStandingsActions {
  load: () => void;
  save: (allMatches: AllMatchesBase) => void;
  selectMatchDate: (newMatchDate: AvailableMatchDateBase | undefined) => void;
}

@jsonObject
export class PaddleMatchesState {
  @jsonMember(AllMatchesBase)
  allMatches: AllMatchesBase = new AllMatchesBase();

  @jsonMember(Boolean)
  isLoaded: boolean = false;

  @jsonMember(AvailableMatchDateBase)
  selectedMatchDate: AvailableMatchDateBase | undefined = undefined;
}

type Action =
  | { type: ActionType.Load; payload: { allMatches: AllMatchesBase } }
  | { type: ActionType.SelectMatchDate; payload: { newMatchDate: AvailableMatchDateBase | undefined } }
  | { type: ActionType.Save; payload: { allMatches: AllMatchesBase } };

const reducer = (state: PaddleMatchesState, action: Action): PaddleMatchesState => {
  const newState = objectUtils.deepCopyTypedObject(PaddleMatchesState, state);

  switch (action.type) {
    case ActionType.Load: {
      const { allMatches } = action.payload;
      newState.allMatches = allMatches;
      newState.isLoaded = true;

      const { availableMatchDates } = allMatches;

      if (availableMatchDates.length > 0) {
        newState.selectedMatchDate = availableMatchDates[0];
      }
      return newState;
    }
    case ActionType.Save: {
      const { allMatches } = action.payload;
      newState.allMatches = AllMatchesBase.create(allMatches);
      return newState;
    }
    case ActionType.SelectMatchDate: {
      const { newMatchDate } = action.payload;
      newState.selectedMatchDate = newMatchDate;
      return newState;
    }
    default:
      return state;
  }
};

export const usePaddleMatchesStore = (): [PaddleMatchesState, PaddleTeamStandingsActions] => {
  const [state, dispatch] = useReducer(reducer, new PaddleMatchesState());

  const load = () => {
    const paddleClub = localPaddleStorageUtil.get();

    const { teams, players, matches } = paddleClub;

    const availableMatchDates = matches.reduce((uniqueArray: AvailableMatchDateBase[], match) => {
      const existingItem = uniqueArray.find((item) => item.date.isSame(match.matchDate));

      if (!existingItem) {
        uniqueArray.push(
          AvailableMatchDateBase.create({
            date: match.matchDate,
            displayName: match.matchDate.format("MMMM Do (dddd)"),
          })
        );
      }

      return uniqueArray;
    }, []);

    const availableTeams: AvailableTeamBase[] =
      teams.map((t) => {
        return AvailableTeamBase.create({
          teamId: t.id,
          displayName: t.name,
        });
      }) ?? [];

    const singleDayMatches: SingleDayMatchesBase[] = paddleClub.matches.map((m) => {
      const matchesOnSameDay: Match[] = paddleClub.matches.filter((mainMatches) => {
        const mainMatchDate = moment(mainMatches.matchDate).format("YYYY-MM-DD");
        const singleMatchDate = moment(m.matchDate).format("YYYY-MM-DD");

        return mainMatchDate === singleMatchDate;
      });

      const overallResults: SingleDayMatchesOverallResultBase[] =
        paddleClub.teams.map((team) => {
          const matchesOnSameDayForTeam = matchesOnSameDay.filter((match) => {
            return match.teamOne.teamId === team.id || match.teamTwo.teamId === team.id;
          });

          const teamPoints = matchesOnSameDayForTeam.reduce((accumulator, match) => {
            if (match.teamOne.teamId === team.id) {
              return accumulator + match.teamOne.points;
            }

            if (match.teamTwo.teamId === team.id) {
              return accumulator + match.teamTwo.points;
            }

            return accumulator;
          }, 0);

          const opponentTeamIds = matchesOnSameDayForTeam.reduce((accumulator, match) => {
            const teamOneId = match.teamOne.teamId;
            const teamTwoId = match.teamTwo.teamId;

            if (team.id !== teamOneId && !accumulator.has(teamOneId)) {
              accumulator.add(teamOneId);
            }

            if (team.id !== teamTwoId && !accumulator.has(teamTwoId)) {
              accumulator.add(teamTwoId);
            }

            return accumulator;
          }, new Set<string>());

          const totalMatches = matchesOnSameDayForTeam.length;

          const totalGames = matchesOnSameDayForTeam.reduce((accumulator, match) => {
            if (match.teamOne.teamId === team.id) {
              return accumulator + match.setOne.teamOne + match.setTwo.teamOne + (match.setThree?.teamOne ?? 0);
            }

            if (match.teamTwo.teamId === team.id) {
              return accumulator + match.setOne.teamTwo + match.setTwo.teamTwo + (match.setThree?.teamTwo ?? 0);
            }

            return accumulator;
          }, 0);

          const opponentTeamId = opponentTeamIds.size === 1 ? Array.from(opponentTeamIds)[0] : undefined;
          const opponentTeam = teams.find((t) => t.id === opponentTeamId);

          let matchupName = "-";

          if (opponentTeam) {
            matchupName =
              team.name < opponentTeam.name
                ? `${team.name} vs ${opponentTeam.name}`
                : `${opponentTeam.name} vs ${team.name}`;
          }

          const overallResult = SingleDayMatchesOverallResultBase.create({
            id: v4(),
            teamId: team.id,
            teamName: team.name,
            opponentTeamId: opponentTeamId,
            matchupName: matchupName,
            points: teamPoints,
            totalMatches: totalMatches,
            totalGames: totalGames,
          });

          return overallResult;
        }) ?? [];

      const singleDayMatches: SingleDayMatchBase[] = matchesOnSameDay.map((m) => {
        const createTeam = (teamKey: keyof MatchSet, opposingTeamKey: keyof MatchSet): SingleDayMatchTeam => {
          const team = m[teamKey];
          const opposingTeam = m[opposingTeamKey];

          const teamId = team.teamId;
          const teamName = teams.find((t) => t.id === teamId)?.name ?? teamId;
          const playerOneName = players.find((p) => p.id === team.playerOneId)?.lastName ?? team.playerOneId;
          const playerTwoName = players.find((p) => p.id === team.playerTwoId)?.lastName ?? team.playerTwoId;

          const setOne = m.setOne[teamKey];
          const setTwo = m.setTwo[teamKey];
          const points = team.points;

          const opposingSetOne = m.setOne[opposingTeamKey];
          const opposingSetTwo = m.setTwo[opposingTeamKey];
          const opposingPoints = opposingTeam.points;

          let setThree = 0;
          let opposingSetThree = 0;

          if (m.setThree) {
            setThree = m.setThree[teamKey];
            opposingSetThree = m.setThree[opposingTeamKey];
          }

          const matchTeam: SingleDayMatchTeam = {
            id: v4(),
            teamId: teamId,
            teamName: teamName,
            playerOneId: team.playerOneId,
            playerTwoId: team.playerTwoId,
            players: `${playerOneName} / ${playerTwoName}`,
            setOne: setOne,
            setTwo: setTwo,
            setThree: setThree,
            points: points,
            isSetOneWinner: setOne > opposingSetOne,
            isSetTwoWinner: setTwo > opposingSetTwo,
            isSetThreeWinner: setThree > opposingSetThree,
            isOverallWinner: points > opposingPoints,
          };

          return matchTeam;
        };

        return SingleDayMatchBase.create({
          teamOne: createTeam("teamOne", "teamTwo"),
          teamTwo: createTeam("teamTwo", "teamOne"),
        });
      });

      return SingleDayMatchesBase.create({
        id: stringUtils.uuid(),
        matchDate: m.matchDate,
        overallResults: overallResults.sort((o1, o2) => sortUtils.byString(o1, o2, "asc", (o) => o.matchupName)),
        matchOne: singleDayMatches[0],
        matchTwo: singleDayMatches.length > 1 ? singleDayMatches[1] : undefined,
        matchThree: singleDayMatches.length > 2 ? singleDayMatches[2] : undefined,
        matchFour: singleDayMatches.length > 3 ? singleDayMatches[3] : undefined,
        matchFive: singleDayMatches.length > 4 ? singleDayMatches[4] : undefined,
        matchSix: singleDayMatches.length > 5 ? singleDayMatches[5] : undefined,
        matchSeven: singleDayMatches.length > 6 ? singleDayMatches[6] : undefined,
        matchEight: singleDayMatches.length > 7 ? singleDayMatches[7] : undefined,
      });
    });

    const availableTeamsMap = availableTeams.reduce((map, team) => {
      map.set(team.teamId, team.displayName);
      return map;
    }, new Map<string, string>());

    const availablePlayers: AvailablePlayer[] = players
      .map((player) => {
        const availablePlayer: AvailablePlayer = AvailablePlayerBase.create({
          teamId: player.teamId,
          playerId: player.id,
          displayName: player.fullName,
          teamDisplayName: `Team ${availableTeamsMap.get(player.teamId)}` ?? "Team Unknown",
        });

        return availablePlayer;
      })
      .sort((a, b) => {
        if (a.displayName.includes("n/a") || b.displayName.includes("n/a")) {
          return 1;
        }

        return sortUtils.byString(a, b, "asc", (player) => player.displayName);
      });

    const allMatches = AllMatchesBase.create({
      availableMatchDates: availableMatchDates.sort((m1, m2) => m2.date.valueOf() - m1.date.valueOf()),
      availableTeams: availableTeams,
      availablePlayers: availablePlayers,
      matches: singleDayMatches.sort((m1, m2) => {
        if (m1.matchDate.isBefore(m2.matchDate)) return 1;
        if (m1.matchDate.isAfter(m2.matchDate)) return -1;
        return 0;
      }),
    });

    dispatch({
      type: ActionType.Load,
      payload: {
        allMatches,
      },
    });
  };

  const selectMatchDate = (newMatchDate: AvailableMatchDateBase | undefined) => {
    dispatch({
      type: ActionType.SelectMatchDate,
      payload: {
        newMatchDate,
      },
    });
  };

  const save = (allMatches: AllMatchesBase) => {
    dispatch({
      type: ActionType.Save,
      payload: {
        allMatches,
      },
    });
  };

  return [state, { load, save, selectMatchDate }];
};
