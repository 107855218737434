export interface Secrets {
  username: string;
  ip: string;
  key: string;
}

const secretsApi = {
  getSecrets: (): Secrets => {
    const localStorageSecrets = localStorage.getItem("namecheap");

    if (localStorageSecrets !== null) {
      try {
        const secretsData = JSON.parse(localStorageSecrets);

        const keysOfSecrets: (keyof Secrets)[] = ["ip", "key", "username"];
        let hasAllKeys = true;

        for (var key of keysOfSecrets) {
          if (!(key in secretsData)) {
            hasAllKeys = false;
          }
        }

        if (hasAllKeys) {
          return {
            username: secretsData.username,
            ip: secretsData.ip,
            key: secretsData.key,
          };
        }
      } catch (e) {
        console.log("failed to parse namecheap secrets");
      }
    }

    return {
      ip: "",
      key: "",
      username: "",
    } as Secrets;
  },
};

export default secretsApi;
