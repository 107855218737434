// EmailPage.tsx
import React from "react";
import { useEmailsStore } from "./store/useEmailsStore";
import ConfirmationDialog from "components/ConfirmationDialog";
import Spinner from "components/Spinner";
import { EmailTemplateForm } from "api/models";
import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import moment from "moment";
import { v4 } from "uuid";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import EditEmailTemplateModal from "./EditEmailTemplateModal";
import listUtils from "utils/listUtils";

const Emails: React.FC = () => {
  const [state, actions] = useEmailsStore();
  const { emailTemplates } = state;

  useEffectOnLoad(() => actions.load());

  if (state.isLoading) {
    return <Spinner isLoading={state.isLoading} />;
  }

  const onTableChange = (updatedRecord: EmailTemplateForm) => {
    actions.saveEmailTemplate(updatedRecord);
  };

  const onTableChangeAll = (updatedRecords: EmailTemplateForm[]) => {
    actions.saveEmailTemplates(updatedRecords);
  };

  const onDeleteConfirmation = () => {
    // Perform the actual deletion here
    const templateToDelete = emailTemplates.find((item) => item.id === state.deletingEmailTemplate?.id);
    if (templateToDelete) {
      const updatedTemplates = [...emailTemplates].filter((template) => template.id !== templateToDelete.id);
      actions.saveEmailTemplates(updatedTemplates);
    }
    actions.toggleDeleteModal(false); // Close the delete confirmation dialog
  };

  const table: AdvancedTableRecords<EmailTemplateForm> = {
    fields: [
      {
        id: "order",
        label: "Order",
        readOnly: true,
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "templates",
        label: "Latest Version",
        readOnly: true,
        custom: (emailTemplate: EmailTemplateForm) => {
          const activeTemplates = emailTemplate.templates.filter?.((t) => !t.isDisabled) ?? [];
          if (activeTemplates.length === 0) {
            return "N/A";
          }

          return listUtils.max(activeTemplates, "version", 1);
        },
      },
    ],
    data: [...emailTemplates],
    actions: [
      {
        icon: "edit",
        onClick: (emailTemplate: EmailTemplateForm) => actions.toggleEditModal(true, emailTemplate),
      },
      {
        icon: "delete",
        onClick: (emailTemplate: EmailTemplateForm) => actions.toggleDeleteModal(true, emailTemplate),
      },
    ],
  };

  return (
    <div>
      <AdvancedTable
        title="Email Templates"
        table={table}
        orderByKey="order"
        createDefaultRecord={() => {
          const defaultForm: EmailTemplateForm = {
            id: v4(),
            name: "",
            order: -1,
            templates: [
              {
                id: v4(),
                version: 1,
                fields: [],
                subject: "",
                template: "",
                defaultValue: "",
                isDisabled: false,
                created: moment(),
                updated: moment(),
              },
            ],
            isDisabled: false,
            created: moment(),
            updated: moment(),
          };

          return defaultForm;
        }}
        onChange={onTableChange}
        onChangeAll={onTableChangeAll}
        isSearchMatch={(item, query) => {
          return item.name.toLowerCase().includes(query);
        }}
        onDelete={(recordsToDelete) => {
          const newTemplates = emailTemplates.filter((template) => !recordsToDelete.some((r) => r.id === template.id));

          const newState = { ...state };
          newState.emailTemplates = newTemplates;
          actions.saveEmailTemplates(newState.emailTemplates);
        }}
        disableOnEnter
      />
      <ConfirmationDialog
        isOpen={state.isDeleteModalOpen}
        onClose={() => actions.toggleDeleteModal(false)}
        onConfirm={onDeleteConfirmation}
        title="Delete Email Template?"
        message={`Are you sure you want to delete "${state.deletingEmailTemplate?.name}"?`}
      />
      <EditEmailTemplateModal
        emailTemplate={state.editingEmailTemplate}
        isOpen={state.isEditModalOpen}
        onClose={() => actions.toggleEditModal(false)}
        onSave={(emailTemplate: EmailTemplateForm) => {
          actions.saveEmailTemplate(emailTemplate);
        }}
      />
    </div>
  );
};

export default Emails;
